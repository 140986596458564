import { Roles } from "../constants/Roles";
import { IRole } from "../types";

export const hasOneOfRoles = (
  userRole: IRole | IRole[],
  requiredRole: Roles | Roles[]
) => {
  const userRoles = Array.isArray(userRole) ? userRole : [userRole];
  const requiredRoles = Array.isArray(requiredRole)
    ? requiredRole
    : [requiredRole];

  return (
    userRoles.find(
      (role) => !!requiredRoles.find((subRole) => subRole === role.name)
    ) !== undefined
  );
};

export const hasEveryRole = (
  userRole: IRole | IRole[],
  requiredRole: Roles | Roles[]
) => {
  const userRoles = Array.isArray(userRole) ? userRole : [userRole];
  const requiredRoles = Array.isArray(requiredRole)
    ? requiredRole
    : [requiredRole];

  return requiredRoles.every(
    (role) => !!userRoles.find((subRole) => subRole.name === role)
  );
};
