import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { ICustomer } from "@/customer/types";

interface ICustomerDetailsProps {
    customer: ICustomer;
    title: string;
}

export const CustomerDetails = ({ customer, title }: ICustomerDetailsProps) => {
    return (
        <div>
            <TypographyH3 className="mb-4 text-md">{title}</TypographyH3>
            <div className="text-muted-foreground text-xs">Full name</div>
            <div className="text-card-foreground font-normal text-sm mb-4">{customer.full_name}</div>
            <div className="text-muted-foreground text-xs mb-1">Address</div>
            <div className="text-card-foreground font-normal text-sm mb-4">
                <p>
                    {customer.fullStreetDetails}
                    <br />
                    {`${customer.zip_code} ${customer.city}, ${customer.country.iso_3166_2}`}
                </p>
            </div>
            <div className="text-card-foreground font-normal text-sm mb-4"></div>
            <div className="text-muted-foreground text-xs mb-1">Phonenumber</div>
            <div className="text-card-foreground font-normal text-sm mb-4">{customer.phone_number}</div>
        </div>
    );
};
