import React from "react";
import { IAppState } from "../types";

export interface IAppStateContext {
    state: IAppState;
    setState: React.Dispatch<React.SetStateAction<IAppState>>;
}

export const INITIAL_APP_STATE: IAppState = {
    language: "en"
};

export const AppStateContext = React.createContext<IAppStateContext>({
    state: INITIAL_APP_STATE,
    setState: () => {
        throw new Error("AppStateContext not ready yet");
    }
});

AppStateContext.displayName = "AppStateContext";
