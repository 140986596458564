import React from "react";
import { useAuth } from "../hooks";
import { IAuthData } from "../types";

export interface IWithAuthEnhancerProps {
    auth: IAuthData;
}

export function withAuth<T extends IWithAuthEnhancerProps = IWithAuthEnhancerProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const DecoratedComponent = (props: Omit<T, keyof IWithAuthEnhancerProps>) => {
        const auth = useAuth();

        return <WrappedComponent {...(props as T)} auth={auth} />;
    };

    DecoratedComponent.displayName = `withAuth(${displayName})`;

    return DecoratedComponent;
}
