import { DataTable } from "@/components/custom/table";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import Flex from "@/components/ui/flex";
import { Mail01Icon } from "@hugeicons/react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { IUser } from "../types";

interface IUserTableProps {
    users: IUser[];
    onEdit: (user: IUser) => void;
}

export const UserTable = ({ users, onEdit }: IUserTableProps) => {
    const { t } = useTranslation();
    const columns: ColumnDef<IUser>[] = [
        {
            id: "name",
            header: "Name",
            cell: ({ row }) => (
                <Flex className="w-auto gap-x-6" justifyContent="start">
                    <Avatar className="h-8 w-8">
                        <AvatarFallback>{row.original.full_name}</AvatarFallback>
                        <AvatarImage src={row.original.avatar} />
                    </Avatar>
                    <div>
                        <span className="text-foreground">{row.original.full_name}</span>
                        <p className="text-xs text-muted-foreground">{row.original.email}</p>
                    </div>
                </Flex>
            )
        },
        {
            id: "Type",
            header: "Type",
            cell: ({ row }) => (
                <p className="capcapitalize">
                    {row.original.last_login ? row.original.roles.map(role => role.name).join(", ") : "Pending invite"}
                </p>
            )
        },
        {
            id: "last_activity",
            header: "Last activity",
            cell: ({ row }) =>
                row.original.last_login ? (
                    format(row.original.last_login, "d MMM yyyy")
                ) : (
                    <Flex justifyContent="start" className="gap-x-1.5">
                        {format(row.original.created_at, "d MMM yyyy")}
                        <Mail01Icon size={16} />
                    </Flex>
                )
        },
        {
            id: "invited_by",
            header: "Invited by",
            cell: ({ row }) => {
                const invitedBy = row.original.invited_by;

                return (
                    <div>
                        {invitedBy?.full_name}
                        <p className="text-xs">{invitedBy?.email}</p>
                    </div>
                );
            }
        },
        {
            id: "actions",
            enableHiding: false,
            header: "",
            cell: ({ row }) => {
                const user = row.original;

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <DotsHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => onEdit(user)}>Edit user</DropdownMenuItem>

                            <DropdownMenuItem>Delete user</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                );
            }
        }
    ];

    return (
        <Card>
            <CardContent className="p-0">
                <DataTable columns={columns} data={users} />
            </CardContent>
        </Card>
    );
};
