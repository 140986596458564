import React, { Fragment } from "react";
import {
  CircleSpinnerOverlay,
  FerrisWheelSpinner,
} from "react-spinner-overlay";
import { useGetUser, useLogin, useLogout } from "../hooks";
import { AuthContext } from "./AuthContext";

/**
 * AuthLoaderProvider
 * Decorates AuthContext.Provider with loader functionality
 * @param props
 * @returns
 */
export function AuthLoaderProvider(props: any) {
  const { data, error, isLoading, isIdle, isError } = useGetUser();

  const login = React.useCallback(useLogin, []);
  const logout = React.useCallback(useLogout, []);

  const value = React.useMemo(
    () => ({ ...data, login, logout }),
    [data, login, logout]
  );

  if (isLoading || isIdle) {
    return (
      <Fragment>
        <FerrisWheelSpinner loading={isLoading} size={28} />
        <CircleSpinnerOverlay
          loading={isLoading}
          overlayColor="rgba(0,153,255,0.2)"
        />
        ;
      </Fragment>
    );
  }

  if (isError) {
    return <div>Something wrong... {JSON.stringify(error)}</div>;
  }

  return <AuthContext.Provider value={value} {...props} />;
}
