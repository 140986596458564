import { carrierTypes } from "@/carrier";
import { Dialog, Transition } from "@headlessui/react";
import { ClosedCaptionIcon } from "@hugeicons/react";
import React, { Fragment } from "react";
import { CarrierForm } from "./CarrierForm";

interface ICarrierDialogProps {
    carriers: carrierTypes.ICarrier[];
    onSubmit: (values: any) => void;
    onClickClose: () => void;
    selectedCarrier?: carrierTypes.ICarrier;
    isOpen?: boolean;
}

export const CarrierDialog = ({
    carriers,
    onSubmit,
    isOpen = false,
    onClickClose,
    selectedCarrier,
}: ICarrierDialogProps) => (
    <Fragment>
        <Transition.Root appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClickClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>
                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="my-8 mx-auto max-w-md transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 py-3 p-6">
                                <ClosedCaptionIcon
                                    className="w-4 h-4 text-gray-600 hover:text-gray-900 absolute right-5 top-5"
                                    aria-hidden="true"
                                    onClick={() => onClickClose()}
                                />
                                Select carrier for the selected items
                            </Dialog.Title>
                            <Dialog.Description as="div" className="p-6">
                                <CarrierForm {...{ carriers, selectedCarrier }} onSubmit={onSubmit} />
                            </Dialog.Description>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    </Fragment>
);
