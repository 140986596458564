import { carrierTypes } from "@/carrier";
import { channelTypes } from "@/channel";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

interface IFilterForm {
  onSubmit: (values: any) => void;
  channels: channelTypes.IChannel[];
  carriers: carrierTypes.ICarrier[];
  formValues?: any;
}

export const FilterForm = ({
  onSubmit,
  channels,
  carriers,
  formValues,
}: IFilterForm) => {
  const { control, handleSubmit, reset } = useForm();
  const channelOptions = channels.map((channel) => ({
    label: channel.company_name,
    value: channel.id,
  }));
  const carrierOptions = carriers.map((carrier) => ({
    label: carrier.name,
    value: carrier.id,
  }));

  useEffect(() => reset(formValues), [formValues]);

  return (
    <form className="flex-col flex gap-y-4" onSubmit={handleSubmit(onSubmit)}>
      {/* <ControlledFormField
        label="Channels"
        isMulti
        placeholder="Channel"
        options={channelOptions}
        control={control}
        name="channels"
        component={SelectField}
      />
      <div className="flex gap-x-4">
        <ControlledFormField
          numColSpan={1}
          label="Placed after"
          placeholder="From"
          control={control}
          name="from"
          component={DateField}
        />
        <ControlledFormField
          label="Placed before"
          numColSpan={1}
          placeholder="To"
          control={control}
          name="to"
          component={DateField}
        />
      </div>
      <ControlledFormField
        label="Carrier"
        isMulti
        placeholder="Carrier"
        options={carrierOptions}
        value
        control={control}
        name="carriers"
        component={SelectField}
      />
      <Flex justifyContent="justify-end">
        <Button type="submit" variant="primary">
          submit
        </Button>
      </Flex> */}
    </form>
  );
};
