import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { FormControl, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { productTypes } from "@/product";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { debounce } from "lodash";
import { ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";

interface AsyncProductSelectProps {
    products: productTypes.IProduct[];
    search: (query: string) => void;
    field: any;
    form: any;
    isLoading: boolean;
}

export const AsyncProductSelect = ({ search, products, field, form, isLoading }: AsyncProductSelectProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        debounce(() => {
            search(searchQuery);
        }, 600)();
    }, [searchQuery]);

    return (
        <FormItem>
            <FormLabel>Select product</FormLabel>
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    <FormControl>
                        <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                                "w-full justify-between overflow-hidden",
                                !!field.value?.id && "text-muted-foreground"
                            )}
                        >
                            <div className="w-48 truncate">{field.value?.name}</div>
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </FormControl>
                </PopoverTrigger>
                <PopoverContent side="bottom" className="w-[400px] p-0">
                    <Command className="w-full" shouldFilter={false}>
                        <CommandInput
                            value={searchQuery}
                            onValueChange={setSearchQuery}
                            placeholder="Search product..."
                        />
                        <CommandEmpty>No product found.</CommandEmpty>
                        {isLoading && <CommandEmpty>Loading...</CommandEmpty>}
                        <CommandGroup>
                            <CommandList>
                                {products
                                    .filter(product => product.eans?.length > 0)
                                    .filter(product => product.channels && product.channels?.length > 0)
                                    .map(product => {
                                        return (
                                            <CommandItem
                                                value={product.id + product.name.replace(/[()-]/g, "").slice(0, 12)}
                                                key={product.id}
                                                onSelect={() => {
                                                    form.setValue(field.name, product);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                {product.id} {product.name} {product.eans[0]?.ean}
                                            </CommandItem>
                                        );
                                    })}
                            </CommandList>
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
            <FormMessage />
        </FormItem>
    );
};
