import { queryClient } from "@/app";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./contants/QueryKeys";
import { getCarrier, getDomainCarriers, updateCarrier } from "./queries";
import { ICarrier, ICarrierResponse } from "./types";

export const useGetDomainCarriers = (domainId: number, page: number) =>
    useQuery<ICarrierResponse, Error>(QueryKeys.CarrierItems, () => getDomainCarriers(domainId, page));

export const useGetCarrier = (id: number) => useQuery<ICarrier, Error>(QueryKeys.CarrierItem, () => getCarrier(id));

export const useUpdateCarrier = () =>
    useMutation(updateCarrier, {
        onSuccess: (updatedItem: ICarrier) => {
            queryClient.invalidateQueries([QueryKeys.CarrierItem, updatedItem.id]);
        }
    });
