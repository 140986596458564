import { DataTable } from "@/components/custom/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { HourglassIcon } from "@hugeicons/react";
import { ColumnDef } from "@tanstack/react-table";
import { differenceInDays } from "date-fns";
import { PurchaseOrderStatus } from "../constans/PurchaseOrderStatus";
import { IPurchaseOrder } from "../types";

interface IPurchaseOrderTableProps {
    orders: IPurchaseOrder[];
    onViewPurchaseOrderClick: (id: number) => void;
}

export const PurchaseOrderTable = ({ orders, onViewPurchaseOrderClick }: IPurchaseOrderTableProps) => {
    const columns: ColumnDef<IPurchaseOrder>[] = [
        {
            header: "ID",
            accessorKey: "id"
        },
        {
            header: "Status",
            accessorKey: "status",
            cell: ({ row }) => (
                <Badge
                    className={cn(
                        "shadow-none rounded-xl font-light",
                        row.original.status === PurchaseOrderStatus.Open && "bg-gray-400",
                        row.original.status === PurchaseOrderStatus.Pending && "bg-orange-400",
                        row.original.status === PurchaseOrderStatus.Completed && "bg-green-500"
                    )}
                >
                    {row.original.status}
                </Badge>
            )
        },
        {
            header: "Identity",
            cell: ({ row }) => <div>{row.original.identity.name}</div>
        },
        {
            header: "Supplier",
            cell: ({ row }) => <Flex>{row.original.supplier?.company}</Flex>
        },
        {
            header: "days",
            cell: ({ row }) => {
                const openDays = row.original?.created_at
                    ? differenceInDays(new Date(), new Date(row.original.created_at))
                    : 0;

                return (
                    row.original?.created_at && (
                        <Flex
                            justifyContent="start"
                            alignItems="center"
                            className={cn("gap-x-1", openDays > 5 && "text-primary")}
                        >
                            <HourglassIcon variant="solid" className="w-3" />
                            {openDays} days ago
                        </Flex>
                    )
                );
            }
        },
        {
            header: "Notice / PO number",
            cell: ({ row }) => <div>{row.original.notice}</div>
        },

        {
            header: "Created At",
            accessorKey: "created_at",
            cell: ({ row }) => <div>{Intl.DateTimeFormat("nl-NL").format(new Date(row.original.created_at))}</div>
        },
        {
            header: "Updated At",
            accessorKey: "updated_at",
            cell: ({ row }) => <div>{Intl.DateTimeFormat("nl-NL").format(new Date(row.original.updated_at))}</div>
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <Button variant="outline" size="sm" onClick={() => onViewPurchaseOrderClick(row.original.id)}>
                    View
                </Button>
            )
        }
    ];

    return <DataTable data={orders} columns={columns} />;
};
