import { utilsQuery } from "@/common";

const define = utilsQuery.defineQueryKeyCreator("product");

const QueryKeys = {
    ProductItem: define("productItem"),
    ProductItems: define("ProductItems"),
    TotalStockValue: define("TotalStockValue")
};

export default QueryKeys;
