import { DataTable } from "@/components/custom/table";
import { Button } from "@/components/ui/button";
import { ColumnDef } from "@tanstack/react-table";
import { IWarehouse } from "../types";

interface IWarehouseTableProps {
    warehouses: IWarehouse[];
    onEditClick: (warehosue: IWarehouse) => void;
}

export const WarehouseTable = ({ warehouses, onEditClick }: IWarehouseTableProps) => {
    const columns: ColumnDef<IWarehouse>[] = [
        {
            id: "name",
            header: "Name"
        },
        {
            id: "address",
            header: "Adress"
        },
        {
            id: "number",
            header: "Number"
        },
        {
            id: "email",
            header: "Email"
        },
        {
            id: "actions",
            header: "Actions",
            cell: ({ row }) => (
                <div>
                    <Button onClick={() => onEditClick(row.original)}>Edit</Button>
                </div>
            )
        }
    ];

    return <DataTable data={warehouses} columns={columns} />;
};
