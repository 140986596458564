import { config } from "@/app";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { countryHooks } from "@/country";
import { Add01Icon } from "@hugeicons/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ISupplier } from "../types";

interface ISupplierFormProps {
    onSubmit: (data: ISupplier) => void;
    supplier?: ISupplier;
}

export const SupplierForm = ({ onSubmit, supplier }: ISupplierFormProps) => {
    const [fetchCountries, setFetchCountries] = useState<boolean>(false);
    const { data: countries = [] } = countryHooks.useGetCountries({ enabled: fetchCountries });
    const { control, ...form } = useForm<ISupplier>({
        defaultValues: supplier,
    });

    setTimeout(() => {
        setFetchCountries(true);
    }, 600);

    return (
        <Form control={control} {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="h-full mb-8 w-full">
                <Flex flexDirection="col" justifyContent="between" className="h-full py-1 w-full">
                    <div className="overflow-scroll px-8 w-full grid grid-cols-6 gap-2">
                        <FormField
                            control={control}
                            name="company"
                            render={({ field }) => (
                                <FormItem className="col-span-6">
                                    <FormLabel>Company</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="EL-Vidas BV" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="col-span-6">
                                    <FormLabel>E-mail</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="johndow@gmail.com" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="first_name"
                            render={({ field }) => (
                                <FormItem className="col-span-3">
                                    <FormLabel>Firstname</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="John" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="surname"
                            render={({ field }) => (
                                <FormItem className="col-span-3">
                                    <FormLabel>Surname</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="Dow" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="street"
                            render={({ field }) => (
                                <FormItem className="col-span-4">
                                    <FormLabel>Street</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="Goosensstraat" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="house_number"
                            render={({ field }) => (
                                <FormItem className="col-span-2">
                                    <FormLabel>Number</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="12" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="zip_code"
                            render={({ field }) => (
                                <FormItem className="col-span-2">
                                    <FormLabel>Zipcode</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="12" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name="city"
                            render={({ field }) => (
                                <FormItem className="col-span-4">
                                    <FormLabel>City</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="Amsterdam" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="phone_number"
                            render={({ field }) => (
                                <FormItem className="col-span-3">
                                    <FormLabel>Phone number</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="+31638383727" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="vat_number"
                            render={({ field }) => (
                                <FormItem className="col-span-3">
                                    <FormLabel>Vat number</FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder="NL192838383BO1" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={control}
                            name="country_id"
                            render={({ field }) => (
                                <FormItem className="col-span-6">
                                    <FormLabel>Country</FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} defaultValue={String(field.value)}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a verified email to display" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {countries.map((country) => (
                                                    <SelectItem key={country.id} value={String(country.id)}>
                                                        <Flex className="gap-x-1.5">
                                                            <img
                                                                src={`${config.appUrl}/assets/img/flags/${country.iso_3166_2}.svg`}
                                                                alt=""
                                                                className="w-3 h-2.5 object-cover"
                                                            />
                                                            {country.name}
                                                        </Flex>
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <div
                        className="flex items-center justify-end text-right w-full px-6 py-3"
                        style={{
                            boxShadow:
                                "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)",
                        }}
                    >
                        <Button icon={Add01Icon}>Create supplier</Button>
                    </div>
                </Flex>
            </form>
        </Form>
    );
};
