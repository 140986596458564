import { axios } from "@/app/services";
import { ICarrier, ICarrierResponse } from "./types";

export const getCarrier = async (id: number): Promise<ICarrier> => {
    const response = await axios.get<{ data: ICarrier }>(`carriers/${id}`);

    return response.data.data;
};

export const getDomainCarriers = async (domainId: number, page: number): Promise<ICarrierResponse> => {
    const response = await axios.get<ICarrierResponse>(`domains/${domainId}/carriers`, {
        params: {
            page
        }
    });

    return response.data;
};

export const updateCarrier = async (carrier: ICarrier): Promise<ICarrier> => {
    const response = await axios.put<{ data: ICarrier }>(`carriers/${carrier.id}`, carrier);

    return response.data.data;
};
