/**
 * isNumeric
 * @param value
 */
export const isNumeric = (value: unknown) => value !== null && !isNaN(value as number);

/**
 * isFloat
 * @param value
 */
export const isFloat = (value: number) => value === +value && value !== (value | 0);

/**
 * isInt
 * @param value
 */
export const isInt = (value: number) => value === +value && value === (value | 0);

/**
 * aspectRatio
 *
 * e.g. 2880, 1800 => 8:5
 *
 * @credits https://github.com/ryanhefner/calculate-aspect-ratio
 *
 * @param width
 * @param height
 */
export const aspectRatio = (width: number, height: number, separator = "/") => {
    const divisor = gcd(width, height);

    return `${width / divisor}${separator}${height / divisor}`;
};

/**
 * gcd
 * greatest common divisor
 *
 * @credits https://github.com/ryanhefner/calculate-aspect-ratio
 *
 * @param a
 * @param b

 */
export const gcd = (a: number, b: number): number => {
    return b ? gcd(b, a % b) : a;
};
