import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserForm } from "../components/UserForm";
import { IUser } from "../types";

interface IUserSheetProps {
    user: IUser;
    handleSubmit: (user: IUser) => void;
    open: boolean;
    onClose: () => void;
}

export const UserSheet = ({
    open,
    onClose,
    user,
    handleSubmit,
}: IUserSheetProps) => {
    const [activeTab, setActiveTab] = useState<string>("general");
    const { t } = useTranslation("user");

    return (
        <Sheet open={open} onOpenChange={onClose}>
            <SheetContent className="p-0 w-[900px] sm:w-[940px] overflow-hidden">
                <Flex
                    justifyContent="start"
                    alignItems="start"
                    className="h-full bg-zinc-100"
                >
                    <Flex
                        alignItems="start"
                        justifyContent="between"
                        flexDirection="col"
                        className="h-full bg-white rounded-xl p-6 "
                    >
                        <SheetHeader>
                            <SheetTitle>
                                <h3>
                                    {t("title")}f <span>{user?.id}</span>
                                </h3>
                            </SheetTitle>
                        </SheetHeader>
                        <SheetContent>
                            <SheetDescription>
                                {t("description")}
                            </SheetDescription>
                            <Flex justifyContent="start" alignItems="start">
                                <div className="w-1/4">
                                    <div className="border rounded-full h-32 w-32" />
                                </div>
                                <UserForm user={user} onSubmit={handleSubmit} />
                            </Flex>
                        </SheetContent>

                        <SheetFooter className="justify-end flex w-full m-0">
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button variant="outline">Delete Account</Button>
                            <Button className="btn btn-secondary">Okey</Button>
                        </SheetFooter>
                    </Flex>
                </Flex>
            </SheetContent>
        </Sheet>
    );
};
