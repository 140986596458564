import {
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut
} from "@/components/ui/command";

import { CommandDialog as CommandDialogRoot } from "@/components/ui/command";
import {
    Calculator01Icon,
    CalendarLove01Icon,
    CreditCardAcceptIcon,
    Setting06Icon,
    SmileIcon,
    UserCircle02Icon
} from "@hugeicons/react";

import { useEffect, useState } from "react";

export const CommandDialog = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setOpen(open => !open);
            }
        };
        document.addEventListener("keydown", down);
        return () => document.removeEventListener("keydown", down);
    }, []);

    return (
        <CommandDialogRoot open={open} onOpenChange={setOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                    <CommandItem>
                        <CalendarLove01Icon />
                        <span>Calendar</span>
                    </CommandItem>
                    <CommandItem>
                        <SmileIcon />
                        <span>Search Emoji</span>
                    </CommandItem>
                    <CommandItem>
                        <Calculator01Icon />
                        <span>Calculator</span>
                    </CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Settings">
                    <CommandItem>
                        <UserCircle02Icon />
                        <span>Profile</span>
                        <CommandShortcut>⌘P</CommandShortcut>
                    </CommandItem>
                    <CommandItem>
                        <CreditCardAcceptIcon />
                        <span>Billing</span>
                        <CommandShortcut>⌘B</CommandShortcut>
                    </CommandItem>
                    <CommandItem>
                        <Setting06Icon />
                        <span>Settings</span>
                        <CommandShortcut>⌘S</CommandShortcut>
                    </CommandItem>
                </CommandGroup>
            </CommandList>
        </CommandDialogRoot>
    );
};
