import { config } from "@/app";
import { Progress } from "@/components/ui/progress";
import { Drag01Icon, MultiplicationSignIcon } from "@hugeicons/react";
import { MediaLibrary as MediaLibraryClass } from "@spatie/media-library-pro-core";
import { MediaLibrary } from "@spatie/media-library-pro-core/dist/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { Icons, ItemErrors, ListErrors, Thumb, Uploader, useMediaLibrary } from "../MediaLibraryProReactCollection";
import { TypographyH3 } from "../text/TypographyH3";

type Props = {
    form: any;
    disabledRemove?: boolean;
    name: string;
    initialValue?: MediaLibrary.MediaAttributes[];
    routePrefix?: string;
    translations?: MediaLibrary.Options["translations"];
    useFieldArray?: MediaLibrary.Options["translations"];
    validationRules?: Partial<MediaLibraryClass["config"]["validationRules"]>;
    validationErrors?: { [key: string]: Array<string> } | Array<never>;
    sortable?: boolean;
    maxItems?: number;
    maxSizeForPreviewInBytes?: number;
    vapor?: MediaLibrary.Config["vapor"];
    vaporSignedStorageUrl?: MediaLibrary.Config["vaporSignedStorageUrl"];
    uploadDomain?: MediaLibrary.Config["uploadDomain"];
    withCredentials?: MediaLibrary.Config["withCredentials"];
    headers?: MediaLibrary.Config["headers"];
    fileTypeHelpText?: string;
    setMediaLibrary?: (mediaLibrary: MediaLibraryClass) => void;
    beforeUpload?: MediaLibraryClass["config"]["beforeUpload"];
    afterUpload?: MediaLibraryClass["config"]["afterUpload"];
    onChange?: (media: { [uuid: string]: MediaLibrary.MediaAttributes }) => void;
    onIsReadyToSubmitChange?: (isReadyToSubmit: boolean) => void;
    propertiesView?: (helpers: { object: MediaLibrary.MediaObject }) => React.ReactNode;
    fieldsView?: (helpers: {
        object: MediaLibrary.MediaObject;
        getCustomPropertyInputProps: (
            propertyName: string
        ) => { value: any; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void };
        getCustomPropertyInputErrors: (
            propertyName: string
        ) => ReturnType<MediaLibraryClass["getCustomPropertyInputErrors"]>;

        getNameInputProps: () => {
            value: any;
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        };
        getNameInputErrors: () => Array<string>;
    }) => React.ReactNode;
};

export default function MediaLibraryCollection({
    form,
    disabledRemove = false,
    name,
    initialValue = [],
    translations = {},
    validationRules,
    validationErrors = {},
    routePrefix = "temp",
    sortable = true,
    maxItems,
    maxSizeForPreviewInBytes,
    vapor,
    vaporSignedStorageUrl,
    uploadDomain = config.apiUrl.replace(/\/$/, ""),
    withCredentials = true,
    headers,
    fileTypeHelpText,
    setMediaLibrary,
    propertiesView = DefaultPropertiesView,
    fieldsView = DefaultFieldsView,
    beforeUpload = () => {},
    afterUpload = () => {},
    onChange = () => {},
    onIsReadyToSubmitChange = () => {}
}: Props) {
    const {
        mediaLibrary,
        state,
        getImgProps,
        getNameInputProps,
        getNameInputErrors,
        getCustomPropertyInputProps,
        getCustomPropertyInputErrors,
        getFileInputProps,
        getDropZoneProps,
        setOrder,
        removeMedia,
        replaceMedia,
        getErrors,
        clearObjectErrors,
        clearInvalidMedia,
        isReadyToSubmit
    } = useMediaLibrary({
        name,
        initialValue: initialValue,
        validationErrors: Array.isArray(validationErrors) ? {} : validationErrors,
        routePrefix,
        validationRules,
        translations,
        maxItems,
        maxSizeForPreviewInBytes,
        vapor,
        vaporSignedStorageUrl,
        uploadDomain,
        withCredentials,
        headers,
        beforeUpload,
        afterUpload,
        onChange
    });

    React.useEffect(() => {
        onIsReadyToSubmitChange(isReadyToSubmit);
    }, [isReadyToSubmit]);

    React.useEffect(() => {
        if (setMediaLibrary && mediaLibrary) {
            setMediaLibrary(mediaLibrary);
        }
    }, [setMediaLibrary, mediaLibrary]);

    const dropZoneProps: any = getDropZoneProps();
    const fileInputProps: any = getFileInputProps();

    const items = state.media.map((object: MediaLibrary.MediaObject) => {
        return {
            id: object.attributes.uuid,
            content: object
        };
    });

    const [listState, setListState] = useState<Array<any>>(items);

    useEffect(() => {
        form.setValue(
            name,
            state.media.map((media: MediaLibrary.MediaObject) => media.attributes)
        );
    }, [state.media]);

    return (
        <div className="border-none mt-10">
            <TypographyH3 className="text-lg font-medium mb-2">Upload attachments</TypographyH3>
            <div className={!maxItems || state.media.length < maxItems ? "border-none bg-white" : "hidden"}>
                <Uploader {...dropZoneProps} {...fileInputProps} add multiple fileTypeHelpText={fileTypeHelpText} />
            </div>
            <Icons />

            <div
                className={`media-library media-library-multiple ${
                    state.media.length == 0 ? "media-library-empty" : "media-library-filled"
                } ${sortable && "media-library-sortable"}`}
            >
                <ListErrors
                    invalidMedia={state.invalidMedia}
                    topLevelErrors={Array.isArray(validationErrors) ? undefined : validationErrors[name]}
                    onClear={clearInvalidMedia}
                />
                {state.media?.length > 0 && (
                    <div className="flex w-full">
                        {/* @ts-ignore */}
                        <ReactSortable
                            list={listState}
                            setList={setListState}
                            className="flex w-full flex-col gap-y-2.5"
                            onEnd={evt => {
                                const source = evt.from;

                                setOrder(
                                    Array.from(source?.children || []).map(element => {
                                        return element.getAttribute("data-media-library-uuid") as string;
                                    })
                                );
                            }}
                        >
                            {state.media.map((object, index) => {
                                const objectErrors = getErrors(object);

                                return (
                                    <div
                                        className="flex items-center justify-start w-full border p-2.5 gap-x-4 rounded-xl"
                                        key={object.attributes.uuid}
                                        data-media-library-uuid={object.attributes.uuid}
                                    >
                                        {sortable && <div className="w-8 hidden">{sortable && <Drag01Icon />}</div>}

                                        <div className="flex items-center grow-0">
                                            <Thumb
                                                uploadInfo={object.upload}
                                                validationRules={validationRules}
                                                imgProps={getImgProps(object)}
                                                onReplace={(file: File) => replaceMedia(object, file)}
                                            />
                                        </div>

                                        <div className="grow">
                                            {!!objectErrors.length ? (
                                                <ItemErrors
                                                    objectErrors={objectErrors}
                                                    onBack={() => clearObjectErrors(object)}
                                                />
                                            ) : (
                                                <>
                                                    {fieldsView({
                                                        object,
                                                        getCustomPropertyInputProps: (propertyName: string) =>
                                                            getCustomPropertyInputProps(object, propertyName),
                                                        getCustomPropertyInputErrors: (propertyName: string) =>
                                                            getCustomPropertyInputErrors(object, propertyName),
                                                        getNameInputProps: () => getNameInputProps(object),
                                                        getNameInputErrors: () => getNameInputErrors(object)
                                                    })}

                                                    <div className="text-xs">{propertiesView({ object })}</div>
                                                </>
                                            )}

                                            {object.upload.uploadProgress > 0 && object.upload.uploadProgress < 100 && (
                                                <Progress
                                                    value={object.upload.uploadProgress}
                                                    max={100}
                                                    className="h-1 mt-2"
                                                />
                                            )}
                                        </div>

                                        {!disabledRemove && (
                                            <div
                                                className="cursor-pointer w-8 h-8 border rounded-full flex justify-center items-center"
                                                onClick={() => {
                                                    removeMedia(object);
                                                }}
                                                {...{ dusk: "remove" }}
                                            >
                                                <MultiplicationSignIcon
                                                    width={16}
                                                    height={16}
                                                    className="text-primary"
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </ReactSortable>
                    </div>
                )}
            </div>
        </div>
    );
}

type DefaultPropertiesViewProps = {
    object: MediaLibrary.MediaObject;
};

function DefaultPropertiesView({ object }: DefaultPropertiesViewProps) {
    return (
        <div className="flex gap-x-1 text-muted-foreground">
            {object.attributes.extension && <div>{object.attributes.extension.toUpperCase()}</div>}
            {object.attributes.size && <div>{(object.attributes.size / 1024).toFixed(2)} KB</div>}
            {object.attributes.original_url && (
                <div>
                    <a
                        href={object.attributes.original_url}
                        download
                        target="_blank"
                        className="media-library-text-link"
                    >
                        {window.mediaLibraryTranslations.download}
                    </a>
                </div>
            )}
        </div>
    );
}

type DefaultFieldsViewProps = {
    object: MediaLibrary.MediaObject;
    getNameInputProps: () => {
        value: any;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    getNameInputErrors: () => Array<string>;
    getCustomPropertyInputProps: (
        propertyName: string
    ) => {
        value: any;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    getCustomPropertyInputErrors: (propertyName: string) => Array<string>;
};

function DefaultFieldsView({ getNameInputProps, getNameInputErrors }: DefaultFieldsViewProps) {
    const nameInputProps: any = getNameInputProps();
    const duskProps: any = { dusk: "media-library-field-name" };

    return (
        <div className="text-sm w-full">
            <input className="w-full" {...nameInputProps} {...duskProps} />
            {getNameInputErrors().map(error => (
                <p key={error} className="media-library-field-error">
                    {error}
                </p>
            ))}
        </div>
    );
}
