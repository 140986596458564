import { utilsQuery } from "@/common";

const define = utilsQuery.defineQueryKeyCreator("purchase-orders");

const QueryKeys = {
    PurhaseOrderItems: define("orderItems"),
    PurhaseOrderItem: define("orderItem"),
    PurhaseOrderCount: define("orderCount"),
    PurhaseOrderProductItems: define("orderProductItems"),
};

export default QueryKeys;
