import { commonHooks } from "@/common";
import React, { useCallback, useEffect, useState } from "react";

import { i18next } from "../services";
import { AppStateContext, IAppStateContext } from "./AppStateContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

const win = window as any;

export function AppStateContextProvider({ children }: IAuthLoaderProviderProps) {
    const { data: translations } = commonHooks.useGetTranslations();
    const [state, setState] = useState<any>({});

    // Load and initialize translations
    useEffect(() => {
        const trs = translations || {};

        Object.keys(trs).forEach(lang => {
            const langTrs = trs[lang] || ({} as any);

            Object.keys(langTrs).forEach(namespace => {
                i18next.addResourceBundle(lang, namespace, langTrs[namespace] as any);
            });
        });

        console.log(trs);
    }, [translations]);

    const value: IAppStateContext = {
        state,
        setState: useCallback(setState, [setState])
    };

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}
