import { config } from "@/app";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { countryTypes } from "@/country";
import { customerTypes } from "@/customer";
import { cn } from "@/lib/utils";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

export interface ICustomerFormProps {
    onSubmit: SubmitHandler<customerTypes.ICustomer>;
    customer: customerTypes.ICustomer;
    countries: countryTypes.ICountry[];
    title?: string;
}

export const CustomerForm = ({ onSubmit, customer, countries, title }: ICustomerFormProps) => {
    const form = useForm({
        defaultValues: {
            ...customer,
            first_name: customer?.first_name || ""
        }
    });

    return (
        <React.Fragment>
            <TypographyH3>{title}</TypographyH3>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="grid grid-cols-6 gap-4">
                    <FormField
                        control={form.control}
                        name="first_name"
                        render={({ field }) => (
                            <FormItem className="col-span-3">
                                <FormLabel>First name</FormLabel>
                                <FormControl>
                                    <Input placeholder="First name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="surname"
                        render={({ field }) => (
                            <FormItem className="col-span-3">
                                <FormLabel>Last name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Last name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="street"
                        render={({ field }) => (
                            <FormItem className="col-span-4">
                                <FormLabel>Street</FormLabel>
                                <FormControl>
                                    <Input placeholder="Street" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="house_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>number</FormLabel>
                                <FormControl>
                                    <Input placeholder="House numbere" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="house_number_extended"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Extended</FormLabel>
                                <FormControl>
                                    <Input placeholder="House number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="zip_code"
                        render={({ field }) => (
                            <FormItem className="col-span-2">
                                <FormLabel>Zip code</FormLabel>
                                <FormControl>
                                    <Input placeholder="Zipcode" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem className="col-span-2">
                                <FormLabel>City</FormLabel>
                                <FormControl>
                                    <Input placeholder="City" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="country_id"
                        render={({ field }) => (
                            <FormItem className="col-span-3">
                                <FormLabel>Country</FormLabel>
                                <FormControl>
                                    <Select onValueChange={field.onChange} defaultValue={String(field.value)}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a verified email to display" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {countries.map(country => (
                                                <SelectItem key={country.id} value={String(country.id)}>
                                                    <Flex className="gap-x-1.5">
                                                        <img
                                                            src={`${config.appUrl}/assets/img/flags/${country.iso_3166_2}.svg`}
                                                            alt=""
                                                            className="w-3 h-2.5 object-cover"
                                                        />
                                                        {country.name}
                                                    </Flex>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className={cn("col-span-6 mt-2")}>
                        <Button type="submit">Save changes</Button>
                    </div>
                </form>
            </Form>
        </React.Fragment>
    );
};
