import { axios } from "@/app";
import { ICustomer } from "./types";

export const getCustomer = async (id: number): Promise<ICustomer> => {
  const response = await axios.get<ICustomer>(`customers/${id}`);

  return response.data;
};

export const getCustomers = async (domainId: number): Promise<ICustomer[]> => {
  const response = await axios.get<ICustomer[]>(
    `domains/${domainId}/customers`
  );

  return response.data;
};

export const updateCustomer = async (
  customer: ICustomer
): Promise<ICustomer> => {
  const response = await axios.put<ICustomer>(
    `customers/${customer.id}`,
    customer
  );

  return response.data;
};
