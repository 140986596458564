import { MultiplicationSignIcon } from "@hugeicons/react";
import { MediaLibrary } from "@spatie/media-library-pro-core/dist/types";
import * as React from "react";

type Props = {
    invalidMedia: MediaLibrary.State["invalidMedia"];
    topLevelErrors?: Array<string>;
    onClear: () => void;
};

export default function ListErrors({ invalidMedia, topLevelErrors = [], onClear }: Props) {
    const groupedInvalidMedia = invalidMedia.reduce((groupedInvalidMedia, invalidMediaObject) => {
        const error = invalidMediaObject.errors[0];

        if (groupedInvalidMedia[error]) {
            groupedInvalidMedia[error].push(invalidMediaObject);
        } else {
            groupedInvalidMedia[error] = [invalidMediaObject];
        }

        return groupedInvalidMedia;
    }, {} as { [error: string]: MediaLibrary.State["invalidMedia"] });

    const [hideTopLevelErrors, setHideTopLevelErrors] = React.useState(false);

    React.useEffect(() => {
        setHideTopLevelErrors(false);
    }, [topLevelErrors]);

    if (!invalidMedia.length && (hideTopLevelErrors || !topLevelErrors.length)) {
        return null;
    }

    function handleClearClick() {
        setHideTopLevelErrors(true);
        onClear();
    }

    return (
        <div className="w-full mb-2.5">
            <ul className="grow-1 flex gap-y-2 flex-col">
                {Object.entries(groupedInvalidMedia).map(([error, invalidObjects], i) => (
                    <>
                        {invalidObjects.map((object, i) => (
                            <li
                                className="flex justify-start items-center w-full rouned-lg border bg-red-50 border-red-100 rounded-xl p-2.5"
                                key={i}
                            >
                                <ObjectPreview
                                    client_preview={object.client_preview || ""}
                                    name={object.attributes.name}
                                />
                                <div className="text-sm ml-4 grow">
                                    <p className="text-sm text-red-500">{error}</p>
                                    <p className="text-xs font-light">{object.attributes.name}</p>
                                    {!hideTopLevelErrors &&
                                        topLevelErrors.map((error, i) => <ListError title={error} key={i} />)}
                                </div>

                                <div
                                    className="cursor-pointer w-8 h-8 border rounded-full rounded-foreground flex justify-center items-center"
                                    onClick={handleClearClick}
                                >
                                    <MultiplicationSignIcon width={16} height={16} className="text-foreground" />
                                </div>
                            </li>
                        ))}
                    </>
                ))}
            </ul>
        </div>
    );
}

type ListErrorProps = {
    title: string;
    children?: React.ReactNode;
};

function ListError({ title, children }: ListErrorProps) {
    return (
        <li className="media-library-listerror">
            {children && (
                <ul className="media-library-listerror-items">
                    {children}

                    <div className="media-library-listerror-icon">
                        <span className="media-library-button media-library-button-error">
                            {/* <Icon icon="error" /> */}
                        </span>
                    </div>
                    <div className="media-library-listerror-content">
                        <div className="media-library-listerror-title"></div>
                    </div>
                </ul>
            )}
        </li>
    );
}

type ObjectPreviewProps = {
    client_preview: string;
    name: string;
};

function ObjectPreview({ client_preview = "", name = "" }: ObjectPreviewProps) {
    const [imageErrored, setImageErrored] = React.useState(false);

    return imageErrored ? (
        <span className="media-library-thumb-extension">
            <span className="text-xs">{name.split(".").pop()}</span>
        </span>
    ) : (
        <img
            className="w-10 h-10 object-cover rounded-lg overflow-hidden shadow-sm borer"
            src={client_preview}
            onError={() => setImageErrored(true)}
        />
    );
}
