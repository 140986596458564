import { authHooks } from "@/auth";
import { Pagination } from "@/components/custom/pagination";
import { DataTable } from "@/components/custom/table";
import { Card, CardContent } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { useGetDomainCarriers } from "../hooks";
import { ICarrier } from "../types";

export const CarrierOverviewPage = () => {
    const [page, setPage] = useState(1);
    const { activeDomain } = authHooks.useAuth();
    const { data: carriers } = useGetDomainCarriers(activeDomain.id, page);

    const columns: ColumnDef<ICarrier>[] = [
        {
            id: "id",
            header: "id",
            accessorKey: "id"
        },
        {
            id: "name",
            header: "name",
            accessorKey: "name"
        },
        {
            id: "key",
            header: "key",
            accessorKey: "key"
        },
        {
            id: "actions",
            header: "actions",
            accessorKey: "actions"
        }
    ];

    return (
        <Fragment>
            <Card>
                <CardContent className="p-0">
                    <DataTable columns={columns} data={carriers?.data || []} />
                </CardContent>
            </Card>
            {carriers?.meta && <Pagination meta={carriers.meta} onPageChange={page => setPage(page)} />}
        </Fragment>
    );
};
