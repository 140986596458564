import { AuthenticationPage } from "@/auth/pages/AuthenticationPage";
import { CarrierOverviewPage } from "@/carrier/pages/CarrierOverviewPage";
import { ChannelItemPage } from "@/channel/pages/ChannelItemPage";
import { ChannelOverviewPage } from "@/channel/pages/ChannelOverviewPage";
import { DashboardPage } from "@/dashboard/pages/DashboardPage";
import { OrderOverviewPage } from "@/order/pages/OrderOverviewPage";
import { ProductOverviewPage } from "@/product/pages/ProductOverviewPage";
import { PurchaseOrderOverviewPage } from "@/purchaseOrder/pages/PurchaseOrderOverviewPage";
import { UserOverviewPage } from "@/users/pages/UserOverviewPage";
import { WarehouseOverviewPage } from "@/warehouse/pages/WarehouseOverviewPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LayoutWithSidebar } from "../layouts/LayoutWithSidebar";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";

// const DashboardPage = React.lazy(() =>
//   import("@/dashboard/pages/DashboardPage").then((module) => ({
//     default: module.DashboardPage,
//   }))
// );

// const UserOverviewPage = React.lazy(() =>
//   import("@/users/pages/UserOverviewPage").then((module) => ({
//     default: module.UserOverviewPage,
//   }))
// );

// const auth = useAuth();
const RouteList = createBrowserRouter([
    {
        path: "/auth/login",
        element: <AuthenticationPage />
    },
    {
        path: "/",
        element: <AuthenticatedRoutes />,
        children: [
            {
                path: "/",
                element: <LayoutWithSidebar />,
                children: [
                    {
                        path: "/",
                        element: <DashboardPage />
                    },
                    {
                        path: "/orders",
                        element: <OrderOverviewPage />
                    },
                    {
                        path: "/orders/:id",
                        element: <OrderOverviewPage />
                    },
                    {
                        path: "/purchase-orders",
                        element: <PurchaseOrderOverviewPage />
                    },
                    {
                        path: "/warehouses",
                        element: <WarehouseOverviewPage />
                    },
                    {
                        path: "/products",
                        element: <ProductOverviewPage />
                    },
                    {
                        path: "/connections",
                        element: <ChannelOverviewPage />
                    },
                    {
                        path: "/connections/:id",
                        element: <ChannelItemPage />
                    },
                    {
                        path: "/connections/new",
                        element: <ChannelItemPage />
                    },
                    {
                        path: "/products/:productId",
                        element: <ProductOverviewPage />
                    },
                    {
                        path: "/carriers",
                        element: <CarrierOverviewPage />
                    },
                    {
                        path: "/users",
                        element: <UserOverviewPage />
                    }
                ]
            }
        ]
    }
]);

export const Routes = () => {
    return <RouterProvider router={RouteList} />;
};
