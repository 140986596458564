import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import {
    Heading01Icon,
    Heading02Icon,
    Heading03Icon,
    LeftToRightListBulletIcon,
    LeftToRightListNumberIcon,
    ParagraphIcon,
    TextBoldIcon,
    TextItalicIcon
} from "@hugeicons/react";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import "./tiptap.css";

export interface ITiptapProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const Tiptap = ({ value, ...props }: ITiptapProps) => {
    const [editorContent, setEditorContent] = useState(`${value}`);
    const MenuBar = () => {
        const { editor } = useCurrentEditor();

        if (!editor) {
            return null;
        }

        return (
            <div className="gap-x-1 flex mb-2">
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    disabled={
                        !editor
                            .can()
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    className={cn("", editor.isActive("bold") ? "is-active" : "")}
                >
                    <TextBoldIcon className="w-3.5" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    disabled={
                        !editor
                            .can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    className={editor.isActive("italic") ? "is-active" : ""}
                >
                    <TextItalicIcon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .setParagraph()
                            .run()
                    }
                    className={editor.isActive("paragraph") ? "is-active" : ""}
                >
                    <ParagraphIcon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run()
                    }
                    className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
                >
                    <Heading01Icon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run()
                    }
                    className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
                >
                    <Heading02Icon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run()
                    }
                    className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
                >
                    <Heading03Icon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleBulletList()
                            .run()
                    }
                    className={editor.isActive("bulletList") ? "is-active" : ""}
                >
                    <LeftToRightListBulletIcon className="w-4" variant="solid" />
                </Button>
                <Button
                    variant="outline"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .toggleOrderedList()
                            .run()
                    }
                    className={editor.isActive("orderedList") ? "is-active" : ""}
                >
                    <LeftToRightListNumberIcon className="w-4" variant="solid" />
                </Button>
            </div>
        );
    };

    useEffect(() => {
        setEditorContent(`${value}`);
    }, [value]);
    const extensions = [
        TextStyle.configure(),
        StarterKit.configure({
            bulletList: {
                keepMarks: true,
                keepAttributes: false
            },
            orderedList: {
                keepMarks: true,
                keepAttributes: false
            }
        })
    ];

    return (
        <div>
            <textarea className="hidden" value={editorContent} {...props} />
            <EditorProvider
                slotBefore={<MenuBar />}
                extensions={extensions}
                content={`${value}`}
                onUpdate={({ editor }) => {
                    setEditorContent(editor.getHTML());
                }}
            />
        </div>
    );
};
