import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constans/QueryKeys";
import {
    addCustomerAlert,
    confirmPickUpOrder,
    createOrder,
    createOrderNotification,
    forceQueueStatusToSuccess,
    getDownloadCsv,
    getOrder,
    getOrderCountPerStatus,
    getOrders,
    getProductOrders,
    getShipmentLabels,
    removeCustomerAlert,
    resetOrderAttachmentFlag,
    updateCarrier,
    updateExactDeliveryDate,
    updateOrder,
    updateOrderStatus,
    updateOrderToWarehouse,
    updateQueueStatus,
    updateResetOrder
} from "./queries";
import { IOrder, IOrderCount, IOrderResponse } from "./types";

export const useGetOrders = ({
    domain,
    status,
    pageId,
    search,
    filters,
    maxOrderItems
}: {
    domain: domainTypes.IDomain;
    status: string;
    pageId: number;
    search: string | null;
    filters: any;
    maxOrderItems: number;
}) =>
    useQuery<IOrderResponse>(
        [QueryKeys.OrderItems, { domainId: domain.id, status, pageId, search, filters, maxOrderItems }],
        () => getOrders(domain, status, pageId, search, filters, maxOrderItems)
    );

export const useGetOrder = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IOrder, Error>([QueryKeys.OrderItem, { id }], () => getOrder(id), options);

export const useGetOrderCountPerStatus = (domain: domainTypes.IDomain) =>
    useQuery<IOrderCount[]>([QueryKeys.OrderCount, { domain_id: domain.id }], () => getOrderCountPerStatus(domain));

export const useUpdateOrdersToWarehouse = () => useMutation(updateOrderToWarehouse);

export const useGetDownloadCsv = () => useMutation(getDownloadCsv);

export const useGetShipmentLabels = () => useMutation(getShipmentLabels);

export const useResetOrderAttachmentFlag = () => useMutation(resetOrderAttachmentFlag);

export const useUpdateOrder = () =>
    useMutation(updateOrder, {
        onSuccess: (orderId: number) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: orderId }]);
        }
    });

export const useUpdateOrderStatus = () =>
    useMutation(updateOrderStatus, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItems, { id: order.id }]);
            queryClient.invalidateQueries(QueryKeys.OrderCount);
        }
    });

export const useCreateOrderNotification = () =>
    useMutation(createOrderNotification, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateExactDeliveryDate = () =>
    useMutation(updateExactDeliveryDate, {
        onSuccess: () => queryClient.invalidateQueries(QueryKeys.OrderItems)
    });

export const useUpdateResetOrder = () =>
    useMutation(updateResetOrder, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateQueueStatus = () =>
    useMutation(updateQueueStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.OrderItems);
        }
    });

export const useForceQueueStatusToSuccess = () =>
    useMutation(forceQueueStatusToSuccess, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.OrderItems);
        }
    });

export const useCreateOrder = () =>
    useMutation(createOrder, {
        onSuccess: () => {
            console.log("added");
        }
    });

export const useGetProductOrders = (productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery([QueryKeys.OrderProductItems, { id: productId }], () => getProductOrders(productId), options);

export const useAddCustomerAlert = () =>
    useMutation(addCustomerAlert, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useRemoveCustomerAlert = () =>
    useMutation(removeCustomerAlert, {
        onSuccess: (order: IOrder) => {
            queryClient.invalidateQueries([QueryKeys.OrderItem, { id: order.id }]);
        }
    });

export const useUpdateCarrier = (orderId: number) =>
    useMutation((carrierId: number) => updateCarrier(orderId, carrierId));

export const useConfirmPickUpOrder = () =>
    useMutation(confirmPickUpOrder, {
        onSuccess: () => {
            // return order;
        }
    });
