import { carrierTypes } from "@/carrier";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

interface ICarrierFormProps {
  carriers: carrierTypes.ICarrier[];
  selectedCarrier?: carrierTypes.ICarrier;
  onSubmit: (test: any) => void;
}

export const CarrierForm = ({
  carriers,
  onSubmit,
  selectedCarrier,
}: ICarrierFormProps) => {
  const { handleSubmit, control, reset } = useForm();

  useEffect(() => reset(selectedCarrier), [selectedCarrier, reset]);

  return (
    // <Form onSubmit={handleSubmit(onSubmit)} fullWidth={true}>
    //     <div className="mb-32 mt-4 w-64">
    //         <ControlledFormField
    //             control={control}
    //             name="id"
    //             options={carriers}
    //             component={SelectField}
    //             getOptionLabel={(option: any) => option.name}
    //             getOptionValue={(option: any) => option.id}
    //             valueFormatter={(optionValue: any) => optionValue?.id}
    //             valueParser={(selectedOption: any) => carriers.find((carrier) => selectedOption === carrier.id)}
    //             label="Select carrier"
    //             rules={{ required: "This is required" }}
    //         />
    //     </div>

    //     <div className="flex col-span-4 px-4 flex-row-reverse bg-slate-100 -mx-6 -my-6 p-4">
    //         <Button variant="primary" color="purple" type="submit">
    //             Confirm
    //         </Button>
    //     </div>
    // </Form>
    <div>todo</div>
  );
};
