import { queryClient } from "@/app";
import { authHooks } from "@/auth";
import { carrierHooks } from "@/carrier";
import { channelHooks } from "@/channel";
import { DropdownMenu, IDropdownMenuItem } from "@/components/custom/dropdownMenu";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { QueueStatus } from "@/queue";
import { HasOneOfRoles, rbacUtils, Roles } from "@/rbac";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { CarrierDialog } from "../components/CarrierDialog";
import { ExactDeliveryDialog } from "../components/ExactDeliveryDialog";
import { FilterDropDown } from "../components/FilterDropdown";
import { OrderTable } from "../components/OrderTable";
import { OrderStatus } from "../constans/OrderStatus";
import { QueryKeys } from "../constans/QueryKeys";
import {
    useForceQueueStatusToSuccess,
    useGetDownloadCsv,
    useGetOrderCountPerStatus,
    useGetOrders,
    useGetShipmentLabels,
    useResetOrderAttachmentFlag,
    useUpdateExactDeliveryDate,
    useUpdateOrderStatus,
    useUpdateOrdersToWarehouse,
    useUpdateQueueStatus
} from "../hooks";
import { IOrder, IOrderCount, IQueue } from "../types";
import OrderSheet from "./OrderSheet";

export const OrderOverviewPage = () => {
    const tableApi = React.createRef<any>();
    const [orderId, setOrderId] = useState<number | undefined>(undefined);
    const [isOrderSheetOpen, setIsOrderSheetOpen] = useState<boolean>(false);
    const { activeDomain, user } = authHooks.useAuth();
    const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [filters, setFilters] = useState<any | null>(null);
    const [newStatus, setNewStatus] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const paramStatus: any = searchParams.get("status") || "New";
    const [activeStatusKey, setActiveStatusKey] = useState<any>(paramStatus);
    const [activeStatusValue, setActiveStatusValue] = useState<any>(OrderStatus[activeStatusKey]);
    const [page, setPage] = useState<number>(Number(searchParams.get("page") || 1));
    const [maxOrderItems, sexMaxOrderItems] = useState<number>(100);
    const { watch } = useForm<{
        per_page: { label: string; value: number };
    }>({
        defaultValues: {
            per_page: {
                label: "100",
                value: maxOrderItems
            }
        }
    });
    const perPage = watch("per_page");
    const [isCarrierDialogOpen, setIsCarrierDialogOpen] = useState<boolean>(false);
    const [isExactDeliveryDateDialogOpen, setIsExactDeliveryDateDialogOpen] = useState<boolean>(false);
    const { data: orders, isLoading: isOrdersLoading } = useGetOrders({
        domain: activeDomain,
        status: activeStatusKey,
        pageId: page,
        search: searchQuery,
        filters,
        maxOrderItems
    });
    const { data: orderCountPerStatus } = useGetOrderCountPerStatus(activeDomain);
    const { data: channels = [] } = channelHooks.useGetChannels(activeDomain);
    const { mutateAsync: updateOrderToWarehouse } = useUpdateOrdersToWarehouse();
    const { mutateAsync: downloadCsv } = useGetDownloadCsv();
    const { mutateAsync: downloadShipmentLabels } = useGetShipmentLabels();
    const { mutateAsync: updateOrderStatus } = useUpdateOrderStatus();
    const { mutateAsync: updateExactDeliveryDate } = useUpdateExactDeliveryDate();
    const { mutateAsync: resetOrderAttachmentFlag } = useResetOrderAttachmentFlag();
    const { mutateAsync: updateQueueStatus } = useUpdateQueueStatus();
    const { mutateAsync: forceQueueStatusToSuccess } = useForceQueueStatusToSuccess();
    const { data: carriers, isLoading } = carrierHooks.useGetDomainCarriers(activeDomain.id, 1);

    useEffect(() => {
        sexMaxOrderItems(perPage.value);
        setPage(1);
    }, [perPage]);

    const handleChangeStatus = (orderStatusValue: number) => {
        const orderStatusKey = OrderStatus[Number(orderStatusValue)];

        if (activeStatusKey !== orderStatusKey) {
            setPage(1);
            setSearchQuery(null);
            setFilters(null);
            tableApi.current?.unSelectAllRows();
        }

        setSearchParams({ status: orderStatusKey });
        setActiveStatusKey(orderStatusKey);
        setActiveStatusValue(orderStatusValue);
        // tableApi.current?.unSelectAllRows();
    };

    const handleUpdateOrderStatus = (status: number) => {
        if (
            (status === OrderStatus.FutureShipping || status === OrderStatus.Shipping) &&
            activeStatusValue === OrderStatus.New
        ) {
            setIsCarrierDialogOpen(true);
            setNewStatus(status);
        } else {
            updateOrderStatus({
                orderIds: selectedOrders,
                newStatus: status,
                currentStatus: activeStatusValue
            }).then(() => {
                // tableApi.current?.unSelectAllRows();
            });
        }
    };

    const handleProcessToWarehouse = () => {
        const orderIds = tableApi.current?.getSelectedRows();
        if (orderIds) {
            updateOrderToWarehouse({
                orderIds,
                currentStatus: activeStatusValue
            }).then(() => {
                tableApi.current?.unSelectAllRows();
                setPage(1);
                queryClient.invalidateQueries(QueryKeys.OrderItems);
                queryClient.invalidateQueries(QueryKeys.OrderCount);
            });
        }
    };

    const handleUpdateExactDeliveryDate = (formValues: any) => {
        if (selectedOrders.length) {
            updateExactDeliveryDate({
                orderIds: selectedOrders as [],
                formValues
            }).then(() => {
                setIsExactDeliveryDateDialogOpen(false);
                // tableApi.current?.unSelectAllRows();
            });
        }
    };

    const handleSelectCarrier = (formValues: { id: number }) => {
        updateOrderStatus({
            orderIds: selectedOrders,
            newStatus,
            carrierId: formValues.id,
            currentStatus: activeStatusValue
        }).then(() => {
            setIsCarrierDialogOpen(false);
            // tableApi.current?.unSelectAllRows();
        });
    };

    const handleDownloadCsv = () => {
        downloadCsv({
            domainId: activeDomain.id,
            // orderIds: tableApi.current?.getSelectedRows() || [],
            orderIds: [],
            status: activeStatusValue,
            filters
        });
    };

    const handleQueueUpdate = (queue: IQueue) => {
        if (queue.status !== QueueStatus.Success && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)) {
            updateQueueStatus(queue);
        }
    };

    const handleResetOrderAttachementFlag = () => {
        resetOrderAttachmentFlag({
            orderIds: []
            // orderIds: tableApi.current?.getSelectedRows() || [],
        });
    };

    const handleForceQueueStatusToSuccess = () => {
        forceQueueStatusToSuccess({
            orderIds: []
            // orderIds: tableApi.current?.getSelectedRows() || [],
        });
    };

    const handleShipmentLabels = () => {
        downloadShipmentLabels({
            // orderIds: tableApi.current?.getSelectedRows() || [],
            orderIds: []
        });
    };

    //   const handleSearch = (query: string) => {
    //     setPage(1);
    //     setSearchQuery(query);
    //   };

    const handleFilter = (filterQuery: any) => {
        setFilters(filterQuery);
        setPage(1);
    };

    const filterButtons = (items: IDropdownMenuItem[]) =>
        items.map((item: IDropdownMenuItem) => ({
            ...item,
            disabled: item.disabled || !item.show || !item.show.includes(activeStatusValue)
        }));

    const buttonItems: IDropdownMenuItem[] = [
        {
            name: "Download CSV",
            description: "CSV with customer details.",
            onClick: () => handleDownloadCsv(),
            show: [OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Download shipment labels",
            description: "You can only download shipment labels once.",
            onClick: () => handleShipmentLabels(),
            show: [OrderStatus.Warehouse],
            disabled: !selectedOrders.length
        },
        {
            name: "Shipping orders",
            description: "Orders shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Shipping),
            show: [OrderStatus.New, OrderStatus.FutureShipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Future shipment",
            description: "Orders are not shipped next working day.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.FutureShipping),
            show: [OrderStatus.New, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "To warehouse",
            description: "Download slips and move to warehosue",
            onClick: () => handleProcessToWarehouse(),
            show: [OrderStatus.Shipping, OrderStatus.PickUp],
            disabled: !selectedOrders.length
        },
        {
            name: "Cancel",
            description: "Cancel order no further actions.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Cancelled),
            show: [OrderStatus.New, OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        },
        {
            name: "Completed",
            description: "Order completed, no further actions needed.",
            onClick: () => handleUpdateOrderStatus(OrderStatus.Completed),
            show: [],
            disabled: !selectedOrders.length
        },
        {
            name: "Reset attachment flag",
            description: "Reset the attachemnt, so it can be downloaded again. Admin only",
            onClick: () => handleResetOrderAttachementFlag(),
            show: [
                OrderStatus.New,
                OrderStatus.FutureShipping,
                OrderStatus.Shipping,
                OrderStatus.Warehouse,
                OrderStatus.Shipped
            ],
            disabled: false // !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin)),
        },
        {
            name: "Force queue status",
            description:
                "Force all queue statusses of an order to be successfull, Wrong use can cause delivery of unpaid products!",
            onClick: () => handleForceQueueStatusToSuccess(),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !(selectedOrders.length && rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin))
        },
        {
            name: "Update exact delivery",
            description: "Mass update the exact delivery update",
            onClick: () => setIsExactDeliveryDateDialogOpen(true),
            show: [OrderStatus.FutureShipping, OrderStatus.Shipping],
            disabled: !selectedOrders.length
        }
    ];

    const onRefChange = useCallback((node: any) => {
        // console.log(node);
        // setSelectedOrders(tableApi.current?.getSelectedRows() || []);
    }, []);

    const tabItems: Array<{ name: string; count: number; orderStatus: number }> = [
        {
            name: "Pickup",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.PickUp)?.count || 0,
            orderStatus: OrderStatus.PickUp
        },
        {
            name: "New",
            count: orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.New)?.count || 0,
            orderStatus: OrderStatus.New
        },
        {
            name: "Future shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.FutureShipping)
                    ?.count || 0,
            orderStatus: OrderStatus.FutureShipping
        },
        {
            name: "Shipping",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipping)?.count ||
                0,
            orderStatus: OrderStatus.Shipping
        },
        {
            name: "Warehouse",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Warehouse)?.count ||
                0,
            orderStatus: OrderStatus.Warehouse
        },
        {
            name: "Shipped",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Shipped)?.count || 0,
            orderStatus: OrderStatus.Shipped
        },
        {
            name: "Cancelled",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Cancelled)?.count ||
                0,
            orderStatus: OrderStatus.Cancelled
        },
        {
            name: "Completed",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Completed)?.count ||
                0,
            orderStatus: OrderStatus.Completed
        },
        {
            name: "Error",
            count:
                orderCountPerStatus?.find((statuss: IOrderCount) => statuss.status === OrderStatus.Error)?.count || 0,
            orderStatus: OrderStatus.Error
        }
    ];

    return (
        <React.Fragment>
            <Flex
                alignItems="end"
                justifyContent="start"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b ")}
            >
                {tabItems.map((tabItem, index) => (
                    <div
                        key={index}
                        onClick={() => handleChangeStatus(tabItem.orderStatus)}
                        className={cn(
                            "flex items-center justify-center px-4 pb-4 text-center text-sm transition-colors hover:text-primary cursor-pointer whitespace-nowrap",
                            tabItem.orderStatus === activeStatusValue
                                ? "font-medium text-primary border-b-2 border-primary"
                                : "text-muted-foreground"
                            //   {
                            //     "border-b-2 border-purple-800":
                            //       tabItem.orderStatus === activeStatusValue,
                            //   }
                        )}
                    >
                        <span className="mr-3">{tabItem.name}</span>
                        {tabItem.count > 0 && (
                            <Badge
                                className="rounded-full text-[10px] py-0.5 h-5 px-2"
                                color={tabItem.orderStatus === activeStatusValue ? "purple" : "slate"}
                            >
                                {tabItem.count}
                            </Badge>
                        )}
                    </div>
                ))}
            </Flex>

            <React.Fragment>
                <div className="flex justify-between mb-8">
                    <div className="flex items-center gap-16">
                        <h3
                            className={cn(
                                "flex items-center text-sm",
                                selectedOrders.length ? "text-slate-600" : "text-slate-300"
                            )}
                        >
                            {`Selected ${[].length} from ${orders?.data.length} orders`}
                        </h3>
                    </div>
                    <div className="flex gap-x-4">
                        <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin, Roles.Developer]}>
                            <DropdownMenu name="Actions" items={filterButtons(buttonItems)} />
                        </HasOneOfRoles>
                        <FilterDropDown
                            formValues={filters}
                            carriers={carriers?.data || []}
                            channels={channels}
                            onSubmit={handleFilter}
                        />
                        <HasOneOfRoles role={[Roles.Admin, Roles.SuperAdmin, Roles.Developer]}>
                            todo
                            {/* <Button
                  color="slate"
                  icon={PlusIcon}
                  onClick={() => naviate("/orders/new")}
                >
                  New order
                </Button> */}
                        </HasOneOfRoles>
                        <HasOneOfRoles role={Roles.Admin}>
                            <Button
                                //   icon={ArrowDownTrayIcon}
                                onClick={() => handleDownloadCsv()}
                                variant="outline"
                                disabled={true}
                            >
                                <span className="flex items-center h-full text-sm">
                                    Download CSV
                                    {selectedOrders.length > 0 && (
                                        <div className="ml-2">
                                            <Badge>{selectedOrders.length}</Badge>
                                        </div>
                                    )}
                                </span>
                            </Button>
                        </HasOneOfRoles>
                    </div>
                </div>

                {isOrdersLoading ? (
                    <DataTableSkeleton />
                ) : (
                    <Card className="overflow-hidden rounded-md p-0 shadow-3xl">
                        <CardHeader>
                            <Flex justifyContent="end">
                                <div className="w-64">
                                    <SearchField onChange={setSearchQuery} />
                                </div>
                            </Flex>
                        </CardHeader>
                        <CardContent className="p-0">
                            <OrderTable
                                activeStatus={activeStatusValue}
                                orders={orders}
                                onClickQueueStatus={handleQueueUpdate}
                                ref={onRefChange}
                                onEditOrder={(order: IOrder) => {
                                    setOrderId(order.id), setIsOrderSheetOpen(true);
                                }}
                            />
                        </CardContent>
                    </Card>
                )}

                {orders?.data && <Pagination className="mt-4" meta={orders?.meta} onPageChange={setPage} />}
            </React.Fragment>

            <CarrierDialog
                carriers={carriers?.data || []}
                isOpen={isCarrierDialogOpen}
                onSubmit={(value: any) => handleSelectCarrier(value)}
                onClickClose={() => setIsCarrierDialogOpen(prevState => !prevState)}
            />

            <ExactDeliveryDialog
                isOpen={isExactDeliveryDateDialogOpen}
                onSubmit={(exactDeliveryDate: Date) => handleUpdateExactDeliveryDate(exactDeliveryDate)}
                onClickClose={() => setIsExactDeliveryDateDialogOpen(prevState => !prevState)}
            />

            <OrderSheet open={isOrderSheetOpen} onClose={() => setIsOrderSheetOpen(false)} orderId={orderId} />
        </React.Fragment>
    );
};
