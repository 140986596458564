import { axios } from "@/app";
import { PurchaseOrderStatus } from "./constans/PurchaseOrderStatus";
import { IPurchaseOrder } from "./types";

export const getDomainPurchaseOrders = async (
    domainId: number,
    page: number,
    status: PurchaseOrderStatus,
    searchQuery: string | null
): Promise<IPurchaseOrder[]> => {
    const response = await axios.get<{ data: IPurchaseOrder[] }>(`/domains/${domainId}/purchase-orders`, {
        params: {
            page,
            status,
            searchQuery
        }
    });

    return response.data.data;
};

export const getPurchaseOrder = async (purchaseOrderId: number): Promise<IPurchaseOrder> => {
    const response = await axios.get<{ data: IPurchaseOrder }>(`/purchase-orders/${purchaseOrderId}`);

    return response.data.data;
};

export const createPurchaseOrder = async (domainId: number, data: IPurchaseOrder): Promise<IPurchaseOrder> => {
    const response = await axios.post<{ data: IPurchaseOrder }>(`/domains/${domainId}/purchase-orders`, data);

    return response.data.data;
};

export const updatePurchaseOrder = async (purchaseOrderId: number, data: IPurchaseOrder): Promise<number> => {
    await axios.put<null>(`/purchase-orders/${purchaseOrderId}`, data);

    return purchaseOrderId;
};

export const setPurchaseOrderStatusCompleted = async (purchaseOrderId: number): Promise<number> => {
    await axios.put<null>(`/purchase-orders/${purchaseOrderId}/completed`);

    return purchaseOrderId;
};
