import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import Hls from "hls.js";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserAuthForm } from "../components/UserAuthForm";

export const AuthenticationPage = () => {
    const videoRef = useRef<null | HTMLVideoElement>(null);
    const { t } = useTranslation("auth");

    useEffect(() => {
        const hls = new Hls();

        if (Hls.isSupported() && videoRef.current) {
            hls.loadSource(
                "https://video.squarespace-cdn.com/content/v1/63cf9adef44b04570b67453a/4e29d2db-c06a-47eb-a0e8-8f6d6b9ab37e/playlist.m3u8"
            );
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.ERROR, err => {
                console.log(err);
            });
        } else {
            console.log("load");
        }
        return () => {
            hls.destroy();
        };
    }, []);

    return (
        <>
            <div className="md:hidden">
                {/* <Image
          src="/examples/authentication-light.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="block dark:hidden"
        />
        <Image
          src="/examples/authentication-dark.png"
          width={1280}
          height={843}
          alt="Authentication"
          className="hidden dark:block"
        /> */}
            </div>
            <div className="container relative hidden h-full max-h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
                <Link
                    to="/examples/authentication"
                    className={cn(buttonVariants({ variant: "ghost" }), "absolute right-4 top-4 md:right-8 md:top-8")}
                >
                    {t("auth:register")}
                </Link>

                <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
                    <div className="absolute inset-0 bg-zinc-900/80 z-10" />
                    <video
                        ref={videoRef}
                        autoPlay={true}
                        controls={true}
                        muted={true}
                        className="absolute inset-0 w-full h-full object-cover"
                    />

                    <div className="relative z-20 flex items-center text-lg font-medium">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="mr-2 h-6 w-6"
                        >
                            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
                        </svg>
                        E-win Trading Group
                    </div>
                    <div className="relative z-20 mt-auto">
                        <blockquote className="space-y-2">
                            <p className="text-lg">
                                &ldquo;Streamline Your Business, Amplify Your Success One System, <br /> Infinite
                                Possibilities.&rdquo;
                            </p>
                        </blockquote>
                    </div>
                </div>
                <div className="lg:p-8">
                    <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                        <div className="flex flex-col space-y-2 text-center">
                            <h1 className="text-2xl font-semibold tracking-tight">{t("welcome")}</h1>
                            <p className="text-sm text-muted-foreground">
                                Enter your email below to create your account
                            </p>
                        </div>
                        <UserAuthForm />
                        <p className="px-8 text-center text-sm text-muted-foreground">
                            By clicking continue, you agree to our{" "}
                            <Link to="/terms" className="underline underline-offset-4 hover:text-primary">
                                Terms of Service
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy" className="underline underline-offset-4 hover:text-primary">
                                Privacy Policy
                            </Link>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
