import { axios } from "@/app";
import { ISupplier } from "./types";

export const getDomainSuppliers = async (domainId: number): Promise<ISupplier[]> => {
    const response = await axios.get<{ data: ISupplier[] }>(`/domains/${domainId}/suppliers`);

    return response.data.data;
};

export const createDomainSupplier = async (domainId: number, data: ISupplier): Promise<ISupplier> => {
    const response = await axios.post<{ data: ISupplier }>(`/domains/${domainId}/suppliers`, data);

    return response.data.data;
};
