import { get } from "lodash";

type TSupplantData = { [key: string]: any } | any[];

/**
 * supplant
 * Modified version of Douglas Crockford's implementation:
 *  - converted to ts
 *  - allow dot separated path for nested data object's
 * @source: https://gist.github.com/pbroschwitz/3891293
 * @source: https://jsfiddle.net/ThomasBurleson/4crkj0w1/
 * @param tpl
 * @param data
 */
export const supplant = (tpl: string, data: TSupplantData) => {
    const hasValue = (r: any) => typeof r === "string" || typeof r === "number" || typeof r === "boolean";
    const hasToString = (r: any) => r && "toString" in r && typeof r.toString === "function";

    return tpl.replace(/\{([^\{\}]*)\}/g, (a: any, b: string) => {
        const r = get(data, b);
        return hasValue(r) ? r : hasToString(r) ? r.toString() : a;
    });
};

/**
 * Capitalize
 * Capitalize first char
 * @param str
 */
export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * uncapitalize
 * Uncapitalize first char (lowercase)
 * @param str
 */
export const uncapitalize = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

/**
 * toSnakeCase
 * Converts camel cased string to snake case or (optional) to kebab case
 */
export const toSnakeCase = (str: string, separator: string = "_") => {
    return str.replace(/[\w]([A-Z])/g, (match: string) => `${match[0]}${separator}${match[1]}`).toLowerCase();
};

/**
 * toCamelCase
 * Will convert kebab-case and snake-case to camel-case
 * @param str
 */
export const toCamelCase = (str: string) => {
    return str.replace(/[_|-](\w)/g, m => m[1].toUpperCase());
};
