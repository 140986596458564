import { CalendarIcon } from "@heroicons/react/24/outline";
import { ClockIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import React from "react";
import { IChannel } from "../types";
import { config } from "@/app";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

interface IChannelListItemProps {
    channel: IChannel;
    onEditClick: (channel: IChannel) => void;
}

export const ChanneLListItem = ({ channel, onEditClick }: IChannelListItemProps) => (
    <Card key={channel.id}>
        <CardContent className="p-4">
            <div className="flex flex-col gap-y-4">
                <div className="w-full flex items-center justify-between space-x-6">
                    <img
                        src={`${config.appUrl}/assets/img/channels/${channel.icon}`}
                        className="h-8 w-8"
                        alt={channel.key}
                    />
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="text-gray-900 text-sm font-medium truncate">{channel.company_name}</h3>
                        </div>
                        <p className="text-gray-500 text-sm truncate">{channel.name}</p>
                    </div>
                </div>
                <div>
                    {channel.is_active ? (
                        <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                <div className="size-1.5 rounded-full bg-emerald-500"></div>
                            </div>
                            <p className="text-xs/5 text-gray-500">Active</p>
                        </div>
                    ) : (
                        <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-red-500/20 p-1">
                                <div className="size-1.5 rounded-full bg-red-500"></div>
                            </div>
                            <p className="text-xs/5 text-gray-500">Inactive</p>
                        </div>
                    )}
                </div>
                <div className="flex flex-col text-gray-600 text-sm gap-y-2">
                    <div className="flex text-xs">
                        <CalendarIcon className="w-4 mr-2" />
                        Added: {format(new Date(channel.created_at), "dd-MM-yyyy")}
                    </div>
                    <div className="flex text-xs">
                        <ClockIcon className="w-4 mr-2" />
                        {channel.latest_sync_at ? (
                            <React.Fragment>
                                Last syncs: {format(new Date(channel.latest_sync_at), "dd-MM-yyyy kk:mm")}
                            </React.Fragment>
                        ) : (
                            <p className="text-red-700">Not synced</p>
                        )}
                    </div>
                </div>
            </div>
        </CardContent>
        <CardFooter className="border-t p-1 flex items-center justify-center">
            <Button
                variant="ghost"
                onClick={() => onEditClick(channel)}
                className="text-foreground/60 hover:bg-transparent hover:underline underline-offset-4"
            >
                Edit channel integration
            </Button>
        </CardFooter>
    </Card>
);
