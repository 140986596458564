import { queryClient } from "@/app/services/queryClient";
import { AuthContext } from "@/auth/components/AuthContext";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "./constants/QueryKeys";
import {
  getUser,
  login,
  logout,
  requestForgotPassword,
  requestResetPassword,
  switchDomain,
  validateResetLink,
} from "./queries";
import { IAuthData, IResetTokenResponse } from "./types";

export const useForgotPassword = () => useMutation(requestForgotPassword);

export const useResetPassword = () => {
  const navigate = useNavigate();

  return useMutation(requestResetPassword, {
    onSuccess: () => {
      navigate("/auth/login");
    },
  });
};

export const useValidateRestPasswordToken = (token: string) => {
  const navigate = useNavigate();

  return useQuery<IResetTokenResponse>(
    QueryKeys.PasswordReset,
    () => validateResetLink(token),
    {
      onError: (error) => {
        navigate("/auth/login");
      },
      retry: false,
    }
  );
};

export const useLogout = () =>
  useMutation(logout, {
    onSuccess: () => {
      queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
    },
  });

export const useLogin = () =>
  useMutation(login, {
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.Authenticate, data);
    },
    onError: (error: AxiosError) => console.log(error.response?.data),
  });

export const useGetUser = () =>
  useQuery<IAuthData>(QueryKeys.Authenticate, getUser, {
    onError: () => {
      queryClient.setQueryData(QueryKeys.Authenticate, { user: null });
    },
    retry: false,
    refetchInterval: false,
  });

export const useUpdateSwitchDomain = () =>
  useMutation(switchDomain, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};
