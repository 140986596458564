import { MoneyFormatter } from "@/common";
import { DataTable } from "@/components/custom/table";
import { Card, CardContent } from "@/components/ui/card";
import { ColumnDef } from "@tanstack/react-table";
import { Fragment } from "react";
import { IOrderProduct } from "../types";

interface IProductTableProps {
    products: IOrderProduct[];
}

export const OrderedProductTable = ({ products }: IProductTableProps) => {
    const totalPrice = products.reduce((acc, product) => acc + product.quantity * product.price, 0);
    const totalFee = products.reduce((acc, product) => acc + product.transaction_fee, 0);
    const columns: ColumnDef<IOrderProduct>[] = [
        {
            id: "name",
            header: "Name",
            cell: ({ row }) => (
                <div>
                    <p title={row.original.name} className="font-medium">
                        {row.original.name}
                    </p>
                    <p className="text-xs text-slate-500">* {row.original.order_name}</p>
                    <p className="truncate w-56">{row.original.ean}</p>
                </div>
            ),
        },
        {
            id: "quantity",
            header: "Qty",
            accessorKey: "quantity",
        },
        {
            id: "price",
            header: "Price",
            cell: ({ row }) => MoneyFormatter(row.original.price),
        },
        {
            id: "total",
            header: "Total",
            cell: ({ row }) => MoneyFormatter(row.original.quantity * row.original.price),
        },
    ];

    return (
        <Fragment>
            <Card className="p-0 overflow-hidden shadow-none rounded-md border-none">
                <CardContent className="p-0 shadow-none">
                    <DataTable data={products} columns={columns} />
                </CardContent>
            </Card>
            <div className="p-4 mt-12">
                <div className="grid grid-cols-4 text-right">
                    <div className="col-span-2 hidden md:block" />
                    <div className="col-span-1 hidden md:block">
                        <div className="text-xs text-slate-600">Transaction fee</div>
                        <div className="text-lg text-slate-600">{MoneyFormatter(totalFee)}</div>
                    </div>
                    <div className="col-span-4 md:col-span-1">
                        <div className="text-xs text-slate-600">Total value</div>
                        <div className="text-lg text-slate-600">{MoneyFormatter(totalPrice)}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
