import { useAuth } from "@/auth/hooks";
import { Sheet, SheetContent, SheetTitle } from "@/components/ui/sheet";
import { identityHooks } from "@/identity";
import { cn } from "@/lib/utils";
import { SupplierForm, supplierHooks, supplierTypes } from "@/supplier";
import { warehouseHooks } from "@/warehouse";
import { Fragment, useState } from "react";
import { PurchaseOrderForm } from "../components/PurchaseOrderForm";
import { useCreateDomainPurchaseOrder } from "../hooks";
import { IPurchaseOrder } from "../types";

interface IPurchaseOrderCreateSheetProps {
    open: boolean;
    onClose: () => void;
}

export const PurchaseOrderCreateSheet = ({ open, onClose }: IPurchaseOrderCreateSheetProps) => {
    const [supplierId, setSupplierId] = useState<number | null>(null);
    const [isSecondSheetOpen, setIsSecondSheetOpen] = useState<boolean>(false);
    const { activeDomain } = useAuth();
    const { data: warehouses = [] } = warehouseHooks.useGetDomainWarehouses(activeDomain.id);
    const { data: suppliers = [] } = supplierHooks.useGetDomainSuppliers(activeDomain.id, {
        enabled: !!activeDomain
    });
    const { data: identities = [] } = identityHooks.useGetDomainIdentities(activeDomain.id, {
        enabled: !!activeDomain
    });
    const { mutateAsync: createSupplier } = supplierHooks.useCreateDomainSupplier(activeDomain.id);
    const { mutateAsync: createPurchaseOrder } = useCreateDomainPurchaseOrder(activeDomain.id);

    const handleAddSupplier = (data: supplierTypes.ISupplier) => {
        createSupplier(data).then(res => {
            setSupplierId(res.id);
            setIsSecondSheetOpen(false);
        });
    };

    const handleCreateDomainPurchaseOrder = (data: IPurchaseOrder) => {
        createPurchaseOrder(data).then(() => {
            onClose();
        });
    };

    return (
        <Fragment>
            <Sheet open={isSecondSheetOpen} onOpenChange={setIsSecondSheetOpen}>
                <div>
                    <SheetContent className={cn("sm:max-w-[32rem] px-0")}>
                        <SheetTitle className="px-8" onClick={() => setIsSecondSheetOpen(value => !value)}>
                            New supplier
                        </SheetTitle>
                        <SupplierForm onSubmit={handleAddSupplier} />
                    </SheetContent>
                </div>
            </Sheet>
            <Sheet open={open} onOpenChange={onClose}>
                <div>
                    <SheetContent
                        className={cn("sm:max-w-4xl px-0 translate-x-0", isSecondSheetOpen && "-translate-x-[33rem]")}
                    >
                        <SheetTitle className="px-8" onClick={() => setIsSecondSheetOpen(value => !value)}>
                            Create purchase order
                        </SheetTitle>

                        <PurchaseOrderForm
                            handleAddSupplier={() => setIsSecondSheetOpen(true)}
                            domain={activeDomain}
                            suppliers={suppliers}
                            warehouses={warehouses}
                            identities={identities}
                            supplierId={supplierId}
                            onSubmit={handleCreateDomainPurchaseOrder}
                        />
                    </SheetContent>
                </div>
            </Sheet>
        </Fragment>
    );
};
