import { authHooks } from "@/auth";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { WarehouseTable } from "../components/WarehouseTable";
import { useGetDomainWarehouses } from "../hooks";
import { IWarehouse } from "../types";

export const WarehouseOverviewPage = () => {
    const navigate = useNavigate();
    const auth = authHooks.useAuth();
    const { data: warehouses } = useGetDomainWarehouses(auth.activeDomain.id);
    const handleEditClick = (warehouse: IWarehouse) => navigate(`/warehouses/${warehouse.id}`);

    return (
        <div>
            <Card>
                <CardHeader title="Warehouse table">sdf</CardHeader>
                <CardContent className="p-0">
                    <WarehouseTable warehouses={warehouses || []} onEditClick={handleEditClick} />
                </CardContent>
            </Card>
        </div>
    );
};
