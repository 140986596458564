import { config } from "@/app";
import MediaLibraryCollection from "@/components/custom/media-library-pro-react-collection/MediaLibraryCollection";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { Alert02Icon, InformationCircleIcon, Tick02Icon } from "@hugeicons/react";
import { HoverCardArrow } from "@radix-ui/react-hover-card";
import { differenceInDays, formatDistance } from "date-fns";
import { Fragment, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { useFieldArray, useForm } from "react-hook-form";
import { PurchaseOrderStatus } from "../constans/PurchaseOrderStatus";
import { useGetPurchaseOrder, useSetPurchaseOrderStatusCompleted, useUpdatePurchaseOrder } from "../hooks";
import { IPurchaseOrder } from "../types";

interface IPurchaseOrderUpdateSheetProps {
    purchaseOrderId: number;
    open: boolean;
    onClose: () => void;
}

export const PurchaseOrderUpdateSheet = ({ open, onClose, purchaseOrderId }: IPurchaseOrderUpdateSheetProps) => {
    const { data: purchaseOrder } = useGetPurchaseOrder(purchaseOrderId, {
        enabled: !!purchaseOrderId
    });
    const { mutate: updatePurchaseOrder } = useUpdatePurchaseOrder(purchaseOrderId);
    const { mutate: setPurchaseOrderStatusCompleted } = useSetPurchaseOrderStatusCompleted(purchaseOrderId);
    const { control, ...form } = useForm<IPurchaseOrder>({
        defaultValues: purchaseOrder
    });
    const { fields } = useFieldArray({
        control,
        name: "productPurchaseOrder"
    });

    const totalOrderValue =
        purchaseOrder?.productPurchaseOrder.reduce(
            (acc, curr) => acc + (curr.adjusted_qty || curr.qty) * curr.price,
            0
        ) || 0;

    const openDays = purchaseOrder?.created_at ? differenceInDays(new Date(), new Date(purchaseOrder.created_at)) : 0;

    useEffect(() => {
        form.reset(purchaseOrder);
    }, [purchaseOrder]);

    const handleOnSubmit = (data: IPurchaseOrder) => {
        updatePurchaseOrder(data);
    };

    return (
        <Sheet open={open} onOpenChange={() => onClose()}>
            <SheetContent className={cn("sm:max-w-5xl p-0 overflow- ")}>
                <Flex
                    alignItems="start"
                    justifyContent="between"
                    flexDirection="col"
                    className="w-full h-full bg-white p-0"
                >
                    <SheetHeader className="px-6 py-3">
                        <SheetTitle>Purchase order #{purchaseOrder?.id}</SheetTitle>
                    </SheetHeader>
                    {/* <SupplierForm onSubmit={handleAddSupplier} /> */}

                    <div className="grow overflow-scroll h-auto w-full px-6 py-8 ">
                        <div className="mx-8 mt-10">{Timeline(purchaseOrder?.status || PurchaseOrderStatus.Open)}</div>

                        {openDays > 5 && (
                            <Alert
                                variant="destructive"
                                className={cn(
                                    "bg-primary-foreground border-none transition-all ease-in-out duration-300 mb-8"
                                )}
                            >
                                <Flex justifyContent="start" alignItems="center" className="gap-x-2.5">
                                    <Alert02Icon className="h-4 w-4 animate-caret-blink" />
                                    <AlertDescription>{`This purchase order is still open after ${openDays} day(s)`}</AlertDescription>
                                </Flex>
                            </Alert>
                        )}

                        <Card>
                            <CardContent className="p-0 grid grid-cols-3 divide-y">
                                <Flex className="col-span-3 py-3 px-4 bg-muted/50" justifyContent="between">
                                    <TypographyH3 className="text-sm">
                                        <strong className="text-foreground bg-muted mr-6">
                                            PO #{purchaseOrder?.id}
                                        </strong>
                                        {purchaseOrder?.notice}
                                    </TypographyH3>
                                    <Badge
                                        className={cn(
                                            "rounded-xl font-light shadow-none",
                                            purchaseOrder?.status === PurchaseOrderStatus.Open && "bg-gray-400",
                                            purchaseOrder?.status === PurchaseOrderStatus.Pending && "bg-orange-400",
                                            purchaseOrder?.status === PurchaseOrderStatus.Completed && "bg-green-500"
                                        )}
                                    >
                                        {purchaseOrder?.status}
                                    </Badge>
                                </Flex>
                                <div className="col-span-3 grid grid-cols-3 divide-x">
                                    <div className="col-span-1 p-3 gap-y-3">
                                        <TypographyH3 className="text-sm font-light">Created at</TypographyH3>
                                        <span className="text-sm font-medium">
                                            {purchaseOrder?.created_at &&
                                                Intl.DateTimeFormat("nl-NL").format(new Date(purchaseOrder.created_at))}
                                        </span>
                                    </div>
                                    <div className="col-span-1 p-3 gap-y-3">
                                        <TypographyH3 className="text-sm font-light">Total days open</TypographyH3>
                                        <span className="text-sm font-medium">
                                            {purchaseOrder?.created_at &&
                                                formatDistance(new Date(purchaseOrder.created_at), new Date(), {
                                                    addSuffix: true
                                                })}
                                        </span>
                                    </div>
                                    <div className="col-span-1 p-3 gap-y-3">
                                        <TypographyH3 className="text-sm font-light">Created at</TypographyH3>
                                        <span className="text-sm font-medium">
                                            {purchaseOrder?.created_at &&
                                                formatDistance(new Date(purchaseOrder.created_at), new Date(), {
                                                    addSuffix: true
                                                })}
                                        </span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        {purchaseOrder && (
                            <MediaLibraryCollection
                                name="attachments"
                                form={form}
                                initialValue={purchaseOrder?.attachments || []}
                                disabledRemove={true}
                            />
                        )}

                        <Separator className="my-10" />
                        <div>
                            {purchaseOrder && (
                                <Flex className="grid grid-cols-3 justify-start items-start">
                                    <TypographyH3 className="mb-4 text-md col-span-3">Supplier details</TypographyH3>
                                    <div>
                                        <div className="text-muted-foreground text-xs mb-1">First name</div>
                                        <div className="text-card-foreground font-normal text-sm mb-4">
                                            {purchaseOrder.supplier?.first_name}
                                        </div>
                                        <div className="text-muted-foreground text-xs mb-1">E-mail</div>
                                        <div className="text-card-foreground font-normal text-sm mb-4">
                                            {purchaseOrder.supplier?.surname}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-muted-foreground text-xs mb-1">Address</div>
                                        <div className="text-card-foreground font-normal text-sm mb-4">
                                            <div className="leading-6">
                                                {purchaseOrder.supplier?.fullStreetDetails}
                                                <br />
                                                {`${purchaseOrder.supplier?.zip_code} ${purchaseOrder.supplier?.city}`}{" "}
                                                <br />
                                                <Flex justifyContent="start" alignItems="center" className="gap-x-2">
                                                    <img
                                                        src={`${config.appUrl}/assets/img/flags/${purchaseOrder.supplier?.country.iso_3166_2}.svg`}
                                                        alt=""
                                                        className="w-3 h-3 object-cover"
                                                    />
                                                    {purchaseOrder.supplier?.country.name}
                                                </Flex>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-muted-foreground text-xs mb-1">Phonenumber</div>
                                        <div className="text-card-foreground font-normal text-sm mb-4">
                                            {purchaseOrder.supplier?.phone_number}
                                        </div>
                                        <div className="text-muted-foreground text-xs mb-1">E-mail</div>
                                        <div className="text-card-foreground font-normal text-sm mb-4">
                                            {purchaseOrder.supplier?.email}
                                        </div>
                                    </div>
                                </Flex>
                            )}

                            <Separator className="my-10" />
                            {purchaseOrder?.extra_notice}
                            <Textarea />
                            <Separator className="my-10" />

                            <Form {...form} control={control}>
                                <form onSubmit={form.handleSubmit(handleOnSubmit)} id="purchase-order-form">
                                    <div className="border rounded-xl my-2">
                                        <Table>
                                            <TableHeader className="bg-muted/50">
                                                <TableRow>
                                                    <TableHead className="text-sm">Name</TableHead>
                                                    <TableHead className="text-sm text-right">Total price</TableHead>
                                                    <TableHead className="text-sm text-center">Qty ordered</TableHead>
                                                    <TableHead className="text-sm text-center">Qty received</TableHead>
                                                    <TableHead className="text-sm text-right">Total</TableHead>
                                                    <TableHead className="text-sm hidden">Qty adjusteed</TableHead>
                                                    <TableHead className="text-sm text-right">
                                                        Qty receivedddd
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {fields.map((product, index) => {
                                                    const totalValue =
                                                        product.histories.reduce(
                                                            (acc, curr) =>
                                                                acc + product.adjusted_qty ||
                                                                product.qty * product.price,
                                                            0
                                                        ) / 100;
                                                    return (
                                                        <TableRow key={product.id}>
                                                            <TableCell>
                                                                {product.histories.length > 0 ? (
                                                                    <HoverCard>
                                                                        <HoverCardTrigger>
                                                                            <p className="text-muted-foreground text-sm flex items-center gap-x-1 cursor-pointer">
                                                                                {product.product.name}
                                                                                <InformationCircleIcon className="w-4 h-4" />
                                                                            </p>
                                                                        </HoverCardTrigger>

                                                                        <HoverCardContent className="z-[9999999]">
                                                                            <Flex
                                                                                flexDirection="col"
                                                                                justifyContent="start"
                                                                                alignItems="start"
                                                                            >
                                                                                <TypographyH3 className="mb-2">
                                                                                    History
                                                                                </TypographyH3>
                                                                                {product.histories.map(history => (
                                                                                    <Fragment key={history.id}>
                                                                                        <Separator className="my-1 " />
                                                                                        <p>
                                                                                            Qty: {history.qty} Date:{" "}
                                                                                            {Intl.DateTimeFormat(
                                                                                                "nl-NL"
                                                                                            ).format(
                                                                                                new Date(
                                                                                                    history.created_at
                                                                                                )
                                                                                            )}
                                                                                        </p>
                                                                                    </Fragment>
                                                                                ))}
                                                                            </Flex>
                                                                            <HoverCardArrow className="fill-white" />
                                                                        </HoverCardContent>
                                                                    </HoverCard>
                                                                ) : (
                                                                    <p className="text-muted-foreground text-sm">
                                                                        {product.product.name}
                                                                    </p>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className="text-right text-muted-foreground">
                                                                <CurrencyFormat
                                                                    value={parseFloat((product.price / 100).toString())}
                                                                    thousandSeparator={true}
                                                                    displayType="text"
                                                                    // suffix="-"
                                                                    prefix="€"
                                                                    fixedDecimalScale={true}
                                                                    className="px-4"
                                                                />
                                                            </TableCell>
                                                            <TableCell className="text-center text-muted-foreground">
                                                                {product.qty}
                                                            </TableCell>
                                                            <TableCell className="text-center text-muted-foreground">
                                                                {product.histories.reduce(
                                                                    (acc, curr) => acc + curr.qty,
                                                                    0
                                                                )}
                                                            </TableCell>
                                                            <TableCell className="text-right text-muted-foreground">
                                                                <CurrencyFormat
                                                                    value={parseFloat(
                                                                        (
                                                                            (product.price *
                                                                                (product.adjusted_qty || product.qty)) /
                                                                            100
                                                                        ).toString()
                                                                    )}
                                                                    thousandSeparator={true}
                                                                    displayType="text"
                                                                    // suffix="-"
                                                                    prefix="€"
                                                                    fixedDecimalScale={true}
                                                                />
                                                            </TableCell>
                                                            <TableCell className="text-right hidden">
                                                                <FormField
                                                                    control={control}
                                                                    name={`productPurchaseOrder.${index}.adjusted_qty`}
                                                                    // rules={{
                                                                    //     validate: value =>
                                                                    //         value <= product.received_qty ||
                                                                    //         `Value cannot be higher ${product.adjusted_qty}`
                                                                    // }}
                                                                    render={({ field, fieldState }) => (
                                                                        <FormItem className="text-center">
                                                                            <FormControl>
                                                                                <div className="text-right flex justify-end">
                                                                                    <Input
                                                                                        className="w-16"
                                                                                        placeholder="10"
                                                                                        type="number"
                                                                                        {...field}
                                                                                    />
                                                                                </div>
                                                                            </FormControl>
                                                                            <FormMessage />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell className="text-right">
                                                                <FormField
                                                                    control={control}
                                                                    name={`productPurchaseOrder.${index}.received_qty`}
                                                                    rules={{
                                                                        validate: value =>
                                                                            value <= product.received_qty ||
                                                                            `Value cannot be higher ${product.received_qty}`
                                                                    }}
                                                                    render={({ field, fieldState }) => (
                                                                        <FormItem className="text-right">
                                                                            <FormControl>
                                                                                <div className="text-right flex justify-end">
                                                                                    <Input
                                                                                        className="w-16"
                                                                                        placeholder="10"
                                                                                        type="number"
                                                                                        {...field}
                                                                                    />
                                                                                </div>
                                                                            </FormControl>
                                                                            <FormMessage />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </form>
                            </Form>
                        </div>
                    </div>
                    <SheetFooter
                        className="justify-between items-center flex w-full m-0 py-3 px-6 z-50 gap-x-2"
                        style={{
                            boxShadow:
                                "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                        }}
                    >
                        <Flex>
                            <div className="flex items-center gap-x-2 w-64">
                                Total order value:
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    // suffix="-"
                                    prefix="€"
                                    fixedDecimalScale={true}
                                    value={totalOrderValue / 100}
                                />
                            </div>
                            <div>
                                <Button onClick={() => onClose()} variant="ghost">
                                    Close
                                </Button>
                                {purchaseOrder?.is_purchase_order_fulfilled &&
                                    purchaseOrder.status !== PurchaseOrderStatus.Completed && (
                                        <Button onClick={() => setPurchaseOrderStatusCompleted()}>
                                            Mark as completed
                                        </Button>
                                    )}

                                {!purchaseOrder?.is_purchase_order_fulfilled && (
                                    <Button form="purchase-order-form" type="submit">
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Flex>
                    </SheetFooter>
                </Flex>
            </SheetContent>
        </Sheet>
    );
};

const Timeline = (status: PurchaseOrderStatus) => {
    let percent = 100;

    if (status === PurchaseOrderStatus.Open) {
        percent = 25;
    } else if (status === PurchaseOrderStatus.Pending) {
        percent = 75;
    }
    return (
        <div className="h-auto w-full mb-16">
            <div className="relative">
                <Flex justifyContent="between" alignItems="center">
                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className="bg-green-500 w-5 h-5 rounded-full"
                            >
                                <Tick02Icon variant="solid" className="text-white w-4" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Open</p>
                        </div>
                    </div>
                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className="bg-slate-100 w-7 h-7 rounded-full z-0"
                            >
                                <div className="w-4 rounded-full h-4 bg-slate-400 z-10" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Pending</p>
                        </div>
                    </div>

                    <div className="z-20 relative">
                        <Flex alignItems="center" justifyContent="center">
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                className="bg-slate-100 w-7 h-7 rounded-full z-0"
                            >
                                <div className="w-4 rounded-full h-4 bg-slate-400 z-10" />
                            </Flex>
                        </Flex>
                        <div className="absolute -bottom-8 text-center left-1/2 -translate-x-1/2 w-auto">
                            <p className="text-foreground text-sm whitespace-nowrap mt-1 font-normal">Completed</p>
                        </div>
                    </div>
                </Flex>

                <div
                    className={`border-b border border-emerald-500 z-10 absolute top-1/2 w-[${percent}%] -translate-y-1/2`}
                    style={{ width: `${percent}%` }}
                />
                <div className="border-b border border-slate-200 border-dashed bg-white z-0 absolute top-1/2 -translate-y-1/2 w-full " />
            </div>
        </div>
    );
};
