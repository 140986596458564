import { cn } from "@/lib/utils";

interface ITypographyPProps extends React.HTMLAttributes<HTMLParagraphElement> {}

export function TypographyP({ className, children, ...restProps }: ITypographyPProps) {
    return (
        <p {...restProps} className={cn("leading-7 text-sm font-light text-foreground my-6", className)}>
            {children}
        </p>
    );
}
