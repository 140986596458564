import { Badge } from "@/components/ui/badge";
import { OrderStatus } from "../constans/OrderStatus";

interface OrderStatusBadgeProps {
    status: OrderStatus;
}

export const OrderStatusBadge = ({ status }: OrderStatusBadgeProps) => {
    return <Badge variant={status === 1 ? "default" : "secondary"}>{OrderStatus[status]}</Badge>;
};
