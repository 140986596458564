import { cn } from "@/lib/utils";

interface ITypographyH1Props extends React.HTMLAttributes<HTMLHeadingElement> {}

export function TypographyH1({ className, children, ...restProps }: ITypographyH1Props) {
    return (
        <h1 {...restProps} className={cn("scroll-m-20 text-3xl font-extrabold tracking-tight", className)}>
            {children}
        </h1>
    );
}
