import { useNavigate } from "react-router-dom";
import { useGetChannels } from "../hooks";
import { IChannel } from "../types";
import { useAuth } from "@/auth/hooks";
import { ChannelList } from "../components/ChannelList";

export const ChannelOverviewPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { data: channels = [] } = useGetChannels(auth.activeDomain);
    const handleEditClick = (channel: IChannel) => navigate(`/connections/${channel.id}`);

    return (
        <div>
            <ChannelList onEditClick={handleEditClick} channels={channels} />
        </div>
    );
};
