import { queryClient } from "@/app/services/queryClient";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKeys";
import { createUser, deleteUser, getDomainUsers, getUser, getUserDomains, updateUser } from "./queries";
import { IUser, IUserRespone } from "./types";

export const useGetDomainUsers = (domainId: number, options: commonTypes.IUseGetOptions) =>
    useQuery<IUserRespone, Error>([QueryKeys.UserItems, domainId], () => getDomainUsers(domainId), options);

export const useGetUserDomains = (userId: number) =>
    useQuery<domainTypes.IDomain[], Error>([QueryKeys.UserDomainItems, userId], () => getUserDomains(userId));

export const useUpdateDomainOrderStatus = (userId: number, domainId: number, newOrderCount: number) => {
    queryClient.setQueryData([QueryKeys.UserDomainItems, userId], (oldDomains: any) => {
        const replacementIndex = oldDomains.findIndex((domain: domainTypes.IDomain) => domain.id === domainId);
        oldDomains[replacementIndex].new_order_count = newOrderCount;

        return oldDomains;
    });
};

export const useGetUser = (userId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IUser, Error>([QueryKeys.UserItem, userId], () => getUser(userId), options);

export const useCreateUser = () =>
    useMutation(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.UserItems);
        }
    });

export const useUpdateUser = () =>
    useMutation(updateUser, {
        onSuccess: (updatedItem: IUser, postedItem) => {
            queryClient.invalidateQueries([QueryKeys.UserItem, postedItem.id]);
        }
    });

export const useDeleteUser = () => useMutation(deleteUser);
