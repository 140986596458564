import { cn } from "@/lib/utils";

interface ITypographyH3Props extends React.HTMLAttributes<HTMLHeadingElement> {}

export function TypographyH3({
    className,
    children,
    ...restProps
}: ITypographyH3Props) {
    return (
        <h3
            {...restProps}
            className={cn(
                "scroll-m-20 text-lg font-normal tracking-tight text-muted-foreground",
                className
            )}
        >
            {children}
        </h3>
    );
}
