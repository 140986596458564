import { commonTypes } from "@/common";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "./contants/QueryKeys";
import { getDomainWarehouses, getWarehouse, updateWarehouse } from "./queries";
import { IWarehouse } from "./types";

export const useGetDomainWarehouses = (domainId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IWarehouse[], Error>(
        [QueryKeys.WarehouseItems, { domainId }],
        () => getDomainWarehouses(domainId),
        options
    );

export const useGetWarehouse = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IWarehouse, Error>("abc", () => getWarehouse(id), options);

export const useUpdateWarehouse = () => useMutation(updateWarehouse);
