import { authHooks } from "@/auth";
import { carrierHooks, carrierTypes } from "@/carrier";
import { CrudActions } from "@/common/constants/CrudActions";
import { CrudStates } from "@/common/constants/CrudStates";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IChannel } from "../types";

interface IChannelFormProps {
    onSubmit: SubmitHandler<IChannel>;
    // mailTemplates: IMailTemplate[];
    onCancel: () => void;
    item: IChannel | undefined;
    crudState?: CrudStates;
    crudAction: CrudActions;
}
export const ChannelForm = ({ onSubmit, item }: IChannelFormProps) => {
    const { activeDomain } = authHooks.useAuth();
    const { data: carriers } = carrierHooks.useGetDomainCarriers(activeDomain.id, 1);
    console.log("item", item);
    const form = useForm<IChannel>({
        defaultValues: item
    });
    const currentKeyValue = form.watch("key");
    // const templateOptions = [{ name: "No mail template selected", id: null }, ...mailTemplates];
    const keyOptions = [
        { label: "Magento", value: "magento" },
        { label: "Bol", value: "bol" },
        { label: "Blokker", value: "blokker" },
        { label: "Shopify", value: "shopify" },
        { label: "Channable", value: "channable" }
    ];

    const UrbantzReference = [
        { label: "Televisie", value: "Televisie" },
        { label: "Witgoed", value: "Witgoed" }
    ];

    const financialStatus = [{ label: "Paid", value: "paid" }];

    const fulfillmentStatus = [{ label: "Unshipped", value: "unshipped" }];

    const defaultDeliveryRange = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24
    ].map(index => ({
        label: index,
        value: index
    }));

    useEffect(() => {
        form.reset(item);
    }, [item]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                        <FormItem className="col-span-1">
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                                <Switch checked={!!field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="col-span-4 grid grid-col-2">
                    {/* <ControlledFormField
                        numColSpan={1}
                        control={form.control}
                        name="key"
                        component={SelectField}
                        defaultValue={item?.key}
                        getOptionLabel={(option: any) => option?.label}
                        getOptionValue={(option: any) => option?.value}
                        options={keyOptions}
                        isDisabled={crudAction !== CrudActions.Create}
                        valueFormatter={(optionValue: any) => optionValue?.value}
                        valueParser={(selectedKeyValue: any) =>
                            keyOptions.find(optionKey => selectedKeyValue === optionKey.value)
                        }
                        label="Key"
                    /> */}
                    {currentKeyValue === "shopify" && (
                        <div className="border rounded-md border-red-300 p-4 my-10 gap-y-2 flex flex-col">
                            <div>
                                <h1 className="text-gray-800 text font-medium">Shopify credentials</h1>
                                <p className="text-gray-600 text-sm mb-2">
                                    Below you can change the Shopify credentials, please notice that incorrect
                                    credentials can stop the channel from working correctly.
                                </p>
                            </div>
                            <FormField
                                control={form.control}
                                name="credentials.apiKey"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Shopify API key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="credentials.domain"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Domain</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="credentials.password"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Password</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    )}
                    {currentKeyValue === "bol" && (
                        <div className="border rounded-md border-red-300 p-4 my-10 gap-y-2 flex flex-col">
                            <div>
                                <h1 className="text-gray-800 text font-medium">API KEYS</h1>
                                <p className="text-gray-600 text-sm mb-2">
                                    Below you can change the API keys, please notice that incorrect keys can stop the
                                    channel from working correctly.
                                </p>
                            </div>
                            <FormField
                                control={form.control}
                                name="credentials.client_id"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="credentials.secret"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    )}

                    {currentKeyValue === "channable" && (
                        <div className="border rounded-md border-red-300 p-4 my-10 gap-y-2 flex flex-col">
                            <div>
                                <h1 className="text-gray-800 text font-medium">Channable API KEYS</h1>
                                <p className="text-gray-600 text-sm mb-2">
                                    Below you can change the API keys, please notice that incorrect keys can stop the
                                    channel from working correctly.
                                </p>
                            </div>

                            <FormField
                                control={form.control}
                                name="credentials.api_key"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Channable API key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="credentials.company_id"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Company ID</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="credentials.project_id"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Project ID</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    )}

                    <div className="border rounded-md border-red-300 p-4 my-10 gap-y-2 flex flex-col divide-y">
                        <div className="py-4">
                            <h1 className="text-gray-800 text font-medium">Default delivery hours</h1>
                            <p className="text-sm mb-4 text-slate-500">At the moment only relevant for Bumbal orders</p>

                            {/* <FormField
                                    control={form.control}
                                    control={form.control}
                                    name="default_delivery_range.from"
                                    component={SelectField}
                                    label="Default delivery hour from:"
                                    options={defaultDeliveryRange}
                                    valueFormatter={(hour: any) => hour.value}
                                    valueParser={(selectedKeyValue: any) =>
                                        defaultDeliveryRange.find(range => range.value === selectedKeyValue)
                                    }
                                />
                                <ControlledFormField
                                    numColSpan={1}
                                    control={form.control}
                                    name="default_delivery_range.to"
                                    component={SelectField}
                                    label="Default delivery hour to:"
                                    options={defaultDeliveryRange}
                                    valueFormatter={(hour: any) => hour.value}
                                    valueParser={(selectedKeyValue: any) =>
                                        defaultDeliveryRange.find(range => range.value === selectedKeyValue)
                                    }
                                /> */}
                        </div>

                        <div className="py-4">
                            <h1 className="text-gray-800 text font-medium mb-4">PostNL credentials</h1>

                            <FormField
                                control={form.control}
                                name="postnl_credentials.apiKey"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL API key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.CustomerCode"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL customer code</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.GlobalpackCustomerCode"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL globalpack customer code</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.CustomerNumber"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL customer number</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.SenderReference"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL sender reference</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.ReturnReference"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL return reference</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.ProductCodeDeliveryNL"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL product code delivery NL</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.ProductCodeDeliveryBE"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL product code delivery BE</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.ProductCodeDeliveryDE"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL product code delivery DE</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.ProductOptions"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL product options</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <div className="my-2" />
                            <FormField
                                control={form.control}
                                name="postnl_credentials.FirstName"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL first name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.Name"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL last name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.CompanyName"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL company name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.Street"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL street</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.HouseNr"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL house number</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.HouseNrExt"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL house number extension</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.Zipcode"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL zipcode</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="postnl_credentials.City"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>PostNL city</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="py-4">
                            <h1 className="text-gray-800 text font-medium mb-4">Urbantz credentials</h1>

                            <FormField
                                control={form.control}
                                name="urbantz_credentials.platform_key"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Urbantz platform key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="urbantz_credentials.flow_key"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Urbantz flow key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            {/* <ControlledFormField
                                    numColSpan={1}
                                    control={form.control}
                                    name="urbantz_credentials.reference"
                                    component={SelectField}
                                    getOptionLabel={(option: any) => option?.label}
                                    getOptionValue={(option: any) => option?.value}
                                    options={UrbantzReference}
                                    valueFormatter={(optionValue: any) => optionValue?.value}
                                    valueParser={(selectedKeyValue: any) =>
                                        UrbantzReference.find(optionKey => selectedKeyValue === optionKey.value)
                                    }
                                    label="Urbantz reference"
                                /> */}
                        </div>
                        {/* <div className="py-4">
                            <h1 className="text-gray-800 text font-medium mb-4">Sendcloud credentials</h1>
                            <FormField
                                control={form.control}
                                name="sendcloud_credentials.public_key"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Sendcloud public key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="sendcloud_credentials.private_key"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Sendcloud private key</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="sendcloud_credentials.sender_address_id"
                                render={({ field }) => (
                                    <FormItem className="col-span-1">
                                        <FormLabel>Sendcloud sender address ID</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div> */}
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                    <FormField
                        control={form.control}
                        name="company_name"
                        render={({ field }) => (
                            <FormItem className="col-span-2">
                                <FormLabel>Company name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="street"
                        render={({ field }) => (
                            <FormItem className="col-span-3">
                                <FormLabel>Street</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="house_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>House number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="zip_code"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Zip code</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>City</FormLabel>
                                <FormControl>
                                    <Input placeholder="Groningen" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="phone_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Phone number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="vat_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>VAT number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="chamber_of_commerce_number"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Chamber of commerce number</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="mail_template_sender_name"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Mail template sender name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="mail_template_sender_email"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Mail template sender email</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    {/*
                        <ControlledFormField
                            numColSpan={2}
                            control={form.control}
                            name="shipping_mail_template_id"
                            component={SelectField}
                            defaultValue={item?.shipping_mail_template_id}
                            getOptionLabel={(option: any) => option?.name}
                            getOptionValue={(option: any) => option?.id}
                            options={templateOptions}
                            valueFormatter={(optionValue: any) => optionValue?.id}
                            valueParser={(templateId: any) =>
                                mailTemplates.find(mailTemplate => mailTemplate.id === templateId)
                            }
                            label="Shipping info mail template"
                        />

                        <ControlledFormField
                            numColSpan={2}
                            control={form.control}
                            name="invoice_mail_template_id"
                            component={SelectField}
                            defaultValue={item?.shipping_mail_template_id}
                            getOptionLabel={(option: any) => option?.name}
                            getOptionValue={(option: any) => option?.id}
                            options={templateOptions}
                            valueFormatter={(optionValue: any) => optionValue?.id}
                            valueParser={(templateId: any) =>
                                mailTemplates.find(mailTemplate => mailTemplate.id === templateId)
                            }
                            label="Invoice mail template"
                        /> */}
                    <div className="border-b col-span-4 my-4" />
                    {(carriers?.data || []).map((carrier: carrierTypes.ICarrier) => (
                        <FormField
                            control={form.control}
                            name={`bol_transport_code_overwrites.${carrier.key}`}
                            render={({ field }) => (
                                <FormItem className="col-span-1">
                                    <FormLabel>{`Bol carrier overwrites ${carrier.name}`}</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    ))}
                    <div className="border-b col-span-4 my-4" />
                    <FormField
                        control={form.control}
                        name="bumbal_assignment_id"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>bumbal_assignment_id</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="mt-8 flex flex-row-reverse">
                    <Button type="submit" disabled={!form.formState.isDirty}>
                        Save changes
                    </Button>
                </div>
            </form>
        </Form>
    );
};
