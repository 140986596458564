import Flex from "@/components/ui/flex";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";

import { authHooks } from "@/auth";
import { channelHooks } from "@/channel";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetFooter } from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { EanInputForm } from "@/order/components/EanInputForm";
import { useGetProductOrders } from "@/order/hooks";
import {
    AppleStocksIcon,
    DrawingModeIcon,
    Image02Icon,
    InformationCircleIcon,
    Invoice01Icon,
    Pdf02Icon
} from "@hugeicons/react";
import { TabsTrigger } from "@radix-ui/react-tabs";
import { Fragment, useEffect, useState } from "react";
import { ChannelProductDialogForm } from "../components/ChannelProductDialogForm";
import { EanForm } from "../components/EanForm";
import { ProductAiLoadingForm } from "../components/ProductAiLoadingForm";
import { ProductDashboard } from "../components/ProductDashboard";
import { ProductFeatureForm } from "../components/ProductFeatureForm";
import { ProductForm } from "../components/ProductForm";
import { ProductMediaForm } from "../components/ProductMediaForm";
import { ProductOrders } from "../components/ProductOrders";
import { WarehouseStockForm } from "../components/WarehouseStockForm";
import {
    useCreateChannelProductAiText,
    useGetProduct,
    useImportProductIcecat,
    useRegenerateChannelProductAiText,
    useUpdateChannelProduct,
    useUpdateProduct,
    useUpdateProductWarehouses
} from "../hooks";
import { IChannelProduct, IProductWarehouse } from "../types";

interface IProductSheetProps {
    productId?: number | null;
    open: boolean;
    handleClose: () => void;
}

export const ProductSheet = ({ open, productId: externalProductId, handleClose }: IProductSheetProps) => {
    const [isExternalProduct, setIsExternalProduct] = useState<boolean>(false);
    const [channelProduct, setChannelProduct] = useState<IChannelProduct | undefined>(undefined);
    const [showChannelProductDialogForm, setShowChannelProductDialogForm] = useState<boolean>(false);
    const [productId, setProductId] = useState<number | null>(null);
    const { activeDomain } = authHooks.useAuth();
    const { data: product, isLoading } = useGetProduct(productId || 0, { enabled: !!productId });
    const { data: orders = [], isLoading: isOrdersLoading } = useGetProductOrders(productId || 0, {
        enabled: !!productId
    });
    const { mutateAsync: updateProduct } = useUpdateProduct();
    const { mutateAsync: updateChannelProduct } = useUpdateChannelProduct();
    const { mutateAsync: importProductIcecat, isLoading: isImportProductIcecatLoading } = useImportProductIcecat();
    const {
        mutateAsync: regenrateChannelProductAiText,
        isLoading: isLoadingRegenrateChannelProductAiText
    } = useRegenerateChannelProductAiText();
    const {
        mutateAsync: createChannelProductAiText,
        isLoading: isCreateChannelProductAiTextLoading
    } = useCreateChannelProductAiText(productId);
    const { mutateAsync: updateProductWarehouses } = useUpdateProductWarehouses(productId || 0);
    const { data: channels = [] } = channelHooks.useGetChannels(activeDomain);

    useEffect(() => {
        setIsExternalProduct(!!externalProductId);

        if (externalProductId) {
            setProductId(externalProductId);
        }
    }, [externalProductId]);

    const handleCreateChannelProductAiText = async (channelId: number) => {
        if (productId && channelId) {
            createChannelProductAiText(channelId).then(res => {
                setChannelProduct(res);
            });
        }
    };

    const handleRegenerateChannelProductAiText = (channelProduct: IChannelProduct) => {
        regenrateChannelProductAiText(channelProduct);
    };

    const handleSuccesFullEanSubmit = (productId: number) => {
        setProductId(productId);
        setIsExternalProduct(true);
    };

    const handleWarehouseStockSubmit = (productWarehouses: IProductWarehouse[], reason: string) => {
        updateProductWarehouses({
            productWarehouses,
            reason
        });
    };

    const handleImportProductIcecat = (productId: number) => {
        importProductIcecat(productId);
    };

    const handleChannelProductUpdateSubmit = (channelProduct: IChannelProduct) => {
        setShowChannelProductDialogForm(false);
        updateChannelProduct(channelProduct);
    };

    return (
        <Fragment>
            <Sheet
                open={open}
                onOpenChange={() => {
                    handleClose();
                }}
            >
                <SheetContent className="sm:max-w-3xl p-0">
                    <TooltipProvider>
                        <div className="h-full bg-muted">
                            {!isExternalProduct && (
                                <Flex className="h-full" alignItems="center" justifyContent="center">
                                    <EanInputForm onSuccess={handleSuccesFullEanSubmit} />
                                </Flex>
                            )}
                            {isLoading && (
                                <Flex className="h-full" alignItems="start">
                                    <Skeleton className="w-16 h-full bg-slate-200 p-1" />

                                    <div className="w-full mx-8 my-16">
                                        <ProductAiLoadingForm />
                                    </div>
                                </Flex>
                            )}

                            {isExternalProduct && (
                                <Tabs defaultValue="dashboard" className="h-full" orientation="vertical">
                                    <Flex alignItems="start" className="h-full">
                                        <div
                                            className={cn(
                                                "h-full transition-all duration-300 transform",
                                                !!product && "w-16 p-1",
                                                !product && "w-0 p-0 overflow-hidden"
                                            )}
                                        >
                                            <TabsList className="h-full flex flex-col justify-start py-4 gap-y-4">
                                                <TabsTrigger
                                                    value="dashboard"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <InformationCircleIcon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="features"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <DrawingModeIcon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="images"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Image02Icon className="w-5 h-5" />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger value="pdf" className="data-[state=active]:text-primary">
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Pdf02Icon />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger value="stock" className="data-[state=active]:text-primary">
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <AppleStocksIcon />
                                                    </Flex>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value="orders"
                                                    className="data-[state=active]:text-primary"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="bg-white w-10 h-10 rounded-full"
                                                    >
                                                        <Invoice01Icon />
                                                    </Flex>
                                                </TabsTrigger>
                                            </TabsList>
                                        </div>

                                        <Flex
                                            flexDirection="col"
                                            justifyContent="between"
                                            alignItems="start"
                                            className="h-full w-full bg-white"
                                        >
                                            <Flex
                                                className="h-20 px-6"
                                                justifyContent="center"
                                                flexDirection="col"
                                                alignItems="start"
                                            >
                                                <h3>{product?.name}</h3>
                                                <p className="text-xs text-muted-foreground">
                                                    {product?.eans.map(eanObject => eanObject.ean).join(",")}
                                                </p>
                                            </Flex>

                                            <div className="grow h-full w-full overflow-scroll px-6">
                                                <TabsContent value="dashboard" className="w-full">
                                                    {product && (
                                                        <ProductDashboard
                                                            product={product}
                                                            orders={orders}
                                                            channels={channels}
                                                        />
                                                    )}
                                                </TabsContent>
                                                <TabsContent value="features" className="w-full">
                                                    <ScrollArea>
                                                        {product && !isImportProductIcecatLoading && (
                                                            <Fragment>
                                                                <Button
                                                                    variant="outline"
                                                                    disabled
                                                                    onClick={() =>
                                                                        handleImportProductIcecat(product.id)
                                                                    }
                                                                >
                                                                    Don't push in production Refetch Icecat data{" "}
                                                                    {product.external_icecat_id || "No external ID"}
                                                                </Button>
                                                                <ProductForm
                                                                    item={product}
                                                                    onSubmit={values => updateProduct(values)}
                                                                />
                                                                <div>
                                                                    <EanForm product={product} />
                                                                </div>

                                                                <div>
                                                                    <TypographyH3 className="hidden">
                                                                        Prodduct families
                                                                    </TypographyH3>
                                                                </div>

                                                                <ProductFeatureForm
                                                                    product={product}
                                                                    onSubmit={() => console.log("sfd")}
                                                                />
                                                            </Fragment>
                                                        )}
                                                    </ScrollArea>
                                                </TabsContent>

                                                <TabsContent value="images" className="w-full h-full">
                                                    <Flex justifyContent="center" className="h-full w-full">
                                                        {product && <ProductMediaForm product={product} />}
                                                    </Flex>
                                                </TabsContent>
                                                <TabsContent value="stock" className="w-full h-full">
                                                    <ScrollArea>
                                                        {product && (
                                                            <WarehouseStockForm
                                                                product={product}
                                                                onSubmit={values =>
                                                                    handleWarehouseStockSubmit(
                                                                        values.productWarehouses || [],
                                                                        values.reason
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </ScrollArea>
                                                </TabsContent>
                                                <TabsContent value="orders" className="w-full h-full">
                                                    {product && <ProductOrders product={product} />}
                                                </TabsContent>
                                            </div>
                                            <SheetFooter
                                                className="justify-end flex w-full m-0 py-3 px-6 z-50 flex-none"
                                                style={{
                                                    boxShadow:
                                                        "0px -2px 4px 0px rgba(101, 101, 101, 0.01), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)"
                                                }}
                                            >
                                                <Button onClick={handleClose} variant="outline">
                                                    Close
                                                </Button>
                                            </SheetFooter>
                                        </Flex>
                                    </Flex>
                                </Tabs>
                            )}
                        </div>
                    </TooltipProvider>
                </SheetContent>
            </Sheet>
            {product && (
                <ChannelProductDialogForm
                    channelProduct={channelProduct}
                    isLoading={isCreateChannelProductAiTextLoading || isLoadingRegenrateChannelProductAiText}
                    product={product}
                    open={showChannelProductDialogForm}
                    onRegenerate={handleRegenerateChannelProductAiText}
                    onClose={() => setShowChannelProductDialogForm(false)}
                    onSubmit={handleChannelProductUpdateSubmit}
                />
            )}
        </Fragment>
    );
};
