import { authHooks } from "@/auth";
import { SearchField } from "@/components/custom/search/search";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { Invoice04Icon } from "@hugeicons/react";
import { Fragment, useState } from "react";
import { PurchaseOrderTable } from "../components/PurchaseOrderTable";
import { PurchaseOrderStatus } from "../constans/PurchaseOrderStatus";
import { useGetDomainPurchaseOrders } from "../hooks";
import { PurchaseOrderCreateSheet } from "./PurchaseOrderCreateSheet";
import { PurchaseOrderUpdateSheet } from "./PurchaseOrderUpdateSheet";

export const PurchaseOrderOverviewPage = () => {
    const [purchaseOrderId, setPurchaseOrderId] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [isPurchaseCreateSheetOpen, setIsPurchaseCreateSheetOpen] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [activeStatus, setActiveStatus] = useState<PurchaseOrderStatus>(PurchaseOrderStatus.Open);
    const { activeDomain } = authHooks.useAuth();
    const { data: purchaseOrders = [] } = useGetDomainPurchaseOrders(activeDomain.id, page, activeStatus, searchQuery);

    const tabItems: Array<{ name: string; purchaseOrderStatus: PurchaseOrderStatus }> = [
        {
            name: "New",
            purchaseOrderStatus: PurchaseOrderStatus.Open
        },
        {
            name: "Pending",
            purchaseOrderStatus: PurchaseOrderStatus.Pending
        },
        {
            name: "Completed",
            purchaseOrderStatus: PurchaseOrderStatus.Completed
        }
    ];

    const handleTabChange = (newPurchaseStatus: PurchaseOrderStatus) => {
        if (activeStatus !== newPurchaseStatus) {
            setPage(1);
            setSearchQuery(null);
        }

        setActiveStatus(newPurchaseStatus);
    };

    const handleViewPurchaseOrder = (id: number) => {
        setPurchaseOrderId(id);
    };

    return (
        <Fragment>
            <TypographyH1>Purchase Orders</TypographyH1>
            <TypographyP>Here you can see all purchase orders for this domain.</TypographyP>

            <Flex
                alignItems="end"
                justifyContent="start"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b h-7")}
            >
                {tabItems.map((tabItem, index) => (
                    <div
                        key={index}
                        onClick={() => handleTabChange(tabItem.purchaseOrderStatus)}
                        className={cn(
                            "flex h-7 items-center justify-center px-4 text-center text-sm transition-colors hover:text-primary cursor-pointer",
                            tabItem.purchaseOrderStatus === activeStatus
                                ? "font-medium text-primary border-b border-primary"
                                : "text-muted-foreground"
                        )}
                    >
                        <span className="mr-3">{tabItem.name}</span>
                    </div>
                ))}
            </Flex>
            <Card className="overflow-hidden rounded-md p-0 shadow-3xl">
                <CardHeader>
                    <Flex justifyContent="end">
                        <div className="w-72 flex gap-x-4">
                            <Button
                                icon={Invoice04Icon}
                                variant="outline"
                                onClick={() => setIsPurchaseCreateSheetOpen(true)}
                            >
                                New PO
                            </Button>
                            <SearchField onChange={setSearchQuery} />
                        </div>
                    </Flex>
                </CardHeader>
                <CardContent className="p-0">
                    <PurchaseOrderTable orders={purchaseOrders} onViewPurchaseOrderClick={handleViewPurchaseOrder} />
                </CardContent>
            </Card>
            <PurchaseOrderCreateSheet
                open={isPurchaseCreateSheetOpen}
                onClose={() => setIsPurchaseCreateSheetOpen(false)}
            />
            <PurchaseOrderUpdateSheet
                onClose={() => setPurchaseOrderId(0)}
                open={!!purchaseOrderId}
                purchaseOrderId={purchaseOrderId}
            />
        </Fragment>
    );
};
