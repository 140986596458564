import { IChannel } from "@/channel/types";
import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import Flex from "@/components/ui/flex";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Table, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { orderTypes } from "@/order";
import { InformationCircleIcon } from "@hugeicons/react";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import { Fragment } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { IProduct } from "../types";

interface IProductDashboardProps {
    product: IProduct;
    channels: IChannel[];
    orders: orderTypes.IOrder[];
}

export const ProductDashboard = ({ product, orders, channels = [] }: IProductDashboardProps) => {
    const orderProductCount = orders.reduce((acc: number, order: orderTypes.IOrder) => {
        const orderProduct = order.products.find(op => op.product_id === product.id);
        if (orderProduct) {
            return acc + orderProduct.quantity;
        }

        return acc;
    }, 0);

    const salesPerDay = orders.flatMap(order =>
        order.products
            .filter(orderProduct => orderProduct.product_id === product.id)
            .map(orderProduct => ({
                date: Intl.DateTimeFormat("nl-NL", {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                }).format(new Date(order.created_at)),
                quantity: orderProduct.quantity
            }))
    );

    console.log("salesPerDay", salesPerDay);
    return (
        <ScrollArea>
            <div className="grid grid-cols-4 gap-x-4">
                {product?.media[0]?.preview_url && (
                    <Fragment>
                        <img
                            src={product?.media[0]?.preview_url || ""}
                            alt="product"
                            className="w-32 h-32 object-cover rounded-xl border p-2"
                        />

                        <div className="col-span-3">
                            <TypographyH3 className="text-xl">{product?.name}</TypographyH3>
                            inkkoprijs {product?.default_price}
                        </div>
                    </Fragment>
                )}
            </div>
            <Card className="mt-8">
                <CardHeader className="border-b p-2 text-muted-foreground">{product?.name}</CardHeader>
                <CardContent className="grid grid-cols-3 divide-x p-0 text-foreground">
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Stock</TypographyH3>
                        <div>
                            <Badge className="text-xs font-light">{product.in_stock}</Badge>
                        </div>
                    </div>
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Sold</TypographyH3>
                        <div>{orderProductCount}</div>
                    </div>
                    <div className="p-3 py-3">
                        <TypographyH3 className="uppercase text-xs">Identity</TypographyH3>
                        <div>E-win trading</div>
                    </div>
                </CardContent>
            </Card>

            <Card className="flex flex-col mt-8" x-chunk="charts-01-chunk-1">
                <CardHeader className="flex flex-row items-center gap-4 space-y-0 pb-8 [&>div]:flex-1">
                    <div>
                        <CardDescription>Total sales last week</CardDescription>
                        <CardTitle className="flex items-baseline gap-1 text-4xl tabular-nums">
                            62
                            <span className="text-sm font-normal tracking-normal text-muted-foreground">qty</span>
                        </CardTitle>
                    </div>
                </CardHeader>
                <CardContent className="flex flex-1 items-center">
                    <ChartContainer
                        config={{
                            resting: {
                                label: "Resting",
                                color: "hsl(var(--chart-1))"
                            }
                        }}
                        className="w-full h-32 pt-2"
                    >
                        <LineChart
                            accessibilityLayer
                            data={[
                                {
                                    date: "2024-01-01",
                                    resting: 62,
                                    ab: 44
                                },
                                {
                                    date: "2024-01-02",
                                    resting: 72,
                                    ab: 11
                                },
                                {
                                    date: "2024-01-03",
                                    resting: 35,
                                    ab: 60
                                },
                                {
                                    date: "2024-01-04",
                                    resting: 62,
                                    ab: 44
                                },
                                {
                                    date: "2024-01-05",
                                    resting: 52,
                                    ab: 24
                                },
                                {
                                    date: "2024-01-06",
                                    resting: 62,
                                    ab: 84
                                },
                                {
                                    date: "2024-01-07",
                                    resting: 70,
                                    ab: 44
                                }
                            ]}
                        >
                            <CartesianGrid
                                strokeDasharray="1 6"
                                vertical={false}
                                stroke="hsl(var(--muted-foreground))"
                                strokeOpacity={0.5}
                            />
                            <YAxis hide domain={["dataMin - 10", "dataMax + 10"]} />
                            <XAxis
                                dataKey="date"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={10}
                                tickFormatter={value => {
                                    return new Date(value).toLocaleDateString("nl-NL", {
                                        weekday: "short"
                                    });
                                }}
                            />

                            <Line
                                dataKey="ab"
                                type="natural"
                                fill="var(--color-resting)"
                                stroke="var(--color-resting)"
                                strokeWidth={1}
                                dot={false}
                                activeDot={{
                                    fill: "var(--color-resting)",
                                    stroke: "var(--color-resting)",
                                    r: 2
                                }}
                            />
                            <ChartTooltip
                                content={
                                    <ChartTooltipContent
                                        indicator="line"
                                        labelFormatter={value => {
                                            return new Date(value).toLocaleDateString("nl-NL", {
                                                day: "numeric",
                                                month: "long",
                                                year: "numeric"
                                            });
                                        }}
                                    />
                                }
                                cursor={false}
                            />
                        </LineChart>
                    </ChartContainer>
                </CardContent>
            </Card>

            {/* <Card className="w-1/2 mt-8" x-chunk="charts-01-chunk-6">
                                                    <CardHeader className="p-4 pb-0">
                                                        <CardTitle>Active Energy</CardTitle>
                                                        <CardDescription>
                                                            You're burning an average of 754 calories per day. Good job!
                                                        </CardDescription>
                                                    </CardHeader>
                                                    <CardContent className="flex flex-row items-baseline gap-4 p-4 pt-2">
                                                        <div className="flex items-baseline gap-2 text-3xl font-bold tabular-nums leading-none">
                                                            1,254
                                                            <span className="text-sm font-normal text-muted-foreground">
                                                                kcal/day
                                                            </span>
                                                        </div>
                                                    </CardContent>
                                                </Card> */}

            <Card className="mt-8">
                <CardHeader className="border-b p-4">
                    <CardTitle>Channels</CardTitle>
                </CardHeader>
                <CardContent className="divide-y divide-dashed p-4">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-[100px]">Channel name</TableHead>
                                <TableHead>
                                    <Flex justifyContent="start" className="gap-x-2">
                                        AI
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger>
                                                <InformationCircleIcon className="w-4" />
                                            </TooltipTrigger>
                                            <TooltipContent
                                                side="top"
                                                className="flex items-center gap-4 bg-white border shadow-sm"
                                            >
                                                <TooltipArrow className="fill-white" />
                                                <p className="text-muted-foreground w-56">
                                                    SEO optimized product text is not generated yet. Click on the AI
                                                    icon to generate it.
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </Flex>
                                </TableHead>
                                {/* <TableHead>Method</TableHead> */}
                                {/* <TableHead className="text-right">
                                                                                Amount
                                                                            </TableHead> */}
                                <TableHead className="text-right">Sales price + margin</TableHead>
                            </TableRow>
                        </TableHeader>
                        {/* <TableBody>
                            {channels.map((channel, index) => {
                                const channelProduct = product?.channelProducts.find(
                                    (cp) => (cp.channel_id as any) === channel.id
                                );

                                return (
                                    <TableRow>
                                        <TableCell className="font-medium">
                                            <div className="text-sm text-muted-foreground w-48">
                                                {channel.company_name}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                {channelProduct ? (
                                                    <Button
                                                        onClick={() => {
                                                            setChannelProduct(channelProduct);
                                                            setShowChannelProductDialogForm(true);
                                                        }}
                                                        variant="outline"
                                                        className="border-primary"
                                                    >
                                                        <AiSearchIcon className="w-4 text-primary" />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            setChannelProduct(null);
                                                            setShowChannelProductDialogForm(true);
                                                            handleCreateChannelProductAiText(channel.id);
                                                        }}
                                                        variant="outline"
                                                    >
                                                        <ArtificialIntelligence02Icon className="w-4 text-muted-foreground" />
                                                    </Button>
                                                )}
                                            </div>
                                        </TableCell>
                                        {/* <TableCell>Marge</TableCell>
                                        <TableCell className="text-right">
                                            <div className="text-muted-foreground">
                                                {MoneyFormatter(100000 + index * 9999)}{" "}
                                                <Badge variant="secondary" className="ml-4">
                                                    +30
                                                </Badge>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody> */}
                    </Table>
                </CardContent>
            </Card>
        </ScrollArea>
    );
};
