"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { authHooks } from "@/auth";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp";
import { useCreateProduct } from "@/product/hooks";

interface IEanInputFormProps {
    onSuccess: (productId: number) => void;
}

const FormSchema = z.object({
    ean: z.string().min(13, {
        message: "A EAN or GTIN must be 13 characters long",
    }),
});

export function EanInputForm({ onSuccess }: IEanInputFormProps) {
    const { activeDomain } = authHooks.useAuth();
    const { mutateAsync: createProduct, isLoading } = useCreateProduct(activeDomain.id);
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            ean: "",
        },
    });

    const handleEanSumbit = (ean: string) => {
        createProduct(ean)
            .then((product) => {
                onSuccess(product.id);
            })
            .catch(({ response }) => {
                Object.keys(response.data.errors).forEach((error: any) => {
                    form.setError(error, {
                        type: "manual",
                        message: response.data.errors[error][0],
                    });
                });
            });
    };

    return (
        <Flex alignItems="center" justifyContent="center" className="w-full">
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit((data: z.infer<typeof FormSchema>) => handleEanSumbit(data.ean))}
                    className="w-2/3 space-y-6"
                >
                    <FormField
                        control={form.control}
                        name="ean"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>EAN / GTIN</FormLabel>
                                <FormControl>
                                    <InputOTP maxLength={13} {...field}>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={0} />
                                            <InputOTPSlot index={1} />
                                            <InputOTPSlot index={2} />
                                            <InputOTPSlot index={3} />
                                            <InputOTPSlot index={4} />
                                            <InputOTPSlot index={5} />
                                            <InputOTPSlot index={6} />
                                            <InputOTPSlot index={7} />
                                            <InputOTPSlot index={8} />
                                            <InputOTPSlot index={9} />
                                            <InputOTPSlot index={10} />
                                            <InputOTPSlot index={11} />
                                            <InputOTPSlot index={12} />
                                        </InputOTPGroup>
                                    </InputOTP>
                                </FormControl>
                                <FormDescription>Please enter the EAN or GTIN of the product.</FormDescription>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <Button disabled={isLoading} isLoading={isLoading} type="submit">
                        Search EAN
                    </Button>
                </form>
            </Form>
        </Flex>
    );
}
