export enum OrderStatus {
    PickUp = 0,
    New = 1,
    FutureShipping = 2,
    Shipping = 3,
    Warehouse = 4,
    Shipped = 5,
    Cancelled = 6,
    Completed = 7,
    Error = 99
}
