import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import {
    ColumnDef,
    ColumnFiltersState,
    Table as ReactTable,
    TableOptions as ReactTableOptions,
    Row,
    RowSelectionState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { JSXElementConstructor, ReactElement, Ref, forwardRef, useState } from "react";

// Redecalare forwardRef
// this is to be able to use React.forwardRef to wrap Table component but to pass TData generic...
// more https://fettblog.eu/typescript-react-generic-forward-refs/
// @todo - find better approach since return type of render function "React.ReactElement" gived problem with displayName. When changing to "React.FunctionComponent"
//         then DataTable consumers break on column type
declare module "react" {
    function forwardRef<T, P = object>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => (React.ReactElement & { displayName?: string }) | null;
}

export type TDataTable<TData> = ReactTable<TData>;

export interface IDataTableRenderProps<TData> {
    table: TDataTable<TData>;
    TableComponent: ReactElement;
}

export interface IDataTableProps<TData> {
    columns: ColumnDef<TData>[];
    data: TData[];
    allowColumnSelection?: boolean;
    allowSearch?: boolean;
    features?: {
        columnSelection?: boolean;
        localSearch?: boolean;
        rowSelection?: RowSelectionState;
    };
    onTableRowClick?: (data: Row<TData>) => void;
    onRowSelectionChange?: (data: Row<TData>[]) => void;
    rowClassName?: (data: any) => string;
    children?: (
        props: IDataTableRenderProps<TData>
    ) => ReactElement<IDataTableProps<TData>, string | JSXElementConstructor<IDataTableProps<TData>>> | null;
}

export const DataTable = forwardRef(function <TData>(
    { columns, data, features, children, rowClassName, onTableRowClick }: IDataTableProps<TData>,
    ref: Ref<TDataTable<TData>>
) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    let options: ReactTableOptions<TData> = {
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {},
    };

    if (features?.columnSelection) {
        options = {
            ...options,
            onColumnVisibilityChange: setColumnVisibility,
            state: { ...options.state, columnVisibility },
        };
    }

    if (features?.localSearch) {
        options = {
            ...options,
            onColumnFiltersChange: setColumnFilters,
            getFilteredRowModel: getFilteredRowModel(),
            state: { ...options.state, columnFilters },
        };
    }

    if (true) {
        options = {
            ...options,
            onRowSelectionChange: setRowSelection,
            state: { ...options.state, rowSelection },
        };
    }

    const table = useReactTable(options);

    /**
     * Expose api to consumer component by making use of ref
     */
    // useImperativeHandle(ref, () => table);

    const handleTableRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (onTableRowClick) {
            const rowId = event.currentTarget.getAttribute("data-id");

            if (rowId) {
                const row = table.getRow(rowId);

                onTableRowClick(row);
            }
        }
    };

    const tableComponent = (
        <Table className="border border-muted/80 bg-white">
            <TableHeader className="bg-muted border-none rounded-xl">
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id} className="border-none">
                        {headerGroup.headers.map((header) => {
                            return (
                                <TableHead
                                    key={header.id}
                                    className="text-xs uppercase font-normal text-muted-foreground whitespace-nowrap"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableHead>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHeader>
            <TableBody>
                {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                        <TableRow
                            className="border-muted"
                            onClick={handleTableRowClick}
                            key={row.id}
                            data-id={row.id}
                            data-state={row.getIsSelected() && "selected"}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} className="text-muted-foreground py-3.5">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={columns.length} className="text-center">
                            No results.
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    return children ? children({ table, TableComponent: tableComponent }) : tableComponent;
});
