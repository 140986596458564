import { carrierTypes } from "@/carrier";
import { channelTypes } from "@/channel";
import { Popover, Transition } from "@headlessui/react";
import { FilterIcon } from "@hugeicons/react";
import { Fragment } from "react";
import { FilterForm } from "./FilterForm";

export interface IFilterValues {
    to: string;
    from: string;
    carriers: Array<{ label: string; value: number }>;
    channels: Array<{ label: string; value: number }>;
}

interface IFilterDropDown {
    channels: channelTypes.IChannel[];
    carriers: carrierTypes.ICarrier[];
    onSubmit: (values: any) => void;
    formValues?: IFilterValues;
}

export const FilterDropDown = ({ channels, carriers, onSubmit, formValues }: IFilterDropDown) => {
    const hasFilters = Object.values(formValues || []).length;

    return (
        <div className="">
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                relative cursor-pointer text-center items-center bg-white border border-slate-300 rounded-md shadow-sm text-sm leading-4 text-slate-700 hover:bg-slate-50 px-4 py-2 font-medium`}
                        >
                            <span className="flex items-center h-full text-sm">
                                <FilterIcon className="w-4 mr-2" />
                                Filter ({hasFilters})
                            </span>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-2/4 transform px-4 sm:px-0 ">
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative grid gap-8 bg-white p-4">
                                        <FilterForm
                                            formValues={formValues}
                                            carriers={carriers}
                                            channels={channels}
                                            onSubmit={(value) => onSubmit(value)}
                                        />
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    );
};
