import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> {
    prefix?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, prefix, ...props }, ref) => {
    return (
        <div className={cn("relative border border-input rounded-md overflow-hidden", className)}>
            {prefix && (
                <div className="absolute h-9 w-9 bg-muted text-muted-foreground flex justify-center items-center">
                    {prefix}
                </div>
            )}
            <input
                type={type}
                className={cn(
                    "flex h-9 w-full bg-white px-3 py-1 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:bg-muted/50",
                    prefix && "pl-12",
                    className
                )}
                ref={ref}
                {...props}
            />
        </div>
    );
});
(Input as any).displayName = "Input";

export { Input };
