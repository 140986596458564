import { TypographyH3 } from "@/components/custom/text/TypographyH3";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MultiplicationSignIcon, PlusSignIcon } from "@hugeicons/react";
import { useForm } from "react-hook-form";
import { useCreateEan, useDeleteEan } from "../hooks";
import { IProduct } from "../types";

interface IEanFormProps {
    product: IProduct;
}

interface IEanFormValues {
    ean: string;
}

export const EanForm = ({ product }: IEanFormProps) => {
    const { mutateAsync: deleteEan } = useDeleteEan(product.id);
    const { mutateAsync: createEan } = useCreateEan(product.id);
    const form = useForm<IEanFormValues>();

    const handleSubmit = async (data: IEanFormValues) => {
        try {
            await createEan(data.ean);
            form.reset({
                ean: ""
            });
        } catch (error) {
            const err = error as { response: { data: { errors: Record<string, string[]> } } };
            if (err.response && err.response.data && err.response.data.errors) {
                const serverErrors = err.response.data.errors;
                Object.keys(serverErrors).forEach(field => {
                    form.setError(field as keyof IEanFormValues, {
                        type: "server",
                        message: serverErrors[field][0]
                    });
                });
            }
        }
    };

    const handleDeleteEan = (eanId: number) => {
        deleteEan(eanId).catch(() => {
            // toast.error("Failed to delete EAN");
        });
    };

    return (
        <div className="">
            <TypographyH3>EAN aliases</TypographyH3>
            <TypographyP className="mt-1">EANs are used to identify products and track inventory.</TypographyP>

            <div className="grid grid-cols-3 gap-2">
                {product.eans.map(ean => (
                    <div className="col-span-1">
                        <Flex
                            justifyContent="between"
                            alignItems="center"
                            key={ean.id}
                            className="bg-slate-100 hover:bg-slate-200 h-10 rounded-md"
                        >
                            <div className="text-sm px-2.5" key={ean.id}>
                                {ean.ean}
                            </div>
                            {product.eans.length > 1 && (
                                <Button
                                    variant="ghost"
                                    className="hover:bg-transparent hover:text-primary"
                                    onClick={() => handleDeleteEan(ean.id)}
                                >
                                    <MultiplicationSignIcon className="w-4 h-4 bg" />
                                </Button>
                            )}
                        </Flex>
                    </div>
                ))}
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <Flex justifyContent="start" alignItems="start" className="my-4">
                        <FormField
                            control={form.control}
                            name="ean"
                            rules={{
                                required: "EAN is required",
                                pattern: {
                                    value: /^\d{13}$/,
                                    message: "EAN must be exactly 13 digits"
                                }
                            }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input placeholder="Enter EAN" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Button variant="outline" disabled={!form.formState.isValid} type="submit">
                            <PlusSignIcon className="w-4 h-4 text-primary" />
                        </Button>
                    </Flex>
                </form>
            </Form>
        </div>
    );
};
