import { axios } from "@/app";
import { IWarehouse } from "./types";

export const getWarehouse = async (id: number): Promise<IWarehouse> => {
    const response = await axios.get<{ data: IWarehouse }>(`warehouses/${id}`);

    return response.data.data;
};

export const getDomainWarehouses = async (domainId: number): Promise<IWarehouse[]> => {
    const response = await axios.get<{ data: IWarehouse[] }>(`domains/${domainId}/warehouses`);

    return response.data.data;
};

export const updateWarehouse = async (warehouse: IWarehouse): Promise<IWarehouse> => {
    const response = await axios.put(`warehouses/${warehouse.id}`, warehouse);

    return response.data;
};
