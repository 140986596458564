import { axios } from "@/app/services";
import { IAuthData, IResetTokenResponse } from "./types";

export const getUser = async (): Promise<IAuthData> => {
    const response = await axios.get<{ data: IAuthData }>("auth/check");

    return response.data.data;
};

export interface ILoginProps {
    email: string;
    password: string;
}

export const login = async (props: ILoginProps): Promise<IAuthData> => {
    const response = await axios.post<{ data: IAuthData }>("auth/login", props);

    return response.data.data;
};

export const logout = async () => {
    await axios.get("auth/logout");
};
export interface IRequestPasswordResetProps {
    email: string;
    reset_token: string;
    password: string;
}

export const requestForgotPassword = async (props: IRequestPasswordResetProps) => {
    await axios.post("auth/forgot-password", props);
};

export const validateResetLink = async (token: string): Promise<IResetTokenResponse> => {
    const response = await axios.post<IResetTokenResponse>("auth/validate-reset-token", { token });

    return response.data;
};

export const requestResetPassword = async (props: IRequestPasswordResetProps) => {
    const response = await axios.post("auth/reset-password", props);

    return response.data;
};

export const switchDomain = async (domain_id: number) => {
    const response = await axios.post("auth/switch-domain", { domain_id });

    return response.data;
};
