import { Skeleton } from "@/components/ui/skeleton";

export const ProductAiLoadingForm = () => (
    <div className="w-full ml-18">
        <div className="grid grid-cols-2 gap-x-8 gap-y-8 p-0.5">
            <div className="gap-y-2 col-span-2 ">
                <Skeleton className="w-2/3 h-6 bg-muted bg-slate-200" />
            </div>
            <div className="gap-y-2">
                <Skeleton className="w-2/3 h-4 bg-muted bg-slate-200" />
                <Skeleton className=" h-6 bg-muted bg-slate-200 mt-3" />
            </div>
            <div className="gap-y-2">
                <Skeleton className="w-2/3 h-4 bg-muted bg-slate-200" />
                <Skeleton className=" h-6 bg-muted bg-slate-200 mt-3" />
            </div>
            <div className="gap-y-2">
                <Skeleton className="w-2/3 h-4 bg-muted bg-slate-200" />
                <Skeleton className=" h-6 bg-muted bg-slate-200 mt-3" />
            </div>
            <div className="gap-y-2 col-span-2">
                <Skeleton className="w-2/3 h-4 bg-muted bg-slate-200" />
                <Skeleton className=" h-6 bg-muted bg-slate-200 mt-3" />
            </div>
            <div className="gap-y-2 col-span-2">
                <Skeleton className="w-2/3 h-4 bg-muted bg-slate-200" />
                <Skeleton className=" h-12 bg-muted bg-slate-200 mt-3" />
            </div>
        </div>
    </div>
);
