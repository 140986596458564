import { debounce } from "lodash";
import React, { useRef } from "react";
import { Input } from "../../ui/input";

export interface ISearchFieldProps {
    defaultValue?: string;
    onChange: (query: string) => void;
    onClear?: () => void;
    minLength?: number;
    className?: string;
}

export const SearchField = ({ onChange, onClear, minLength = 3, className, ...fieldProps }: ISearchFieldProps) => {
    const debouncedHandleChange = useRef(
        debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            const query = event.target.value;

            if (isValidQuery(query, minLength)) {
                onChange(query);
            } else if (query.trim().length === 0 && onClear) {
                onClear();
            }
        }, 300)
    ).current;

    return (
        <Input
            {...fieldProps}
            // prefixIcon={Search01Icon}
            //   suffixIcon={<MagnifyingGlassIcon className="text-gray-400 w-4" />}
            onChange={debouncedHandleChange}
            placeholder="Search"
            className={className}
        />
    );
};

const isValidQuery = (query: string, minLength: number) => typeof query === "string" && query.length >= minLength;
