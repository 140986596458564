import { authHooks } from "@/auth";
import { MoneyFormatter } from "@/common";
import { Pagination } from "@/components/custom/pagination";
import { SearchField } from "@/components/custom/search/search";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";
import { TypographyP } from "@/components/custom/text/TypographyP";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Flex from "@/components/ui/flex";
import { Download04Icon, Tv01Icon } from "@hugeicons/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductTable } from "../components/ProductTable";
import { useGetDownloadCsv, useGetProducts, useGetTotalStockValue } from "../hooks";
import { ProductSheet } from "./ProductSheet";

export const ProductOverviewPage = () => {
    const { productId: paramProductId } = useParams();
    const [productId, setProductId] = useState<number | null>(null);
    const [showProductSheet, setShowProductSheet] = useState(false);
    const auth = authHooks.useAuth();
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const { data, isLoading } = useGetProducts(auth.activeDomain, page, searchQuery);
    const totalPages = data?.meta.per_page ? Math.ceil(data.meta.total / data.meta.per_page) : 0;

    const { data: totalStockValue, isLoading: isLoadingTotalStockValue } = useGetTotalStockValue(auth.activeDomain);
    const { mutate: downloadCsv } = useGetDownloadCsv(auth.activeDomain.id);

    const handleEditProduct = (productId: number) => {
        setProductId(productId);
        setShowProductSheet(true);
    };

    const handleProductSheetClose = () => {
        setProductId(null);
        setShowProductSheet(false);
    };

    useEffect(() => {
        if (paramProductId) {
            setProductId(parseInt(paramProductId));
            setShowProductSheet(true);
        }
    }, [paramProductId]);

    return (
        <div>
            <TypographyH1>Products</TypographyH1>
            <TypographyP>Here you can see all products for this domain.</TypographyP>

            <div className="grid grid-cols-4 gap-4">
                {!isLoadingTotalStockValue && (
                    <Card className="col-span-1 my-4">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4">
                            <CardTitle className="text-sm font-medium">Stock value</CardTitle>
                        </CardHeader>
                        <CardContent className="px-4">
                            <div className="text-2xl font-bold text-primary">
                                {MoneyFormatter(totalStockValue?.total_in_stock_in_euro || 0)}
                            </div>
                            <p className="text-xs text-muted-foreground">Includes sold products, not in stock.</p>
                        </CardContent>
                    </Card>
                )}
            </div>

            {isLoading ? (
                <DataTableSkeleton />
            ) : (
                <Card>
                    <CardHeader>
                        <Flex>
                            <div className="w-96">
                                <SearchField
                                    className="w-96"
                                    defaultValue={searchQuery ?? undefined}
                                    onChange={query => {
                                        setSearchQuery(query);
                                        setPage(1);
                                    }}
                                />
                            </div>
                            <Flex justifyContent="end" className="gap-4">
                                <Button variant="outline" onClick={() => setShowProductSheet(true)}>
                                    <Tv01Icon className="w-5 h-5 mr-2" /> Add product
                                </Button>
                                <Button variant="outline" onClick={() => downloadCsv()}>
                                    <Download04Icon className="w-5 h-5 mr-2" /> Download stock
                                </Button>
                            </Flex>
                        </Flex>
                    </CardHeader>
                    <CardContent className="p-0">
                        <ProductTable onEditProduct={handleEditProduct} data={data?.data || []} />
                    </CardContent>
                </Card>
            )}
            {data?.data && totalPages > 1 && (
                <Pagination
                    className="mt-4"
                    meta={data.meta}
                    onPageChange={(pageNumber: number) => setPage(pageNumber)}
                />
            )}

            <ProductSheet productId={productId} open={showProductSheet} handleClose={handleProductSheetClose} />
        </div>
    );
};
