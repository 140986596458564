import MediaLibraryCollection from "@/components/custom/media-library-pro-react-collection/MediaLibraryCollection";

import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { IProduct } from "../types";

interface IProductMediaForm {
    product: IProduct;
}

export const ProductMediaForm = ({ product }: IProductMediaForm) => {
    const { control, ...form } = useForm<IProduct>({
        defaultValues: {
            media: product.media
        }
    });

    return (
        <Form {...form} control={control}>
            <form className="w-full">
                <MediaLibraryCollection
                    onChange={(item: any) => console.log("chane", item)}
                    name="product"
                    form={form}
                    initialValue={product.media}
                />
            </form>
        </Form>
    );
};
