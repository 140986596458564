import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { domainTypes } from "@/domain";
import { warehouseTypes } from "@/warehouse";
import { useMutation, useQuery } from "react-query";
import QueryKeys from "./constans/QueryKeys";
import {
    createChannelProductAiText,
    createEan,
    createProduct,
    deleteEan,
    getDownloadCsv,
    getProduct,
    getProducts,
    getProductWarehouses,
    getTotalStockValue,
    importProductIcecat,
    regenerateChannelProductAiText,
    syncProductChannel,
    updateChannelProduct,
    updateProduct,
    updateProductWarehouses
} from "./queries";
import { IChannelProduct, IProduct, IProductResponse, IProductWarehouse, ITotalStockValue } from "./types";

export const useGetProducts = (
    domain: domainTypes.IDomain,
    page: number,
    search: string | null,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<IProductResponse, Error>(
        ["product/items", { domain, page, search }],
        () => getProducts(domain, page, search),
        options
    );

export const useGetProduct = (id: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProduct, Error>([QueryKeys.ProductItem, id], () => getProduct(id), options);

export const useGetProductWarehouses = (productId: number, options?: commonTypes.IUseGetOptions) =>
    useQuery<warehouseTypes.IWarehouse[], Error>(
        ["product/item/warehouses", productId],
        () => getProductWarehouses(productId),
        options
    );

export const useCreateProduct = (domainId: number) =>
    useMutation((ean: string) => createProduct(domainId, ean), {
        onSuccess: (product: IProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, product.id]);
        }
    });

export const useUpdateProduct = () =>
    useMutation(updateProduct, {
        onSuccess: productId => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useCreateChannelProductAiText = (productId: number | null) =>
    useMutation((channelId: number) => createChannelProductAiText(channelId, productId), {
        onSuccess: (channelProduct: IChannelProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, channelProduct.product_id]);
        }
    });

export const useUpdateChannelProduct = () =>
    useMutation(updateChannelProduct, {
        onSuccess: (productId: string) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useRegenerateChannelProductAiText = () =>
    useMutation(regenerateChannelProductAiText, {
        onSuccess: (channelProduct: IChannelProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, channelProduct.product_id]);
        }
    });

export const useSyncProductChannel = () =>
    useMutation(syncProductChannel, {
        onSuccess: (product: IProduct) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, product.id]);
        }
    });

export const useUpdateProductWarehouses = (productId: number) =>
    useMutation(
        (values: { reason: string; productWarehouses: IProductWarehouse[] }) =>
            updateProductWarehouses(productId, values),
        {
            onSuccess: (productId: number) => {
                queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
            }
        }
    );

export const useImportProductIcecat = () =>
    useMutation((productId: number) => importProductIcecat(productId), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useDeleteEan = (productId: number) =>
    useMutation((eanId: number) => deleteEan(productId, eanId), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useCreateEan = (productId: number) =>
    useMutation((ean: string) => createEan(productId, ean), {
        onSuccess: (productId: number) => {
            queryClient.invalidateQueries([QueryKeys.ProductItem, productId]);
        }
    });

export const useGetTotalStockValue = (domain: domainTypes.IDomain, options?: commonTypes.IUseGetOptions) =>
    useQuery<ITotalStockValue, Error>(
        [QueryKeys.TotalStockValue, domain.id],
        () => getTotalStockValue(domain),
        options
    );

export const useGetDownloadCsv = (domainId: number) => useMutation(() => getDownloadCsv(domainId));
