import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SubmitHandler, useForm } from "react-hook-form";
import { IProduct } from "../types";

export interface IProductFormProps {
    onSubmit: SubmitHandler<IProduct>;
    item: IProduct | undefined;
}

export function ProductForm({ onSubmit, item }: IProductFormProps) {
    const form = useForm<IProduct>({
        defaultValues: item
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mb-8">
                <div className="grid grid-cols-2 gap-4">
                    <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem className="col-span-2">
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="default_price"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Default price</FormLabel>
                                <FormControl>
                                    <Input placeholder="1000.00" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="purchase_price"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Purchase price (value in cents)</FormLabel>
                                <FormControl>
                                    <Input placeholder="100000" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    {/* <FormField
                        control={form.control}
                        name="product_name"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Product name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Product name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}

                    {/* <FormField
                        control={form.control}
                        name="external_icecat_id"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Iceat ean</FormLabel>
                                <FormControl>
                                    <Input disabled placeholder="78595438" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}

                    {/* <FormField
                        control={form.control}
                        name="status"
                        render={({ field }) => (
                            <FormItem className="col-span-1">
                                <FormLabel>Status</FormLabel>
                                <FormControl>
                                    <Switch {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}
                </div>
                <Flex justifyContent="end" className="mt-4">
                    <Button type="submit">Submit</Button>
                </Flex>
            </form>
        </Form>
    );
}
