// @ts-nocheck
// @ts-expect-error
import { Progress } from "@/components/ui/progress";
import { Upload01Icon } from "@hugeicons/react";
import { MediaLibrary } from "@spatie/media-library-pro-core/dist/types";
import * as React from "react";
import { TypographyH1 } from "../text/TypographyH1";
import { TypographyP } from "../text/TypographyP";
import DropZone from "./DropZone";

type Props = {
    add?: boolean;
    uploadInfo?: MediaLibrary.MediaObject["upload"];
    multiple: boolean;
    validationRules?: Partial<MediaLibrary.Config["validationRules"]>;
    maxItems?: number;
    fileTypeHelpText?: string;
    onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Uploader({
    add = true,
    uploadInfo,
    multiple = false,
    validationRules,
    maxItems,
    fileTypeHelpText,
    onDrop,
    onChange,
    ...props
}: Props) {
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange(event);

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    return (
        <DropZone
            validationAccept={validationRules?.accept}
            onDrop={onDrop}
            {...props}
            className={add ? "w-full border border-dashed my-4 rounded-lg flex justify-center items-center" : "hidden"}
            onClick={() => fileInputRef.current?.click()}
        >
            {({ hasDragObject, isDropTarget, isValid }) => (
                <>
                    <button
                        type="button"
                        className={`media-library-dropzone my-4 w-full px-4
                                    ${hasDragObject && !isDropTarget ? "media-library-dropzone-drag" : ""}
                                    ${hasDragObject && isDropTarget ? "media-library-dropzone-drop" : ""}
                                    ${add ? "media-library-dropzone-add" : "media-library-dropzone-replace"}
                                    ${!isValid && hasDragObject ? "disabled" : ""}
                                `}
                    >
                        <input
                            type="file"
                            accept={validationRules?.accept?.join(",")}
                            className="hidden"
                            ref={fileInputRef}
                            multiple={multiple}
                            onChange={handleChange}
                            {...{ dusk: add ? "main-uploader" : "uploader" }}
                        />

                        {uploadInfo && (
                            <div
                                className={` ${
                                    uploadInfo.hasFinishedUploading ? "" : "media-library-progress-wrap-loading"
                                }`}
                            >
                                a
                                <Progress
                                    max={100}
                                    value={uploadInfo.uploadProgress}
                                    className="media-library-progress"
                                />
                                a
                            </div>
                        )}

                        <div className="w-full h-full flex justify-start items-center gap-x-4">
                            {add && <Upload01Icon width={36} height={36} variant="twotone" className="text-primary" />}

                            {add && (
                                <div className="text-sm text-gray-500 w-full flex justify-start items-center">
                                    {isValid && hasDragObject ? (
                                        isDropTarget ? (
                                            <TypographyH1 className="text-base font-normal">
                                                Drop it while it's hot!
                                            </TypographyH1>
                                        ) : (
                                            <TypographyH1 className="text-base font-normal">Almost there!</TypographyH1>
                                        )
                                    ) : (
                                        <div className="flex flex-col justify-start items-start">
                                            <TypographyH1 className="text-base font-normal">
                                                Upload the Purchase Order or Slip order here.
                                            </TypographyH1>
                                            <TypographyP className="my-0 text-xs text-light">
                                                Supported format: PDF, JPG, PNG
                                            </TypographyP>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </button>
                </>
            )}
        </DropZone>
    );
}
