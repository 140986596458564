import { useQuery } from "react-query";
import { QueryKeys } from "./constants/QueryKey";
import { getTranslations } from "./queries";
import { ITranslation, IUseGetOptions } from "./types";
import { useContext } from "react";
import { NotificationManagerContext } from "./components/notification/NotificationManagerContext";

export const useGetTranslations = (options?: IUseGetOptions) =>
    useQuery<ITranslation, Error>([QueryKeys.Translations], () => getTranslations(), options);

export function useNotificationManagerContext() {
    return useContext(NotificationManagerContext);
}
