import { useAuth } from "@/auth/hooks";
import { Pagination } from "@/components/custom/pagination";
import { DataTableSkeleton } from "@/components/custom/table/DataTableSkeleton";
import { Button } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { UserAdd01Icon } from "@hugeicons/react";
import { useState } from "react";
import { UserTable } from "../components/userTable";
import { useGetDomainUsers, useUpdateUser } from "../hooks";
import { IUser } from "../types";
import { UserSheet } from "./UserSheet";

export const UserOverviewPage = () => {
    const [user, setUser] = useState<IUser | null>(null);
    const [activeTab, setActiveTab] = useState<string>("All");
    const [filterUsers, setFilterUsers] = useState<IUser[] | null>(null);
    const [isUserSheetOpen, setIsUserSheetOpen] = useState<boolean>(false);
    const auth = useAuth();
    const { data: users, isLoading: isLoadingUsers } = useGetDomainUsers(auth.activeDomain.id, {
        enabled: !!auth.activeDomain
    });

    const { mutateAsync: updateUser } = useUpdateUser();
    const tabs = [
        {
            name: "All",
            onclick: () => {
                setFilterUsers(users?.data || []), setActiveTab("All");
            }
        },
        {
            name: "Members",
            onclick: () => {
                setFilterUsers(users?.data.filter(user => user.last_login) || []), setActiveTab("Members");
            }
        },
        {
            name: "Invited",
            onclick: () => {
                setFilterUsers(users?.data.filter(user => !user.last_login) || []), setActiveTab("Invited");
            }
        },
        {
            name: "Removed",
            onclick: () => {
                setFilterUsers(users?.data.filter(user => user.deleted_at) || []), setActiveTab("Removed");
            }
        }
    ];

    return (
        <div>
            <Button>
                <UserAdd01Icon className="mr-2 w-4 h-4" />
                Add user
            </Button>

            <Flex
                alignItems="end"
                justifyContent="start"
                className={cn("mb-0 text-foreground/80 my-8 w-full border-b h-7")}
            >
                {tabs.map(tab => (
                    <div
                        key={tab.name}
                        onClick={tab.onclick}
                        className={cn(
                            "flex h-7 items-center justify-center px-4 text-center text-sm transition-colors hover:text-primary cursor-pointer",
                            tab.name === activeTab
                                ? "font-medium text-primary border-b border-primary"
                                : "text-muted-foreground"
                        )}
                    >
                        {tab.name}
                    </div>
                ))}
            </Flex>

            {isLoadingUsers ? (
                <DataTableSkeleton />
            ) : (
                <UserTable
                    users={filterUsers || users?.data || []}
                    onEdit={(user: IUser) => {
                        setUser(user), setIsUserSheetOpen(true);
                    }}
                />
            )}

            {user && (
                <UserSheet
                    user={user}
                    handleSubmit={(user: IUser) => updateUser(user)}
                    open={isUserSheetOpen}
                    onClose={() => setIsUserSheetOpen(false)}
                />
            )}

            {users && users.meta && <Pagination meta={users.meta} onPageChange={() => {}} />}
        </div>
    );
};
