import { queryClient } from "@/app";
import { commonTypes } from "@/common";
import { useMutation, useQuery } from "react-query";
import { PurchaseOrderStatus } from "./constans/PurchaseOrderStatus";
import QueryKeys from "./constans/QueryKeys";
import {
    createPurchaseOrder,
    getDomainPurchaseOrders,
    getPurchaseOrder,
    setPurchaseOrderStatusCompleted,
    updatePurchaseOrder
} from "./queries";
import { IPurchaseOrder } from "./types";

export const useGetDomainPurchaseOrders = (
    domainId: number,
    page: number,
    status: PurchaseOrderStatus,
    searchQuery: string | null,
    options?: commonTypes.IUseGetOptions
) => {
    return useQuery<IPurchaseOrder[], Error>(
        [QueryKeys.PurhaseOrderItems, page, status, searchQuery],
        () => getDomainPurchaseOrders(domainId, page, status, searchQuery),
        options
    );
};

export const useGetPurchaseOrder = (purchaseOrderId: number, options?: commonTypes.IUseGetOptions) => {
    return useQuery<IPurchaseOrder, Error>(
        [QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }],
        () => getPurchaseOrder(purchaseOrderId),
        options
    );
};

export const useCreateDomainPurchaseOrder = (domainId: number) => {
    return useMutation((data: IPurchaseOrder) =>
        createPurchaseOrder(domainId, data).then(res => {
            queryClient.invalidateQueries(QueryKeys.PurhaseOrderItems);

            return res;
        })
    );
};

export const useUpdatePurchaseOrder = (purchaseOrderId: number) => {
    return useMutation((data: IPurchaseOrder) =>
        updatePurchaseOrder(purchaseOrderId, data).then(purchaseOrderId => {
            queryClient.invalidateQueries([QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }]);
        })
    );
};

export const useSetPurchaseOrderStatusCompleted = (purchaseOrderId: number) => {
    return useMutation(() =>
        setPurchaseOrderStatusCompleted(purchaseOrderId).then(() => {
            queryClient.invalidateQueries([QueryKeys.PurhaseOrderItem, { id: purchaseOrderId }]);
        })
    );
};
