import { get } from "lodash";
import React, { useRef } from "react";
import { useMutation, UseMutationResult, UseQueryResult } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { CrudActions } from "../../constants/CrudActions";
import { CrudStates } from "../../constants/CrudStates";
import { useNotificationManagerContext } from "../../hooks";
import { TCrudStateFlags, TItemId } from "../../types";
import { utilsNumeric, utilsStr } from "@/common/utils";
import { NotificationTypes } from "@/common/constants/NotificationTypes";

export interface IHookResolverProps<TPageComponentProps> {
    itemId?: TItemId;
    crudAction: CrudActions;
    props: TPageComponentProps;
}

export interface IWithUpdateOrCreateItemProps<TItem, TPageComponentProps> {
    useGetItemHook?: (props: IHookResolverProps<TPageComponentProps>) => UseQueryResult<TItem, Error>;
    useUpdateItemHook?: (props: IHookResolverProps<TPageComponentProps>) => UseMutationResult<TItem, unknown, TItem>;
    useCreateItemHook?: (props: IHookResolverProps<TPageComponentProps>) => UseMutationResult<TItem, unknown, TItem>;
    targetUri?: string | ((props: IHookResolverProps<TPageComponentProps>) => string | undefined); // uri to redirect to after onCancel or onSubmit
    notificationTemplates?: {
        title?: string;
        msg?: string;
    };
    itemName: string;
    urlIdParam?: string;
    forcedCrudAction?: CrudActions | ((props: TPageComponentProps) => CrudActions);
}

/**
 * IAsyncSubmitData
 */
interface IAsyncSubmitData {
    crudAction: CrudActions;
    crudState: CrudStates;
}

export interface IWithUpdateOrCreateItemEnhancerProps<TItem, TSubmitItem = TItem> {
    itemId: TItemId;
    crudAction: CrudActions;
    crudState: CrudStates | undefined;
    crudStateFlags: TCrudStateFlags;
    item?: TItem | undefined;
    submitHandler: (item: TSubmitItem) => any | Promise<TSubmitItem>;
    cancelHandler: () => void;
}

/**
 * withUpdateOrCreateItem
 * Helper HOC to inject reuseable props to handle pages intented for Create/Update functionality.
 *
 * @param WrappedComponent
 * @returns
 */
export function withUpdateOrCreateItem<TPageComponentProps, TItem, TSubmitItem = TItem>(
    WrappedComponent: React.ComponentType<
        TPageComponentProps & IWithUpdateOrCreateItemEnhancerProps<TItem, TSubmitItem>
    >,
    {
        useGetItemHook,
        useUpdateItemHook,
        useCreateItemHook,
        targetUri,
        notificationTemplates,
        itemName,
        forcedCrudAction,
        urlIdParam = "id"
    }: IWithUpdateOrCreateItemProps<TItem, TPageComponentProps>
) {
    const useEmptyMutation = () => useMutation(async (resolve: () => void) => resolve());
    const defaultNotificationTemplates = {
        title: "{itemName} {crudState}",
        msg:
            "Item {itemName} #{item.id} successfully {crudState}. <a class='text-hc_green-700 underline' href='{toBack}'>Back</a>"
    };

    return (
        wrappedComponentProps: Omit<TPageComponentProps, keyof IWithUpdateOrCreateItemEnhancerProps<TItem, TSubmitItem>>
    ) => {
        const typedWrappedComponentProps = wrappedComponentProps as TPageComponentProps;
        const urlParams = useParams<string>();
        const id = get(urlParams, urlIdParam);
        const navigate = useNavigate();
        const location = useLocation();
        console.log("location", id);
        const isExistingItem = utilsNumeric.isNumeric(id);
        const processedForcedCrudAction =
            typeof forcedCrudAction === "function"
                ? forcedCrudAction(wrappedComponentProps as TPageComponentProps)
                : forcedCrudAction;
        const crudAction = processedForcedCrudAction || (isExistingItem ? CrudActions.Update : CrudActions.Create);
        const itemId = isExistingItem ? Number(id) : id || "";
        const hookResolverProps: IHookResolverProps<TPageComponentProps> = {
            itemId,
            crudAction,
            props: typedWrappedComponentProps
        };
        const { data = undefined, isSuccess = true, isFetching = false } = useGetItemHook
            ? useGetItemHook(hookResolverProps)
            : {};
        const hasUpdateMutator = !!useUpdateItemHook;
        const updateMutator = hasUpdateMutator ? useUpdateItemHook(hookResolverProps) : useEmptyMutation();
        const hasCreateMutator = !!useCreateItemHook;
        const createMutator = hasCreateMutator ? useCreateItemHook(hookResolverProps) : useEmptyMutation();
        const asyncSubmitDataRef = useRef<IAsyncSubmitData | {}>({});
        const processedTargerUri = typeof targetUri === "function" ? targetUri(hookResolverProps) : targetUri;

        if (crudAction === CrudActions.Create && !hasCreateMutator) {
            throw Error(`useCreateItemHook not defined while in ${CrudActions.Create} mode`);
        }

        if (!hasCreateMutator && !hasUpdateMutator) {
            throw new Error("At least one of useUpdateItemHook, useCreateItemHook should be provided...");
        }

        const crudStateFlags: TCrudStateFlags = {
            [CrudStates.Reading]: isFetching,
            [CrudStates.Read]: isSuccess,
            [CrudStates.Updating]: updateMutator.isLoading,
            [CrudStates.Updated]: updateMutator.isSuccess,
            [CrudStates.Creating]: createMutator.isLoading,
            [CrudStates.Created]: createMutator.isSuccess,
            [CrudStates.Destroying]: false,
            [CrudStates.Destroyed]: false
        };

        const crudState = resolveCrudStateByFlags(crudStateFlags);
        const { pushNotification } = useNotificationManagerContext();
        const currentCrudActionMutator = crudAction === CrudActions.Create ? createMutator : updateMutator;
        console.log("location", crudAction);
        // const suggestedPageTitle = utilsStr.supplant("{crudAction} {itemName}", {
        //     crudAction: utilsStr.capitalize(crudAction),
        //     itemName: utilsStr.capitalize(itemName)
        // });

        /**
         * this is necessary to have access to the actual values from
         * the async submitHandler instead of the ones which are defined in the
         * stale closure
         * @more - https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
         */
        asyncSubmitDataRef.current = {
            crudAction,
            crudState
        };

        const submitHandler = async (item: TSubmitItem) => {
            try {
                const storedItem = await currentCrudActionMutator.mutateAsync(item as any);

                const tpls = { ...(notificationTemplates || {}), ...defaultNotificationTemplates };
                const notificationTemplateData = {
                    ...asyncSubmitDataRef.current,
                    itemName,
                    item: storedItem,
                    toBack: location.pathname
                };

                setTimeout(
                    () =>
                        pushNotification({
                            title: utilsStr.supplant(tpls.title, notificationTemplateData),
                            msg: utilsStr.supplant(tpls.msg, notificationTemplateData),
                            type: NotificationTypes.Success
                        }),
                    3500
                );

                if (processedTargerUri) {
                    setTimeout(() => navigate(processedTargerUri), 3000);
                }

                return storedItem;
            } catch {
                // throw resolveRelatedErrorByFailedResponse<TSubmitItem>(error as Error, item);
            }
        };

        const cancelHandler = () => {
            if (processedTargerUri) {
                setTimeout(() => navigate(processedTargerUri));
            }
        };

        const enhancedProps: IWithUpdateOrCreateItemEnhancerProps<TItem, TSubmitItem> = {
            itemId,
            item: data,
            crudState,
            crudStateFlags,
            crudAction,
            submitHandler,
            cancelHandler
        };

        return <WrappedComponent {...typedWrappedComponentProps} {...enhancedProps} />;
    };
}

const resolveCrudStateByFlags = (crudStateFlags: TCrudStateFlags) =>
    crudStateFlags.updated
        ? CrudStates.Updated
        : crudStateFlags.updating
        ? CrudStates.Updating
        : crudStateFlags.created
        ? CrudStates.Created
        : crudStateFlags.creating
        ? CrudStates.Creating
        : crudStateFlags.read
        ? CrudStates.Read
        : crudStateFlags.reading
        ? CrudStates.Reading
        : undefined;
