import { ChannelForm } from "../components/ChannelForm";
import { useCreateChannel, useGetChannel, useUpdateChannel } from "../hooks";
import { IChannel } from "../types";
import { Card, CardContent } from "@/components/ui/card";
import { IWithAuthEnhancerProps, withAuth } from "@/auth/components/withAuth";
import { authHooks } from "@/auth";
import { CrudActions } from "@/common/constants/CrudActions";
import { withUpdateOrCreateItem } from "@/common/components/form/withUpdateOrCreateItem";
import { IWithUpdateOrCreateItemEnhancerProps } from "@/common/components/form/withUpdateOrCreateItem";

export interface IChannelItemPageProps extends IWithUpdateOrCreateItemEnhancerProps<IChannel>, IWithAuthEnhancerProps {
    item: IChannel | undefined;
}

export const ChannelItemPage = withAuth(
    withUpdateOrCreateItem<IChannelItemPageProps, IChannel>(
        ({ submitHandler, cancelHandler, item, crudState, crudAction }) => {
            const auth = authHooks.useAuth();
            // const { data: mailTemplates = [] } = useGetDomainMailTemplates(auth.activeDomain);

            return (
                <div>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    This information will be displayed publicly so be careful what you share.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <Card>
                                <CardContent>
                                    <ChannelForm
                                        // mailTemplates={mailTemplates}
                                        onCancel={cancelHandler}
                                        onSubmit={submitHandler}
                                        item={item}
                                        crudState={crudState}
                                        crudAction={crudAction}
                                    />
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            );
        },
        {
            itemName: "channel",
            useGetItemHook: ({ itemId, crudAction }) =>
                useGetChannel(Number(itemId), { enabled: crudAction === CrudActions.Update }),
            useCreateItemHook: ({ props }) => useCreateChannel(props.auth.activeDomain),
            useUpdateItemHook: () => useUpdateChannel(),
            targetUri: "/connections"
        }
    )
);
