import { useNavigate } from "react-router-dom";
import { ChanneLListItem } from "./ChannelListItem";
import { IChannel } from "../types";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { TypographyH1 } from "@/components/custom/text/TypographyH1";

interface IChannelList {
    onEditClick: (channel: IChannel) => void;
    channels: IChannel[];
}

export const ChannelList = ({ channels, onEditClick }: IChannelList) => {
    const navigate = useNavigate();

    return (
        <div>
            <TypographyH1 className="lg:text-2xl foont-medium">Channels</TypographyH1>
            <div className="grid grid-cols-3 gap-10 mt-10">
                <Card>
                    <CardContent>
                        <div className="w-full h-full flex items-center justify-center mt-8">
                            <div
                                className="text-center cursor-pointer text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/connections/new")}
                            >
                                <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        vectorEffect="non-scaling-stroke"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    />
                                </svg>
                                <p>New channel</p>
                                <p className="mt-1 text-sm">Get started by creating a new channel.</p>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {channels.map((channel: IChannel) => (
                    <ChanneLListItem key={channel.id} channel={channel} onEditClick={onEditClick} />
                ))}
            </div>
        </div>
    );
};
