import { config } from "@/app";
import { authHooks } from "@/auth";
import { MoneyFormatter } from "@/common";
import { DataTable } from "@/components/custom/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import Flex from "@/components/ui/flex";
import { cn } from "@/lib/utils";
import { QueueStatus } from "@/queue";
import { rbacUtils, Roles } from "@/rbac";
import { Building05Icon, DeliveryTruck01Icon, Home01Icon, House04Icon } from "@hugeicons/react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { Paperclip } from "lucide-react";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../constans/OrderStatus";
import { IOrder, IOrderResponse, IQueue } from "../types";

interface IOrderTableProps {
    orders?: IOrderResponse;
    activeStatus: OrderStatus;
    onClickQueueStatus: (queue: IQueue) => void;
    onEditOrder: (order: IOrder) => void;
}

export const OrderTable = forwardRef(({ orders, activeStatus, onClickQueueStatus, onEditOrder }: IOrderTableProps) => {
    const { user } = authHooks.useAuth();
    const navigate = useNavigate();
    const isEveryQueueStatusSame = (order: IOrder) =>
        order.queues?.every((queue, index, array) => queue.status === array[0].status);

    const getOrderStatus = (order: IOrder) =>
        order.queues?.map(queue => {
            if (queue.status === QueueStatus.Success) {
                return (
                    <Badge key={queue.id} color="green">
                        <span>{queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Success</span>
                    </Badge>
                );
            } else if (queue.status === QueueStatus.Timeout) {
                return (
                    <Badge
                        className={rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin) ? "cursor-pointer" : ""}
                        key={queue.id}
                        color="yellow"
                        onClick={() => onClickQueueStatus(queue)}
                    >
                        <span>{queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Timeout</span>
                    </Badge>
                );
            } else if (queue.status === QueueStatus.Pending) {
                return (
                    <Badge
                        key={queue.id}
                        color="yellow"
                        onClick={() => onClickQueueStatus(queue)}
                        className={rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin) ? "cursor-pointer" : ""}
                    >
                        <span>{queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Pending</span>
                    </Badge>
                );
            }

            return (
                <Badge
                    key={queue.id}
                    color="red"
                    onClick={() => onClickQueueStatus(queue)}
                    className={rbacUtils.hasOneOfRoles(user.roles, Roles.SuperAdmin) ? "cursor-pointer" : ""}
                >
                    <span>{queue.order_product_id ? `o_p: ${queue.order_product_id}` : ""} Error</span>
                </Badge>
            );
        });
    const columns: ColumnDef<IOrder>[] = [
        {
            id: "select-col",
            header: ({ table }) => (
                <Checkbox
                    className="border-foreground/20 shadow-none mr-4"
                    checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
                    onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
                    aria-label="Select all"
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={value => row.toggleSelected(!!value)}
                    aria-label="Select row"
                    className="border-foreground/20 shadow-none mr-4"
                />
            )
        },
        {
            id: "id",
            header: "Platform & Channel",
            cell: ({ row }) => (
                <div className="flex gap-4 items-center">
                    <div className="flex -space-x-1">
                        <div
                            className="bg-white rounded-full w-6 h-6 ring-2 ring-white"
                            onClick={() => onEditOrder(row.original)}
                        >
                            <img
                                className="rounded-md w-6 h-6"
                                src={`${config.appUrl}/assets/img/channels/${row.original.channel.icon}`}
                                alt=""
                                width="28"
                                height="28"
                            />
                        </div>
                        {!!row.original.integrator_marketplace && (
                            <div className="bg-white rounded-full w-7 h-7 ring-2 ring-white">
                                {row.original.integrator_marketplace === "amazon" ? (
                                    <img
                                        className="rounded-md w-7 h-7"
                                        src={`${config.appUrl}/assets/img/channels/amazon-icon.svg`}
                                        alt=""
                                        width="24"
                                        height="24"
                                    />
                                ) : row.original.integrator_marketplace === "mirakl_mediamarkt" ? (
                                    <img
                                        className="rounded-md w-7 h-7"
                                        src={`${config.appUrl}/assets/img/channels/media-markt-logo.svg`}
                                        alt=""
                                        width="24"
                                        height="24"
                                    />
                                ) : (
                                    <img
                                        className="rounded-md w-7 h-7"
                                        src={`${config.appUrl}/assets/img/channels/blokker.png`}
                                        alt=""
                                        width="24"
                                        height="24"
                                    />
                                )}
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col">
                        <div className="text-sm">
                            <Flex className="w-full font-medium">
                                <span className="text-xs font-extralight text-muted-foreground">
                                    # {row.original.id}
                                </span>
                                {(row.original.customer?.shipmentAddress?.is_alarm ||
                                    row.original.customer?.billingAddress?.is_alarm) && (
                                    <span className="relative flex h-3 w-3 -left-1">
                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                    </span>
                                )}
                            </Flex>
                        </div>

                        <div className="text-xs whitespace-nowrap text-foreground">
                            {row.original.channel.company_name}
                        </div>
                    </div>
                </div>
            )
        },
        {
            id: "status",
            header: "Status",
            cell: ({ row }) => (
                <div className="flex flex-col gap-y-0.5">
                    {!!row.original.queues?.length && getOrderStatus(row.original)}
                    {!row.original.customer?.shipmentAddress?.house_number && (
                        <Badge color="red">Missing house number</Badge>
                    )}
                </div>
            )
        },
        {
            id: "external_order_id",
            header: "Ex Order ID",
            accessorKey: "external_order_id"
        },
        {
            id: "carrier",
            header: "Delivery",
            cell: ({ row }) => {
                const order = row.original;
                const lastOrderSlipAttachment = order.attachments && order.attachments[0];

                return (
                    <div>
                        <span className="flex items-center justify-start w-full text-xs">
                            {!order.pick_up ? (
                                <DeliveryTruck01Icon className="w-5 h-5 text-muted-foreground mr-2" />
                            ) : (
                                <Home01Icon className="w-5 h-5 text-red-700" />
                            )}
                            {order.carrier}
                            {lastOrderSlipAttachment && (
                                <Paperclip
                                    className={cn("ml-2 w-4 cursor-pointer", {
                                        "text-slate-300": lastOrderSlipAttachment.is_downloaded,
                                        "text-slate-800": !lastOrderSlipAttachment.is_downloaded
                                    })}
                                />
                            )}
                        </span>
                        <p>
                            {order.shipment?.shipment_order_products?.map(product =>
                                product.tracking_numbers?.join(", ")
                            )}
                            {order.shipment?.carrier_transport_ids?.join(", ")}
                        </p>
                    </div>
                );
            }
        },
        {
            id: "latest_delivery_date_at",
            header: "Delivery date",

            cell: ({ row }) => {
                const order = row.original;

                return order.exact_delivery_date_at ? null : (
                    <div>
                        {order.latest_delivery_date_at}
                        <br />
                        {order.delivery_between_hours && (
                            <span>
                                <span className="font-normal text-xs">Between:</span>{" "}
                                {order.delivery_between_hours.from}:00 - {order.delivery_between_hours.to}:00
                            </span>
                        )}
                    </div>
                );
            }
        },
        {
            id: "exact_delivery_date_at",
            header: "Exact date",
            cell: ({ row }) => {
                const order = row.original;

                return (
                    <div>
                        {order.exact_delivery_date_at}
                        <br />
                        {order.delivery_between_hours && (
                            <span>
                                <span className="font-normal text-xs">Between:</span>{" "}
                                {order.delivery_between_hours.from}:00 - {order.delivery_between_hours.to}:00
                            </span>
                        )}
                    </div>
                );
            }
        },
        {
            id: "customer.shipmentAddress.zip_code",
            header: "Address",
            cell: ({ row }) => {
                const order = row.original;

                return (
                    <Flex className="text-xs gap-4" justifyContent="start" alignItems="center">
                        <div className="relative">
                            <img
                                src={`${config.appUrl}/assets/img/flags/${order.customer?.shipmentAddress?.country_iso_3166_2}.svg`}
                                alt=""
                                className="w-3 h-3 rounded-full object-cover absolute -right-2 -top-2"
                            />
                            {!order.customer?.shipmentAddress?.company ? (
                                <House04Icon className="h-5 w-5 text-muted-foreground" />
                            ) : (
                                <Building05Icon className="h-5 w-5 text-muted-foreground" />
                            )}
                        </div>

                        <div>
                            {order.customer?.shipmentAddress?.fullStreetDetails}

                            <br />
                            <span className="flex text-xs">
                                {order.customer?.shipmentAddress?.full_name || "Unknown"}
                                <br />
                                {order.customer?.shipmentAddress?.company || "-"}
                                {order.customer?.shipmentAddress?.zip_code} {order.customer?.shipmentAddress?.city}
                            </span>
                        </div>
                    </Flex>
                );
            }
        },
        {
            id: "products",
            header: "Products",
            cell: ({ row }) => {
                const order = row.original;

                return (
                    <ul className="text-xs justify-start items-start h-auto p-0">
                        {order.products?.map(product => (
                            <li title={product.name} key={product.id} className="w-64 truncate">
                                {product.quantity}x ({MoneyFormatter(product.price)}) {product.name}
                            </li>
                        ))}
                    </ul>
                );
            }
        },
        {
            id: "order_placed_at",
            header: "Placed at",
            accessorKey: "order_placed_at"
        },
        {
            id: "view",
            // header: "",
            cell: ({ row }) => {
                const order = row.original;

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <DotsHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={() => onEditOrder(order)}>View order</DropdownMenuItem>
                            {/* <DropdownMenuSeparator />
                            <DropdownMenuItem>View customer</DropdownMenuItem>
                            <DropdownMenuItem>View payment details</DropdownMenuItem> */}
                        </DropdownMenuContent>
                    </DropdownMenu>
                );
            }
        }
    ];

    const isRowSelectableResolver = (order: IOrder) => {
        if (
            (!order.pick_up &&
                !order.queues?.length &&
                order.channel.key === "bol" &&
                activeStatus !== OrderStatus.New) ||
            (!order.pick_up &&
                (!order.shipment?.external_shipment_id ||
                    !order.shipment?.carrier_transport_ids ||
                    order.shipment?.carrier_transport_ids.length < 1) &&
                activeStatus > OrderStatus.New) ||
            (!order.pick_up &&
                order.queues?.length &&
                (!isEveryQueueStatusSame(order) || order.queues[0].status !== QueueStatus.Success)) ||
            (order.pick_up && activeStatus === OrderStatus.Warehouse)
        ) {
            return false;
        }

        return true;
    };

    const rowClassNameResolver = (order: IOrder) => {
        console.log("order", order);
        return cn(
            order.is_highlighted &&
                (order.status === OrderStatus.Shipping || order.status === OrderStatus.Warehouse) &&
                "bg-orange-50",
            order.added && "bg-green-50",
            order.attachments && order.attachments[0]?.is_downloaded && "bg-amber-50"
        );
    };

    return !orders ? (
        <div />
    ) : (
        <DataTable
            columns={columns}
            data={orders.data || []}
            // onRowSelectionChange={() => onRowSelection()}
            // features={{
            //     rowSelection: () => sel
            // }}
            rowClassName={(row: any) => rowClassNameResolver(row.original)}
        />
    );
});
