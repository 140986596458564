import nativeAxios from "axios";
import { config } from "../config";

export const axios = nativeAxios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
});
