import { axios } from "@/app";
import { channelTypes } from "@/channel";
import { domainTypes } from "@/domain";
import { warehouseTypes } from "@/warehouse";
import fileDownload from "js-file-download";
import { IChannelProduct, IProduct, IProductResponse, IProductWarehouse, ITotalStockValue } from "./types";

export const getProducts = async (
    domain: domainTypes.IDomain,
    page: number,
    search: string | null
): Promise<IProductResponse> => {
    const response = await axios.get<IProductResponse>(`domains/${domain.id}/products`, {
        params: { page, search }
    });

    return response.data;
};

export const getProduct = async (id: number): Promise<IProduct> => {
    const response = await axios.get<{ data: IProduct }>(`products/${id}`);

    return response.data.data;
};

export const createProduct = async (domainId: number, ean: string | null): Promise<IProduct> => {
    const response = await axios.post<{ data: IProduct }>(`domains/${domainId}/products`, {
        ean
    });

    return response.data.data;
};

export const regenerateChannelProductAiText = async (channelProduct: IChannelProduct): Promise<IChannelProduct> => {
    await axios.post(`channel-products/${channelProduct.id}/regenerate`);

    return channelProduct;
};

export const importProductIcecat = async (productId: number): Promise<number> => {
    await axios.post<null>(`products/${productId}/icecat`);

    return productId;
};

export const updateProduct = async (product: IProduct): Promise<number> => {
    await axios.put<null>(`products/${product.id}`, product);

    return product.id;
};

export const syncProductChannel = async ({
    product,
    channel
}: {
    product: IProduct;
    channel: channelTypes.IChannel;
}) => {
    const response = await axios.put<{ data: IProduct }>(`products/${product.id}/channels/${channel.id}`);

    return response.data.data;
};

export const createChannelProductAiText = async (
    channelId: number,
    productId: number | null
): Promise<IChannelProduct> => {
    const response = await axios.post<{ data: IChannelProduct }>(`channels/${channelId}/products/${productId}/ai-text`);

    return response.data.data;
};

export const updateChannelProduct = async (channelProduct: IChannelProduct): Promise<string> => {
    await axios.put<{ data: IChannelProduct }>(`channel-products/${channelProduct.id}`, channelProduct);

    return channelProduct.product_id;
};

export const getProductWarehouses = async (productId: number): Promise<warehouseTypes.IWarehouse[]> => {
    const response = await axios.get<{ data: warehouseTypes.IWarehouse[] }>(`products/${productId}/product-warehouses`);

    return response.data.data;
};

export const updateProductWarehouses = async (
    productId: number,
    values: {
        reason: string;
        productWarehouses: IProductWarehouse[];
    }
): Promise<number> => {
    await axios.put<{ data: IProduct }>(`products/${productId}/product-warehouses`, {
        productWarehouses: values.productWarehouses,
        reason: values.reason
    });

    return productId;
};

export const deleteEan = async (productId: number, eanId: number): Promise<number> => {
    await axios.delete(`eans/${eanId}`);

    return productId;
};

export const createEan = async (productId: number, ean: string): Promise<number> => {
    await axios.post(`/products/${productId}/eans`, { ean });

    return productId;
};

export const getTotalStockValue = async (domain: domainTypes.IDomain): Promise<ITotalStockValue> => {
    const response = await axios.get<ITotalStockValue>(`domains/${domain.id}/total-stock-value`);

    return response.data;
};

export const getDownloadCsv = async (domainId: number) => {
    const response = await axios.post(`domains/${domainId}/total-stock-value-csv`);

    fileDownload(response.data, "total-stock-value.csv");
};
