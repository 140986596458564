import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn } from "@/lib/utils";

const Avatar = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)}
        {...props}
    />
));
(Avatar as any).displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image ref={ref} className={cn("aspect-square h-full w-full", className)} {...props} />
));
(AvatarImage as any).displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn("flex h-full w-full items-center justify-center rounded-full bg-muted", className)}
        {...props}
    >
        {resolveInitials(props.children as string)}
    </AvatarPrimitive.Fallback>
));
(AvatarFallback as any).displayName = AvatarPrimitive.Fallback.displayName;

/**
 * resolveInitials
 * Helper function to resolve the initials
 * @param name
 * @returns
 */
const resolveInitials = (name: string): string => {
    const splitted = name.split(" ");
    let initials = splitted[0][0];

    if (name) {
        if (splitted.length >= 2 && splitted[1]) {
            initials += splitted[1][0];
        } else if (splitted.length === 1 && name.length > 1) {
            initials += splitted[0][1];
        }
    } else {
        initials = "";
    }

    return initials;
};

export { Avatar, AvatarFallback, AvatarImage };
