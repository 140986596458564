"use client";

import { buttonVariants } from "@/components/ui/button";
import Flex from "@/components/ui/flex";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { HugeiconsProps } from "@hugeicons/react";
import { TooltipArrow, TooltipProvider } from "@radix-ui/react-tooltip";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

interface NavProps {
    isCollapsed: boolean;
    links: ILink[];
}

export interface ILink {
    title: string;
    label?: string | undefined;
    icon: React.FC<Omit<HugeiconsProps, "ref"> & React.RefAttributes<SVGSVGElement>>;
    variant: "default" | "ghost";
    to: string;
}

export function Nav({ links, isCollapsed }: NavProps) {
    const { pathname } = useLocation();

    return (
        <TooltipProvider>
            <div data-collapsed={isCollapsed} className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2">
                <nav className="grid gap-1.5 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                    {links.map(link =>
                        isCollapsed ? (
                            <Tooltip key={link.to} delayDuration={0}>
                                <TooltipTrigger asChild>
                                    <NavLink
                                        key={`icon-${link.to}`}
                                        to={link.to}
                                        className={cn(
                                            "h-9 w-9 flex items-center justify-center rounded-md",
                                            "text-foreground/70 hover:bg-foreground/5",
                                            pathname === link.to &&
                                                "bg-primary/10 text-primary shadow-none hover:bg-primary/10 hover:text-primary cursor-default"
                                        )}
                                    >
                                        <link.icon variant="twotone" className="h-5 w-5" />
                                        <span className="sr-only">{link.title}</span>
                                    </NavLink>
                                </TooltipTrigger>
                                <TooltipContent side="right" className="flex items-center gap-4 whitespace-nowrap">
                                    <TooltipArrow className="fill-primary" />
                                    {link.title}
                                    {link.label && <Flex className=" text-muted-background text-xs">{link.label}</Flex>}
                                </TooltipContent>
                            </Tooltip>
                        ) : (
                            <NavLink
                                key={link.to}
                                to={link.to}
                                className={({ isActive }) =>
                                    cn(
                                        buttonVariants({
                                            variant: link.variant,
                                            size: "sm"
                                        }),
                                        "bg-white shadow-none text-foreground/70 hover:bg-foreground/5",
                                        "justify-start",
                                        "text-sm font-normal h-9",
                                        isActive &&
                                            "bg-primary/10 text-primary shadow-none hover:bg-primary/10 hover:text-primary cursor-default",
                                        !isActive && ""
                                    )
                                }
                            >
                                <link.icon variant="twotone" className="mr-2 h-5 w-5" />

                                {link.title}
                                {link.label && (
                                    <span
                                        className={cn(
                                            "ml-auto",
                                            link.variant === "default" && "text-background dark:text-white"
                                        )}
                                    >
                                        {link.label}
                                    </span>
                                )}
                            </NavLink>
                        )
                    )}
                </nav>
            </div>
        </TooltipProvider>
    );
}
