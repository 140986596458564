import {
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
    Pagination as PaginationRoot
} from "@/components/ui/pagination";
import { twMerge } from "tailwind-merge";

interface PaginationProps {
    meta: {
        current_page: number;
        last_page: number;
    };
    onPageChange: (page: number) => void;
    className?: string;
}

export const Pagination = ({ meta, onPageChange, className }: PaginationProps) => {
    const { current_page, last_page } = meta;

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const SIBLINGS = 3; // Number of pages to show on each side

        // Helper function to create page items
        const createPageItem = (pageNum: number) => (
            <PaginationItem key={pageNum}>
                <PaginationLink href="#" isActive={pageNum === current_page} onClick={() => onPageChange(pageNum)}>
                    {pageNum}
                </PaginationLink>
            </PaginationItem>
        );

        // Always show first page
        pageNumbers.push(createPageItem(1));

        // Calculate range for left side
        const leftStart = Math.max(2, current_page - SIBLINGS);
        if (leftStart > 2) {
            pageNumbers.push(
                <PaginationItem key="leftEllipsis">
                    <PaginationEllipsis />
                </PaginationItem>
            );
        }

        // Add pages before current page
        for (let i = leftStart; i < current_page; i++) {
            pageNumbers.push(createPageItem(i));
        }

        // Add current page if not first or last
        if (current_page !== 1 && current_page !== last_page) {
            pageNumbers.push(createPageItem(current_page));
        }

        // Calculate range for right side
        const rightEnd = Math.min(last_page - 1, current_page + SIBLINGS);

        // Add pages after current page
        for (let i = current_page + 1; i <= rightEnd; i++) {
            pageNumbers.push(createPageItem(i));
        }

        // Add ellipsis if needed
        if (rightEnd < last_page - 1) {
            pageNumbers.push(
                <PaginationItem key="rightEllipsis">
                    <PaginationEllipsis />
                </PaginationItem>
            );
        }

        // Always show last page if not already included
        if (last_page !== 1) {
            pageNumbers.push(createPageItem(last_page));
        }

        return pageNumbers;
    };

    return (
        <PaginationRoot className={twMerge("mt-4", className)}>
            <PaginationContent>
                <PaginationItem>
                    <PaginationPrevious
                        href="#"
                        onClick={() => onPageChange(current_page - 1)}
                        aria-disabled={current_page === 1}
                        className={current_page === 1 ? "pointer-events-none opacity-50" : ""}
                    />
                </PaginationItem>

                {renderPageNumbers()}

                <PaginationItem>
                    <PaginationNext
                        href="#"
                        onClick={() => onPageChange(current_page + 1)}
                        aria-disabled={current_page === last_page}
                        className={current_page === last_page ? "pointer-events-none opacity-50" : ""}
                    />
                </PaginationItem>
            </PaginationContent>
        </PaginationRoot>
    );
};
