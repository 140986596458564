// import { ControlledFormField } from "@/common";
// import { Button, Calendar, Form, MultiRangeSlider } from "@ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IOrder } from "../types";

interface IExactDeliveryFormProps {
  onSubmit: (formValues: any) => void;
  order?: IOrder;
}

export const ExactDeliveryForm = ({
  order,
  onSubmit,
}: IExactDeliveryFormProps) => {
  const { handleSubmit, control, reset } = useForm<IOrder>();

  useEffect(() => reset(order), [order, reset]);

  return <div />;
  // <Form onSubmit={handleSubmit(onSubmit)} fullWidth={true}>
  //     <div className="col-span-4 p-4">
  //         <ControlledFormField control={control} name="exact_delivery_date_at" component={Calendar} label="" />
  //     </div>
  //     <div className="col-span-4 h-16 mx-2 mb-8">
  //         <ControlledFormField
  //             control={control}
  //             name="delivery_between_hours"
  //             component={MultiRangeSlider}
  //             valueParser={(value: any) => [value?.from || 7, value?.to || 22]}
  //             valueFormatter={(value: any) => ({ from: value[0], to: value[1] })}
  //             label="Delivery time between:"
  //         />
  //     </div>

  //     <div className="flex col-span-4 flex-row-reverse -m-6 bg-slate-100 px-6 py-3">
  //         <Button color="purple" size="sm" variant="primary" type="submit">
  //             submit
  //         </Button>
  //     </div>
  // </Form>
};
