import { useMutation, useQuery } from "react-query";
import { getCustomer, getCustomers, updateCustomer } from "./queries";

export const useGetCustomer = (id: number) => useQuery(["customers/item", id], () => getCustomer(id));

export const useGetCustomers = (domainId: number) =>
    useQuery(["customers/items", domainId], () => getCustomers(domainId));

export const useUpdateCustomer = () =>
    useMutation(updateCustomer, {
        onSuccess: () => console.log("gelukt"),
    });
