import { paginationTypes } from "@/components/custom/pagination";
import { Pagination } from "@/components/custom/pagination/pagination";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Remove01Icon } from "@hugeicons/react";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { IFeatureGroup, IProduct } from "../types";

interface IProductFeatureFormProps {
    product: IProduct;
    onSubmit: (data: IProduct) => void;
    meta?: paginationTypes.IMeta;
    onPageChange?: (page: number) => void;
}

export const ProductFeatureForm = ({ product, onSubmit, meta, onPageChange }: IProductFeatureFormProps) => {
    const { control, ...form } = useForm<IProduct>({
        defaultValues: product
    });

    const { fields: featureGroupFields, append: appendFeatureGroup, remove: removeFeatureGroup } = useFieldArray({
        control,
        name: "featureGroups"
    });

    useEffect(() => {
        if (product) {
            form.reset(product);
        }
    }, [product]);

    return (
        <Form {...form} control={control}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-8 w-full">
                {featureGroupFields.map((group, groupIndex) => {
                    const {
                        fields: featureProductFields,
                        append: appendFeature,
                        remove: removeFeature
                    } = useFieldArray({
                        control,
                        name: `featureGroups.${groupIndex}.featureProducts`
                    });

                    return (
                        <Card key={group.id} className="mb-4 shadow-none">
                            <CardHeader className="bg-muted p-2 flex flex-row justify-between items-center">
                                {/* <FormField
                                    control={control}
                                    name={`featureGroups.${groupIndex}.name`}
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormControl>
                                                <Input placeholder="Group name" {...field} />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                /> */}
                                {/* <Button
                                    variant="destructive"
                                    size="sm"
                                    type="button"
                                    onClick={() => removeFeatureGroup(groupIndex)}
                                >
                                    Remove Group
                                </Button> */}
                                {product.featureGroups[groupIndex].name}
                            </CardHeader>
                            <CardContent className="py-4">
                                <div className="flex gap-y-2 flex-col">
                                    {featureProductFields.map((featureProduct, featureIndex) => {
                                        const type =
                                            product.featureGroups[groupIndex].featureProducts[featureIndex].feature
                                                .type;

                                        return (
                                            <div key={featureProduct.id} className="flex gap-x-8 items-end">
                                                <FormField
                                                    control={control}
                                                    name={`featureGroups.${groupIndex}.featureProducts.${featureIndex}.feature.name`}
                                                    render={({ field }) => (
                                                        <FormItem className="flex-1">
                                                            <FormLabel>Feature Name</FormLabel>
                                                            <FormControl>
                                                                <Input {...field} />
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />

                                                <FormField
                                                    control={control}
                                                    name={`featureGroups.${groupIndex}.featureProducts.${featureIndex}.data.${
                                                        type === "y_n" ? "raw_value" : "presentation_value"
                                                    }`}
                                                    render={({ field }) => (
                                                        <FormItem className="flex-1">
                                                            {type !== "y_n" && <FormLabel>Value</FormLabel>}
                                                            <FormControl>
                                                                {type === "y_n" ? (
                                                                    <Switch
                                                                        checked={field.value === "Y"}
                                                                        onCheckedChange={checked => {
                                                                            field.onChange(checked ? "Y" : "N");
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Input {...field} />
                                                                )}
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => removeFeature(featureIndex)}
                                                >
                                                    <Remove01Icon className="w-4 h-4" />
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() =>
                                        appendFeature({
                                            feature: {
                                                name: "",
                                                description: "",
                                                type: "string",
                                                external_feature_id: 0,
                                                external_icecat_feature_id: 0
                                            },
                                            data: {
                                                presentation_value: "",
                                                local_value: "",
                                                measure_unit: {
                                                    ID: "",
                                                    Language: ""
                                                },
                                                raw_value: "",
                                                value: ""
                                            }
                                        })
                                    }
                                    className="mt-4"
                                >
                                    Add Feature
                                </Button> */}
                            </CardContent>
                        </Card>
                    );
                })}
                <Button
                    type="button"
                    className="hidden"
                    onClick={() =>
                        appendFeatureGroup({
                            name: "",
                            featureProducts: []
                        } as IFeatureGroup)
                    }
                >
                    Add Feature Group
                </Button>
                {meta && onPageChange && (
                    <div className="mt-4">
                        <Pagination meta={meta} onPageChange={onPageChange} />
                    </div>
                )}
                <Button className="hidden" type="submit">
                    Save Changes
                </Button>
            </form>
        </Form>
    );
};
